import { colors } from 'src/theme/colors';

export const buttonStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  backgroundColor: colors.darkGray2,
  pt: 1,
  pb: 1,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: colors.darkGray2,
    boxShadow: 'none',
  },
};

export const buttonAltStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  color: colors.darkGray2,
  pt: 1,
  pb: 1,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: colors.lightGray2,
    boxShadow: 'none',
  },
};

export const buttonOutlinedStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  color: colors.darkGray2,
  pt: 1,
  pb: 1,
  textTransform: 'none',
  boxShadow: 'none',
  borderColor: colors.darkGray2,
  '&:hover': {
    backgroundColor: colors.lightGray2,
    boxShadow: 'none',
    borderColor: colors.darkGray2,
  },
};
