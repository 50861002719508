import React, { Dispatch, MouseEvent, ReactElement, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import UserRow from './UserRow';
import { EditableUser } from 'src/types/EditableUser';
import { colors } from 'src/theme/colors';
import { Order } from 'src/types/Order.type';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getComparator, stableSort } from 'src/utils/sortingHelpers';

const tableRowStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  border: 'solid 1px',
  borderColor: colors.darkGray2,
};

const tableBodyStyles = {
  ...tableRowStyles,
  backgroundColor: colors.white,
};

const tableHeadStyles = {
  ...tableRowStyles,
  backgroundColor: colors.lightGray1,
};

const paginationStyles = {
  overflowX: 'hidden',
  marginTop: 2.5,
  '.MuiTablePagination-selectIcon': {
    mt: 0,
    p: 0,
  },
  '.MuiTablePagination-displayedRows': {
    fontFamily: 'Lato',
  },
  '.MuiTablePagination-selectLabel': {
    fontFamily: 'Lato',
  },
  '.MuiTablePagination-select': {
    fontFamily: 'Lato',
    pl: '8px',
    pr: '24px',
    textAlign: 'right',
    textAlignLast: 'right',
  },
};

interface UserTableProps {
  users: EditableUser[];
  setModalOpen: Dispatch<boolean>;
  setEditUser: Dispatch<EditableUser>;
  setShowAddUserForm: Dispatch<boolean>;
}

const UserTable = ({ users, setModalOpen, setEditUser, setShowAddUserForm }: UserTableProps): ReactElement => {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof EditableUser>('createdDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (_: MouseEvent<HTMLSpanElement>, property: keyof EditableUser): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { title: 'Date Added', style: { ...tableHeadStyles }, field: 'createdDate', sortable: true },
    { title: 'Last Name', style: { ...tableHeadStyles }, field: 'lastName', sortable: true },
    { title: 'First Name', style: { ...tableHeadStyles }, field: 'firstName', sortable: false },
    { title: 'Email', style: { ...tableHeadStyles }, field: 'email', sortable: false },
    { title: 'Last Log-In', style: { ...tableHeadStyles }, field: 'lastLogin', sortable: true },
    { title: 'Role', style: { ...tableHeadStyles }, field: 'role', sortable: true },
    { title: 'Account Status', style: { ...tableHeadStyles }, field: 'active', sortable: true },
    { title: 'Action', style: { ...tableHeadStyles, textAlign: 'center' }, field: 'action', sortable: false },
  ];

  return (
    <TableContainer component={Box}>
      <Table aria-label="Users table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                size="small"
                key={column.field}
                sortDirection={orderBy === column.field ? order : false}
                sx={column.style}
              >
                {column.sortable ? (
                  <TableSortLabel
                    sx={{
                      fontFamily: 'Lato',
                      padding: '5px',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: colors.black,
                      },
                      '&:focus-visible': {
                        outline: `2px solid ${colors.blue1}`,
                      },
                    }}
                    className={
                      orderBy !== column.field && column.sortable
                        ? 'Mui-active MuiTableSortLabel-iconDirectionDesc MuiTableSortLabel-iconDirectionAsc'
                        : ''
                    }
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : 'asc'}
                    onClick={(e) => handleSort(e, column.field as keyof EditableUser)}
                    IconComponent={ArrowDropDownIcon}
                  >
                    {column.title}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(users, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user: any) => (
              <UserRow
                key={user.id}
                user={user}
                tableBodyStyles={tableBodyStyles}
                setModalOpen={setModalOpen}
                setEditUser={setEditUser}
                setShowAddUserForm={setShowAddUserForm}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component={Box}
        count={users.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={paginationStyles}
        rowsPerPageOptions={[5, 10, 25]}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
      />
    </TableContainer>
  );
};

export default UserTable;
