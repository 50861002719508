import {createTheme} from "@mui/material/styles";
import {colors} from "./colors";

// Ideally, all CNEE-specific customizations to MUI components live here.
// This way, we can control all aspects of the CNEE theme in one place and
// apply them consistently wherever these components are used.
export const cneeTheme = createTheme({
  components: {
    MuiLink: {
      // @todo Is this the right way to style a component for theming?
      defaultProps: {
        color: colors.blue6
      }
    }
  }
})

// @todo Move this to CNEE site-wide theme. Ensure no buttons break.
// Not sure why we have different color setups and three kinds of buttons.
export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: colors.blue1,
    },
    secondary: {
      light: colors.white,
      main: colors.black,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    button: {
      textTransform: 'none',
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: '300',
    },
  },
});