import { gql } from '@apollo/client';

export const GET_STATE_ANSWERS = gql`
  query ($states: [State], $questionIds: [Int]) {
    getPolicyAnswersByQuestionsAndStates(input: { states: $states, questionIds: $questionIds }) {
      id
      value
      url
      stateId
      question {
        id
        text
        subcategory {
          id
          title
          description
          category {
            id
            title
          }
          tier
        }
      }
    }
  }
`;
