import React, { Dispatch, FC, useEffect } from 'react';
import { MainAccordion, AccordionSummary } from '../DataAdminAccordionTheme';
import { AccordionDetails } from '@mui/material';
import { LeadershipAndLegislators } from './LeadershipAndLegislators';
import { Commission } from './Commission';
import { OtherStateOffices } from './OtherStateOffices';
import styles from './StateSnapshotAccordion.module.scss';
import { Snapshot } from 'src/components/StateSnapshot/types/Snapshot.class';
import { State } from 'src/types/State.enum';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import Button from "@mui/material/Button";

interface StateSnapshotAccordionsProps {
  state: State;
  snapshot: Snapshot;
  setSnapshot: Dispatch<Snapshot>;
  handleSave: (e: any) => void;
  saving: boolean;
}

export const StateSnapshotAccordion: FC<StateSnapshotAccordionsProps> = ({
  state,
  snapshot,
  setSnapshot,
  handleSave,
  saving,
}) => {
  useEffect(() => {}, [snapshot]);

  const props = {
    state,
    snapshot,
    setSnapshot,
  };
  return (
    <div>
      <MainAccordion className={styles.areaAccordion}>
        <AccordionSummary>
          <div className={styles.summaryContent}>ABOUT</div>
          <Button onClick={() => {window.open(`/state/${state}`, '_blank')}} sx={{ marginRight: '1rem' }}>View state page</Button>
          <AdminButton
            text="Save"
            overwriteUnderline={true}
            onClick={handleSave}
            defaultStyle={true}
            disabled={saving}
          />
        </AccordionSummary>
        <AccordionDetails className={styles.areaAccordionDetails}>
          <LeadershipAndLegislators {...props} />
          <Commission {...props} />
          <OtherStateOffices {...props} />
        </AccordionDetails>
      </MainAccordion>
    </div>
  );
};
