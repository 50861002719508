import { Expose } from 'class-transformer';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { PolicySubcategory } from 'src/types/PolicySubcategory';

export interface PolicyCategoryAccordionInterface {
  listPolicyCategories: PolicyCategory[];
  listPolicySubcategories: PolicySubcategory[];
}

export class PolicyCategoryAccordionClass {
  @Expose() listPolicyCategories: PolicyCategory[] = [new PolicyCategory()];
  @Expose() listPolicySubcategories: PolicySubcategory[] = [new PolicySubcategory()];
}

export interface CreatePolicyCategoryVars {
  title: string;
}

export interface UpdatePolicyCategoryVars {
  id: number;
  title: string;
}
