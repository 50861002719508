import React, { FC } from 'react';
import styles from './PolicyBriefUpload.module.scss';

interface PolicyBriefUploadProps {
  id: string;
  handleFileChange: (e: any) => void;
}

const PolicyBriefUpload: FC<PolicyBriefUploadProps> = ({ id, handleFileChange }) => {
  const hiddenFileInput = React.useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <label htmlFor="policyBriefUpload">
        <button className={styles.uploadButton} onClick={handleClick}>
          Upload Policy Brief
        </button>
      </label>
      <input
        type="file"
        id="policyBriefUpload"
        accept=".pdf"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
export default PolicyBriefUpload;
