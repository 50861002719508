export const navItems = [
  {
    title: 'SPOT for Clean Energy',
    subItems: [
      {
        title: 'About SPOT',
        to: '/about',
      },
      {
        title: 'State Comparison Tool',
        to: `/policy/policyName/compareStateTool`,
      },
    ],
  },
  {
    title: 'Clean Energy Policies',
    subItems: [],
  },
  {
    title: 'Contact',
    to: '/contact',
  },
  {
    title: 'About CNEE',
    href: 'https://cnee.colostate.edu/about-us/',
  },
];

export const navItemsAdmin = [
  {
    title: 'Admin',
    subItems: [
      {
        title: 'State Data Management',
        to: '/admin/state/Alabama',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'Policy Data Management',
        to: '/admin/policy',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'User Account Management',
        to: '/user-account-management',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'Global Settings',
        to: '/admin/globalSettings',
        role: ['SUPER_ADMIN'],
      },
      {
        title: 'Log Out',
        to: '/logout',
        role: ['SUPER_ADMIN'],
      },
    ],
  },
];
