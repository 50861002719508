import React, { FC } from 'react';
import styles from './CneeHomeNav.module.scss';
import { useScreenSize } from '../../hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import hamburger from 'src/assets/svg/hamburger.svg';
import closeIcon from 'src/assets/svg/close-square.svg';
import GeneralLinkBody from '../GeneralLinkBody/GeneralLinkBody';

export interface CneeHomeNavProps {
  drawerOpen: boolean;
  setDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const CneeHomeNav: FC<CneeHomeNavProps> = ({ drawerOpen, setDrawerOpened }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  return (
    <>
      <nav className={styles.navContainer}>
        <div className={styles.textContainer}>
          <div>
            An official site of the{' '}
            <GeneralLinkBody href="https://cnee.colostate.edu/">
              <strong>Center for the New Energy Economy {'>'}</strong>{' '}
            </GeneralLinkBody>
          </div>
        </div>
        {screenSizeIsAtLeast[Breakpoint.LG] ? null : (
          <button className={styles.hamburgerContainer} onClick={() => setDrawerOpened((prev) => !prev)}>
            <img src={drawerOpen ? closeIcon : hamburger} alt="navigation menu expand"></img>
          </button>
        )}
      </nav>
    </>
  );
};

export default CneeHomeNav;
