import React, { ReactElement, useState, useContext, useEffect, ChangeEvent, FormEvent } from 'react';
import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { UserDataContext } from 'src/hooks/useUserDataContext';
import { CreateAccountAdmin, DeleteAccount, GetAllUsers, UpdateAccount } from 'src/services/AuthService';
import { Navigate } from 'react-router-dom';
import UserTable from './UserTable/UserTable';
import { buttonStyles } from './buttonStyles';
import EditUserModal from './EditUserModal/EditUserModal';
import { toast } from 'react-toastify';
import { validateEmail } from 'src/utils/validateEmail';
import { EditableUser } from 'src/types/EditableUser';
import { UserRole } from 'src/types/UserRole.enum';

const typographyStyles = {
  fontFamily: 'Lato',
};

class InputError {
  error: boolean = false;
  message: string = '';
}

export class EditUserFormError {
  firstName: InputError = new InputError();
  lastName: InputError = new InputError();
  email: InputError = new InputError();
  password: InputError = new InputError();
}

const UserAccountManagement = (): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [error, setError] = useState<EditUserFormError>(new EditUserFormError());
  const [editUser, setEditUser] = useState<EditableUser>(new EditableUser());
  const [promptActive, setPromptActive] = useState<boolean>(false);
  const [promptType, setPromptType] = useState<'' | 'delete' | 'deactivate'>('');
  const [accepted, setAccepted] = useState<boolean>(false);
  const { setAuthenticated, setUserData, setAdminUsers, adminUsers, authenticated, userData } =
    useContext(UserDataContext);

  useEffect(() => {
    GetAllUsers(setAdminUsers);
  }, [setAuthenticated, setUserData, setAdminUsers]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validEmail = validateEmail(editUser.email);
    const isMatch = editUser.newPassword === editUser.confirmNewPassword;
    if (!validEmail) {
      setError((formErrors) => ({ ...formErrors, email: { error: true, message: 'Email not valid' } }));
      return;
    }

    if (!isMatch) {
      return setError((formErrors) => ({
        ...formErrors,
        password: { error: true, message: 'Passwords need to match' },
      }));
    }

    if (!editUser.firstName) {
      return setError((formErrors) => ({
        ...formErrors,
        firstName: { error: true, message: 'First name is required' },
      }));
    }

    if (!editUser.lastName) {
      return setError((formErrors) => ({ ...formErrors, lastName: { error: true, message: 'Last name is required' } }));
    }

    if (showAddUserForm) {
      const body = {
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        email: editUser.email,
        role: editUser.role,
        newPassword: editUser.newPassword,
        confirmNewPassword: editUser.confirmNewPassword,
      };
      CreateAccountAdmin(body, setAdminUsers);
      handleClose();
    } else {
      const res: any = await UpdateAccount(editUser);
      if (res?.status === 200) {
        toast.success('Update successful', {
          position: toast.POSITION.TOP_CENTER,
        });
        handleClose();
        GetAllUsers(setAdminUsers);
      } else {
        handleClose();
      }
    }
  };

  const handleClose = (): void => {
    setModalOpen(false);
    setEditUser(new EditableUser());
    setPromptActive(false);
    setAccepted(false);
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement> | null): void => {
    if (event === null) return;
    if (accepted || !editUser.active) {
      setEditUser({
        ...editUser,
        [event.target.name]: event.target.checked,
      });
    } else {
      setPromptType('deactivate');
      setPromptActive(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent): void => {
    if (error) setError(new EditUserFormError());
    setEditUser({
      ...editUser,
      [event.target.name]: event.target.value,
    });
  };

  const handleDeleteUser = async () => {
    const res = await DeleteAccount(editUser.id);
    if (res?.status === 200) {
      setModalOpen(false);
      await GetAllUsers(setAdminUsers);
      toast.success("Account deleted successfully", { position: toast.POSITION.TOP_CENTER })
    }
  };

  const openAddNewUserForm = () => {
    setModalOpen(true);
    setShowAddUserForm(true);
  };

  const tableProps = {
    users: adminUsers,
    modalOpen,
    setModalOpen,
    editUser,
    setEditUser,
    setShowAddUserForm,
  };

  const editUserModalProps = {
    modalOpen,
    showAddUserForm,
    editUser,
    handleSubmit,
    handleClose,
    handleCheckbox,
    handleChange,
    handleDeleteUser,
    error,
    setEditUser,
    promptActive,
    setPromptActive,
    promptType,
    setPromptType,
    accepted,
    setAccepted,
  };

  if (
    authenticated !== 'loading' &&
    (!authenticated || UserRole[userData.role as keyof typeof UserRole] !== UserRole.SUPER_ADMIN)
  )
    return <Navigate to="/" />;
  return (
    <Box>
      <EditUserModal {...editUserModalProps} />
      <Grid container sx={{ mt: 6 }}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" component="h1" sx={{ ...typographyStyles }}>
            User Account Management
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={6} sm={6}>
            <Typography variant="h5" component="h2" sx={{ pb: 0, pt: '20px', ...typographyStyles }}>
              Users &amp; Roles
            </Typography>
            <Typography variant="subtitle1" component="h3" sx={{ pt: 1, pb: 2.5, ...typographyStyles }}>
              Displaying {adminUsers.length} out of {adminUsers.length} user accounts
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} display="flex" justifyContent="end" alignSelf="flex-end">
            <Button variant="contained" onClick={openAddNewUserForm} sx={{ pl: 3, pr: 3, mb: 2.5, ...buttonStyles }}>
              Add New User
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <UserTable {...tableProps} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAccountManagement;
