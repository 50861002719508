import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useScreenSize } from 'src/hooks/useScreenSize';
import { PolicyAnswer } from 'src/types/PolicyAnswer';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import Breakpoint from '../../../types/BreakPoint.enum';
import { GapAnalysisBar } from '../GapAnalysisBar/GapAnalysisBar';
import { DESCRIPTION_QUERY, POLICY_GAP_ANALYSIS_DATA } from './PolicyGapAnalysis.graphql';
import styles from './PolicyGapAnalysis.module.scss';
import GeneralInfoCard from '../../GeneralInfoCard/GeneralInfoCard';
import contentfulClient from 'src/common/apolloClient';
import { renderRichTextNoLinks } from 'src/utils/renderRichText.util';
import {
  PolicyGapAnalysisQueryInterface,
  PolicyGapAnalysisQueryVariableInterface,
} from './PolicyGapAnalysis.interface';
import getEnumUrlText from '../../../utils/getEnumUrlText';
import { StateOptionEnum } from '../../../types/StateOption.enum';
import getEnumDisplayText from '../../../utils/getEnumDisplayText';

interface PolicyGapAnalysisProps {
  state: StateOptionEnum;
}

interface Questions {
  subcategory: number;
  answers: PolicyAnswer[];
  numOfYes: PolicyAnswer[];
}

interface SubPolicyData {
  title: string;
  policy: {
    questions: Questions[];
  };
  tier: number;
}

export const PolicyGapAnalysis: FC<PolicyGapAnalysisProps> = ({ state }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const [hoverAndFocus, setHoverAndFocus] = useState({ id: '', status: false });

  const handleMouseEnter = (id: string) => {
    setHoverAndFocus({ id: id, status: true });
  };

  const handleMouseLeave = (id: string) => {
    setHoverAndFocus({ id: id, status: false });
  };

  const [questionsData, setQuestionsData] = useState<PolicyQuestion[]>([]);
  const [answersData, setAnswersData] = useState<PolicyAnswer[]>([]);
  const [policySubcategoriesData, setPolicySubcategoriesData] = useState<PolicySubcategory[]>([]);
  let description;
  const { data } = useQuery<PolicyGapAnalysisQueryInterface, PolicyGapAnalysisQueryVariableInterface>(
    POLICY_GAP_ANALYSIS_DATA,
    {
      variables: {
        stateId: state,
      },
    },
  );

  const { data: gapAnalysisDescription } = useQuery(DESCRIPTION_QUERY, { client: contentfulClient });

  useEffect(() => {
    if (data) {
      setQuestionsData(data.listPolicyQuestions);
      setAnswersData(data.getPolicyAnswersByStateId);
      setPolicySubcategoriesData(data.listActivePolicySubcategories);
    }
  }, [data, gapAnalysisDescription]);

  if (gapAnalysisDescription) {
    description = gapAnalysisDescription?.spotGapAnalysisCollection.items?.[0].content.json;
  }

  const subPolicyData: SubPolicyData[] = policySubcategoriesData.map((d: PolicySubcategory) => {
    return {
      title: d.title,
      policy: {
        questions: questionsData
          .map((d: PolicyQuestion) => {
            return {
              subcategory: d.subcategory.id,
              answers: answersData.filter((e: PolicyAnswer) => {
                // Change return statement to below after migration of all state policy answers to database
                return e.id === d.id;
              }),
              numOfYes: answersData.filter((e: PolicyAnswer) => {
                // Change return statement to below after migration of all state policy answers to database
                return e.question.subcategory.id === d.subcategory.id && e.value;
              }),
            };
          })
          .filter((e: Questions) => {
            return e.subcategory === d.id;
          }),
      },
      tier: d.tier,
    };
  });

  const marketPreparation: SubPolicyData[] = [];
  const marketCreation: SubPolicyData[] = [];
  const marketExpansion: SubPolicyData[] = [];

  subPolicyData.forEach((sub: SubPolicyData) => {
    if (sub.tier === 1) {
      marketPreparation.push(sub);
    } else if (sub.tier === 2) {
      marketCreation.push(sub);
    } else {
      marketExpansion.push(sub);
    }
  });

  const renderPolicyBars = (policyTypes: SubPolicyData[]) => {
    return policyTypes.map((policy: SubPolicyData, i: number) => {
      const stateUrl = getEnumUrlText(state);
      const policyUrl = policy.title.replace(/\s+/gi, '_').replace(/\//g, '&');

      return (
        <Link
          key={i}
          to={`/state/${stateUrl}/${policyUrl}`}
          className={styles.policyItem}
          onMouseEnter={() => {
            handleMouseEnter(policy.title);
          }}
          onFocus={() => {
            handleMouseEnter(policy.title);
          }}
          onMouseLeave={() => {
            handleMouseLeave(policy.title);
          }}
          onBlur={() => {
            handleMouseLeave(policy.title);
          }}
        >
          <div className={styles.policyName}>{policy.title}</div>
          <GapAnalysisBar
            yes={policy.policy.questions[0].numOfYes.length}
            total={policy.policy.questions.length}
            height="50px"
            width={
              screenSizeIsAtLeast[Breakpoint.SM] ? '255px' : screenSizeIsAtLeast[Breakpoint.XS] ? '225px' : '175px'
            }
            fontSize="1rem"
            hoverAndFocus={hoverAndFocus.status}
            barName={hoverAndFocus.id === policy.title ? policy.title : null}
          />
        </Link>
      );
    });
  };

  const preparationBars = renderPolicyBars(marketPreparation);
  const creationBars = renderPolicyBars(marketCreation);
  const expansionBars = renderPolicyBars(marketExpansion);

  return (
    <GeneralInfoCard
      title={'Gap Analysis'}
      description={renderRichTextNoLinks(
        description,
        policySubcategoriesData.length.toString(),
        getEnumDisplayText(state),
      )}
    >
      <div className={styles.contentBlock}>
        <div className={styles.firstColumn}>
          <div className={styles.columnHeading}>
            <div className={styles.number}>1</div>
            <div className={styles.columnHeadingText}>
              Market <span className={styles.emphasizedHeading}>PREPARATION</span>
            </div>
          </div>
          <div className={styles.columnContent}>
            <div className={styles.policyArea}>{preparationBars}</div>
          </div>
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.columnHeading}>
            <div className={styles.number}>2</div>
            <div className={styles.columnHeadingText}>
              Market <span className={styles.emphasizedHeading}>CREATION</span>
            </div>
          </div>
          <div className={styles.columnContent}>
            <div className={styles.policyArea}>{creationBars}</div>
          </div>
        </div>
        <div className={styles.thirdColumn}>
          <div className={styles.columnHeading}>
            <div className={styles.number}>3</div>
            <div className={styles.columnHeadingText}>
              Market <span className={styles.emphasizedHeading}>EXPANSION</span>
            </div>
          </div>
          <div className={styles.columnContent}>
            <div className={styles.policyArea}>{expansionBars}</div>
          </div>
        </div>
      </div>
    </GeneralInfoCard>
  );
};
