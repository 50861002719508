import { Expose } from 'class-transformer';
import { PolicyCategory } from './PolicyCategory';

export class PolicySubcategory {
  @Expose() id: number = 1;
  @Expose() title: string = '';
  @Expose() description: string = '';
  @Expose() policyBrief: string = '';
  @Expose() category: PolicyCategory = new PolicyCategory();
  @Expose() tier: number = 1;
  @Expose() active: boolean = false;
  @Expose() lastModifiedDate: Date = new Date('1970-01-01 00:00:01');
}
