export enum FuelNameEnum {
  BIOMASS = "Biomass",
  COAL = "Coal",
  GEOTHERMAL = "Geothermal",
  HYDROELECTRIC = "Hydroelectric",
  NATURAL_GAS = "Natural Gas",
  NUCLEAR = "Nuclear",
  OTHER = "Other",
  PETROLEUM = "Petroleum",
  SOLAR = "Solar",
  WIND = "Wind",
}