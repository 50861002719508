export enum PoliticalParty {
  R = 'R',
  D = 'D',
  UNKNOWN = 'UNKNOWN',
  NONPARTISAN = 'NONPARTISAN',
}

export const politicalPartyText = {
  [PoliticalParty.R]: 'R',
  [PoliticalParty.D]: 'D',
  [PoliticalParty.UNKNOWN]: 'N/A',
  [PoliticalParty.NONPARTISAN]: 'Nonpartisan'
}

export const PoliticalParties = Object.entries(politicalPartyText).map(([value, label]) => ({ value, label }));
