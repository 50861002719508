import React, { ChangeEvent, Dispatch, FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import styles from './Commission.module.scss';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { Snapshot } from 'src/components/StateSnapshot/types/Snapshot.class';
import { plainToInstance } from 'class-transformer';
import { State } from 'src/types/State.enum';

interface CommissionProps {
  state: State;
  snapshot: Snapshot;
  setSnapshot: Dispatch<Snapshot>;
}
export const Commission: FC<CommissionProps> = ({ snapshot, setSnapshot }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const commissionTypes = ['Appointed', 'Elected', ''];

  const handleSnapshotChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = event.target;
    const updated = {
      ...snapshot,
      [name]: value,
    };
    setSnapshot(plainToInstance(Snapshot, updated));
  };

  const data = [
    {
      id: 1,
      label: 'Commission Type',
      fieldType: 'textfield',
    },
    {
      id: 2,
      label: 'Commission Link Label',
      fieldType: 'textfield',
    },
    {
      id: 3,
      label: 'Commission Link URL',
      fieldType: 'textfield',
    },
  ];

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>Commission</div>
      <div className={styles.sectionContent}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: screenSizeIsAtLeast[Breakpoint.LG] ? '50%' : '100%',
            margin: '1rem 0',
          }}
        >
          <label className={styles.label} htmlFor="commissionType">
            {data[0].label}:
          </label>
          <TextField
            id="commissionType"
            name="commissionType"
            value={snapshot.commissionType}
            onChange={handleSnapshotChange}
            variant="outlined"
            placeholder="Commission Type"
            sx={{ width: '70%' }}
            InputProps={{
              style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: screenSizeIsAtLeast[Breakpoint.LG] ? '50%' : '100%',
            margin: '1rem 0',
          }}
        >
          <GeneralSelect
            id="commissionerPosition"
            name="commissionerPosition"
            labelText="Are Commissioners Appointed or Elected?:"
            isAdmin
            labelLeft
            data={commissionTypes}
            value={snapshot.commissionerPosition}
            onChange={handleSnapshotChange}
            defaultStyle={true}
            width="100%"
          />
        </Box>
        <div className={styles.groupedFields}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
            }}
          >
            <label className={styles.label} htmlFor="commissionLinkLabel">
              {data[1].label}:
            </label>
            <TextField
              id="commissionLinkLabel"
              name="commissionLinkLabel"
              value={snapshot.commissionLinkLabel}
              onChange={handleSnapshotChange}
              variant="outlined"
              placeholder="Commission Link Label"
              sx={{ width: '70%' }}
              InputProps={{
                style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
              }}
              InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
            }}
          >
            <label className={`${styles.label} ${styles.groupedFieldLabel}`} htmlFor="commissionLinkUrl">
              {data[2].label}:
            </label>
            <TextField
              id="commissionLinkUrl"
              name="commissionLinkUrl"
              value={snapshot.commissionLinkUrl}
              onChange={handleSnapshotChange}
              variant="outlined"
              placeholder="Commission Link URL"
              sx={{ width: '70%' }}
              InputProps={{
                style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
              }}
              InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};
