export enum FuelColorEnum {
  BIOMASS = "#E4993C",
  COAL = "#333333",
  GEOTHERMAL = "#A63A00",
  HYDROELECTRIC = "#095c6a",
  NATURAL_GAS = "#c1c13e",
  NUCLEAR = "#74c5ce",
  OTHER = "#888888",
  PETROLEUM = "#7d6d41",
  SOLAR = "#f1c40f",
  WIND = "#d1e36a",
}
