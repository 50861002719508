import { Expose, Transform } from 'class-transformer';
import 'reflect-metadata';
import CommissionerPosition from 'src/types/CommissionerPosition.enum';
import { PoliticalParty } from 'src/types/PoliticalParty.enum';
import { State } from 'src/types/State.enum';
import { OfficeLink } from './OfficeLink.interface';

export class Snapshot {
  @Expose()
  stateId: State = State.UNKNOWN;

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  governor: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  sessionLabel: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  sessionDates: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  linkLabel: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  linkUrl: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? PoliticalParty.UNKNOWN : value))
  houseMajority: PoliticalParty = PoliticalParty.UNKNOWN;

  @Expose()
  @Transform(({ value }) => (value === null ? PoliticalParty.UNKNOWN : value))
  senateMajority: PoliticalParty = PoliticalParty.UNKNOWN;

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  commissionType: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? CommissionerPosition.UNKNOWN : value))
  commissionerPosition: CommissionerPosition = CommissionerPosition.UNKNOWN;

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  commissionLinkLabel: string = '';

  @Expose()
  @Transform(({ value }) => (value === null ? '' : value))
  commissionLinkUrl: string = '';

  @Expose()
  officeLinks: OfficeLink[] = [];

  separateGovernor(index: number): string {
    if (!this.governor) {
      return '';
    }
    if (this.governor.includes('(' && ')')) {
      return this.governor.split('(')[index].replace(/[^a-zA-Z "']/g, '');
    }
    return this.governor;
  }
}
