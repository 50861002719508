import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const renderOptions = (links: any, policyCount: any, stateName: any) => {
  const assetMap = new Map();
  // Loop through the assets and add them to the map
  for (const asset of links.assets.hyperlink) {
    assetMap.set(asset.sys.id, asset);
  }

  for (const asset of links.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }

  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        if (node.data.uri.includes('player.vimeo.com/video')) {
          return (
            <div style={{ display: 'flex' }}>
              <iframe title={node.content[0].value} src={node.data.uri} frameBorder="0" allowFullScreen />
            </div>
          );
        } else if (node.data.uri.includes('youtube.com/embed')) {
          return (
            <div style={{ display: 'flex' }}>
              <iframe
                title={node.content[0].value}
                src={node.data.uri}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value || ''}
            </a>
          );
        }
      },

      [INLINES.ASSET_HYPERLINK]: (node: any) => {
        // find the entry in the entryMap by ID
        const entry = assetMap.get(node.data.target.sys.id);

        return <a href={entry.url}>{node.content[0].value || ''}</a>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        // find the entry in the entryMap by ID
        const entry = assetMap.get(node.data.target.sys.id);
        switch (entry.contentType) {
          case 'image/jpeg':
            return (
              <div style={{ display: 'flex' }}>
                <img src={entry.url} alt={entry.description} style={{ maxWidth: '90%', margin: '10px auto' }} />
              </div>
            );
          case 'image/png':
            return (
              <div style={{ display: 'flex' }}>
                <a href={entry.url} target="_blank" rel="noreferrer" download>
                  <img src={entry.url} alt={entry.description} style={{ maxWidth: '90%', margin: '10px auto' }} />
                </a>
              </div>
            );
          case 'application/pdf':
            return (
              <a href={entry.url} target="_blank" rel="noreferrer" download>
                {entry.title || ''}
              </a>
            );
          default:
            return <></>;
        }
      },
    },
    renderText: (text: string) => text.replace('{{policy_count}}', policyCount).replace('{{state_name}}', stateName),
  };
};

const renderOptionsNoLinks = (policyCount: string, stateName: string) => {
  return {
    renderText: (text: string) => text.replace('{{policy_count}}', policyCount).replace('{{state_name}}', stateName),
  };
};

// Rich Text renderer util supports Tables, Youtube/Vimeo Embed Videos, PDFS, Images, Inline Links to Assets aforementioned

export const renderRichText = (richTextDocument: any, links?: any, policyCount?: string, stateName?: string) => {
  if (links && (policyCount || stateName)) {
    return documentToReactComponents(richTextDocument, renderOptions(links, policyCount, stateName));
  } else {
    return documentToReactComponents(richTextDocument);
  }
};

export const renderRichTextNoLinks = (richTextDocument: any, policyCount: string, stateName: string) => {
  if (policyCount && stateName) {
    return documentToReactComponents(richTextDocument, renderOptionsNoLinks(policyCount, stateName));
  } else {
    return documentToReactComponents(richTextDocument);
  }
};
