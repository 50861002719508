import { StateOptionEnum } from '../../../types/StateOption.enum';
import { PolicyAnswer } from '../../../types/PolicyAnswer';
import { PolicyQuestion } from '../../../types/PolicyQuestion';
import { Grid } from '@mui/material';
import { colors } from '../../../theme/colors';
import styles from '../StatePolicyComparison.module.scss';
import React, { FC } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import formatEnumToString from '../../../utils/formatEnumToString';

interface Props {
  questionsLength: number;
  states: StateOptionEnum[];
  answers?: PolicyAnswer[];
  questionData?: PolicyQuestion[];
}

const numberSpelled: { [k: number]: string } = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
};

export const QuestionsAndAnswers: FC<Props> = ({ questionData, questionsLength, states, answers }) => {
  if (!questionData) return <></>;
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((questionNum, questionIndex) => (
        <React.Fragment key={`row${questionNum}`}>
          <Grid
            item
            style={{
              gridArea: `number${questionNum}`,
              color: colors.darkGray2,
              fontSize: '3.438rem',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            {questionNum}
          </Grid>
          <Grid
            item
            style={{
              gridArea: `question${questionNum}`,
              fontSize: '1.13rem',
              alignSelf: 'center',
              justifySelf: 'start',
            }}
          >
            {questionData[questionIndex]
              ? questionData[questionIndex].text
              : `This policy only has ${numberSpelled[questionsLength]} components.`}
          </Grid>
          {[1, 2, 3, 4].map((stateNum) => (
            <React.Fragment key={`answer state${stateNum}Q${questionNum}`}>
              <AnswerGridCell
                gridArea={`state${stateNum}Q${questionNum}`}
                state={states[stateNum - 1]}
                questionId={questionData[questionIndex]?.id}
                answers={answers}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

function AnswerGridCell({
  gridArea,
  questionId,
  state,
  answers,
}: {
  gridArea: string;
  state: StateOptionEnum;
  questionId?: number;
  answers?: PolicyAnswer[];
}) {
  const { screenSizeIsAtLeast } = useScreenSize();
  return (
    <Grid
      item
      aria-label={`question id:${questionId ?? ''} for state ${state}`}
      style={{
        gridArea: gridArea,
        alignSelf: 'center',
        justifySelf: screenSizeIsAtLeast[Breakpoint.MD] ? 'center' : 'start',
      }}
    >
      <YesNoOrNA questionId={questionId} state={state} answers={answers} />
    </Grid>
  );
}

function YesNoOrNA({
  questionId,
  state,
  answers,
}: {
  state: StateOptionEnum;
  questionId?: number;
  answers?: PolicyAnswer[];
}) {
  const answer = answers?.find((answer) => answer.question.id === questionId && answer.stateId === state);
  if (!answers) return <></>;
  if (!questionId || !answer) return <span className={styles.notApp}>N/A</span>;

  return (
    <div className={styles.answerContainer}>
      {answer?.value ? <span className={styles.yes}>Y</span> : <span className={styles.no}>N</span>}
      <span className={styles.answerStateText}>{formatEnumToString(state)}</span>
    </div>
  );
}
