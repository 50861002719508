import { GeneralSelectOption } from '../../../components/GeneralSelect/GeneralSelect';
import { StateOptionEnum } from '../../../types/StateOption.enum';

export const stateOptionsMobile: GeneralSelectOption[] = [
  {
    value: StateOptionEnum.ALABAMA,
    label: 'AL',
  },
  {
    value: StateOptionEnum.ALASKA,
    label: 'AK',
  },
  {
    value: StateOptionEnum.ARIZONA,
    label: 'AZ',
  },
  {
    value: StateOptionEnum.ARKANSAS,
    label: 'AR',
  },
  {
    value: StateOptionEnum.CALIFORNIA,
    label: 'CA',
  },
  {
    value: StateOptionEnum.COLORADO,
    label: 'CO',
  },
  {
    value: StateOptionEnum.CONNECTICUT,
    label: 'CT',
  },
  {
    value: StateOptionEnum.DELAWARE,
    label: 'DE',
  },
  {
    value: StateOptionEnum.FLORIDA,
    label: 'FL',
  },
  {
    value: StateOptionEnum.GEORGIA,
    label: 'GA',
  },
  {
    value: StateOptionEnum.HAWAII,
    label: 'HI',
  },
  {
    value: StateOptionEnum.IDAHO,
    label: 'ID',
  },
  {
    value: StateOptionEnum.ILLINOIS,
    label: 'IL',
  },
  {
    value: StateOptionEnum.INDIANA,
    label: 'IN',
  },
  {
    value: StateOptionEnum.IOWA,
    label: 'IA',
  },
  {
    value: StateOptionEnum.KANSAS,
    label: 'KS',
  },
  {
    value: StateOptionEnum.KENTUCKY,
    label: 'KY',
  },
  {
    value: StateOptionEnum.LOUISIANA,
    label: 'LA',
  },
  {
    value: StateOptionEnum.MAINE,
    label: 'ME',
  },
  {
    value: StateOptionEnum.MARYLAND,
    label: 'MD',
  },
  {
    value: StateOptionEnum.MASSACHUSETTS,
    label: 'MA',
  },
  {
    value: StateOptionEnum.MICHIGAN,
    label: 'MI',
  },
  {
    value: StateOptionEnum.MINNESOTA,
    label: 'MN',
  },
  {
    value: StateOptionEnum.MISSISSIPPI,
    label: 'MS',
  },
  {
    value: StateOptionEnum.MISSOURI,
    label: 'MO',
  },
  {
    value: StateOptionEnum.MONTANA,
    label: 'MT',
  },
  {
    value: StateOptionEnum.NEBRASKA,
    label: 'NE',
  },
  {
    value: StateOptionEnum.NEVADA,
    label: 'NV',
  },
  {
    value: StateOptionEnum.NEW_HAMPSHIRE,
    label: 'NH',
  },
  {
    value: StateOptionEnum.NEW_JERSEY,
    label: 'NJ',
  },
  {
    value: StateOptionEnum.NEW_MEXICO,
    label: 'NM',
  },
  {
    value: StateOptionEnum.NEW_YORK,
    label: 'NY',
  },
  {
    value: StateOptionEnum.NORTH_CAROLINA,
    label: 'NC',
  },
  {
    value: StateOptionEnum.NORTH_DAKOTA,
    label: 'ND',
  },
  {
    value: StateOptionEnum.OHIO,
    label: 'OH',
  },
  {
    value: StateOptionEnum.OKLAHOMA,
    label: 'OK',
  },
  {
    value: StateOptionEnum.OREGON,
    label: 'OR',
  },
  {
    value: StateOptionEnum.PENNSYLVANIA,
    label: 'PA',
  },
  {
    value: StateOptionEnum.RHODE_ISLAND,
    label: 'RI',
  },
  {
    value: StateOptionEnum.SOUTH_CAROLINA,
    label: 'SC',
  },
  {
    value: StateOptionEnum.SOUTH_DAKOTA,
    label: 'SD',
  },
  {
    value: StateOptionEnum.TENNESSEE,
    label: 'TN',
  },
  {
    value: StateOptionEnum.TEXAS,
    label: 'TX',
  },
  {
    value: StateOptionEnum.UTAH,
    label: 'UT',
  },
  {
    value: StateOptionEnum.VERMONT,
    label: 'VT',
  },
  {
    value: StateOptionEnum.VIRGINIA,
    label: 'VA',
  },
  {
    value: StateOptionEnum.WASHINGTON,
    label: 'WA',
  },
  {
    value: StateOptionEnum.WEST_VIRGINIA,
    label: 'WV',
  },
  {
    value: StateOptionEnum.WISCONSIN,
    label: 'WI',
  },
  {
    value: StateOptionEnum.WYOMING,
    label: 'WY',
  },
  {
    value: StateOptionEnum.UNKNOWN,
    label: '',
  },
];
