import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { plainToInstance } from 'class-transformer';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { STATE_SNAPSHOT_QUERY, UPDATE_SNAPSHOT_MUTATION } from 'src/components/StateSnapshot/StateSnapshot.graphql';
import { Snapshot } from 'src/components/StateSnapshot/types/Snapshot.class';
import { PolicyAnswerInput } from 'src/types/PolicyAnswerInput';
import { getState, State, States } from 'src/types/State.enum';
import { stripTypenames } from 'src/utils/stripTypenames';
import styles from './StateDataAdmin.module.scss';
import { StatePolicyAreaAccordion } from './StatePolicyMgmt/StatePolicyAreaAccordion';
import { StateSnapshotAccordion } from './StateSnapshotMgmt/StateSnapshotAccordion';

export const StateDataAdmin: FC = () => {
  const { stateId }: any = useParams();
  const navigate = useNavigate();
  const states: string[] = States;
  const state: State = State[getState(stateId)];
  const [dropDownFilter, setDropDownFilter] = useState<string>(state);
  const [snapshot, setSnapshot] = useState<Snapshot>(new Snapshot());
  const [policyAnswers, setPolicyAnswers] = useState<PolicyAnswerInput[]>([]);
  const [errors, setErrors] = useState<number[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const { data, loading, error } = useQuery(STATE_SNAPSHOT_QUERY, {
    variables: { stateId: getState(stateId) },
  });

  const [updateSnapshot] = useMutation(UPDATE_SNAPSHOT_MUTATION, {
    onCompleted() {
      toast.success('Snapshot saved', {
        position: toast.POSITION.TOP_CENTER,
      });
      setSaving(false);
    },
    onError() {
      toast.error('Snapshot did not save. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
      setSaving(false);
    },
  });

  useEffect(() => {
    if (data && data.getStateSnapshotByStateId) {
      const { getStateSnapshotByStateId, getOfficeLinks } = data;
      const snapshotInstance = plainToInstance(
        Snapshot,
        { ...getStateSnapshotByStateId, officeLinks: getOfficeLinks },
        { enableImplicitConversion: true },
      );
      setSnapshot(snapshotInstance);
    } else if (!loading && !error) {
      setSnapshot(new Snapshot());
    }
    setDropDownFilter(state.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, state, setDropDownFilter]);

  const handleChange = (e: SelectChangeEvent) => {
    if (e.target.value !== '') navigate(`/admin/state/${e.target.value.replace(/\s+/gi, '_').replace(/\//g, '&')}`);
    setDropDownFilter(e.target.value);
  };

  const saveSnapshot = (e: any) => {
    e.stopPropagation();
    setSaving(true);
    const { officeLinks, ...rest } = stripTypenames(snapshot);
    rest.stateId = getState(stateId);
    const answers = [];
    const errors = [];

    for (const input of policyAnswers) {
      if (!input.url) errors.push(input.id);
      if (!input.changed) continue;

      answers.push({
        id: input.id,
        value: input.value === 'yes',
        url: input.url,
        stateId: getState(stateId),
        question: input.question.id,
      });
    }

    if (!!errors.length) {
      toast.error('All Policy Component links must have a URL!', { position: toast.POSITION.TOP_CENTER });
      setErrors(errors);
      setSaving(false);
      return;
    }

    const payload = {
      stateId: getState(stateId),
      snapshot: rest,
      links: officeLinks,
      answers,
    };

    updateSnapshot({ variables: payload });
  };

  const cancelEdit = () => {
    navigate('/');
  };

  return (
    <div className={styles.pageContainer}>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>SPOT Data Administration</Heading>
      <Box component="div" className={styles.selectContent}>
        <Box className={styles.dropDownSection}>
          <Box component="div" className={styles.viewing}>
            Viewing:
          </Box>
          <Box component="div" className={styles.stateSelectContainer}>
            <GeneralSelect
              id="stateSelector"
              labelText="Choose another state"
              data={states}
              value={dropDownFilter}
              onChange={handleChange}
              defaultStyle={true}
              width="100%"
            />
          </Box>
        </Box>
        <Box className={styles.buttonSection}>
          <Box className={styles.buttonColumnWrapper}>
            <AdminButton text="Cancel" onClick={cancelEdit} defaultStyle={false} />
            <AdminButton text="Save" onClick={saveSnapshot} defaultStyle={true} disabled={saving} />
          </Box>
        </Box>
      </Box>
      {!!snapshot && (
        <>
          <div className={styles.stateSnapshotMgmtContent}>
            <StateSnapshotAccordion
              state={State[getState(stateId)]}
              snapshot={snapshot}
              setSnapshot={setSnapshot}
              handleSave={saveSnapshot}
              saving={saving}
            />
          </div>
          <div className={styles.statePolicyMgmtContent}>
            <StatePolicyAreaAccordion
              stateName={state.toString()}
              policyAnswers={policyAnswers}
              setPolicyAnswers={setPolicyAnswers}
              handleSave={saveSnapshot}
              saving={saving}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
        </>
      )}
    </div>
  );
};
