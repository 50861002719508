import { ChangeEvent, Dispatch, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { EditableUser } from 'src/types/EditableUser';
import { buttonStyles, buttonOutlinedStyles } from '../buttonStyles';

interface DeactivateUserPromptProps {
  setPromptActive: Dispatch<boolean>;
  promptType: '' | 'delete' | 'deactivate';
  setPromptType: Dispatch<'' | 'delete' | 'deactivate'>;
  setAccepted: Dispatch<boolean>;
  handleCheckbox: (e: ChangeEvent<HTMLInputElement> | null) => void;
  handleDeleteUser: () => void;
  editUser: EditableUser;
  setEditUser: Dispatch<EditableUser>;
}

const DeactivateUserPrompt = ({
  setPromptActive,
  promptType,
  setPromptType,
  setAccepted,
  editUser,
  setEditUser,
  handleDeleteUser,
}: DeactivateUserPromptProps) => {
  useEffect(() => { }, [setAccepted]);

  const title = promptType !== '' ? promptType.charAt(0).toUpperCase() + promptType.slice(1) : '';

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Typography component="h2" variant="h5" sx={{ fontFamily: 'Lato', fontSize: 22, fontWeight: 'bold' }}>
          {title} {editUser.firstName} {editUser.lastName}&apos;s Account
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
        <Typography sx={{ fontFamily: 'Lato', fontSize: 18 }}>
          Are you sure you want to {promptType} this account?
        </Typography>
        <Typography sx={{ fontFamily: 'Lato', fontSize: 18 }}>
          The user will lose administrative access and privileges.
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={12} sx={{ mt: 3 }} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={(_) => {
            if (promptType === 'delete') {
              handleDeleteUser();
            } else {
              setAccepted(true);
              setEditUser({ ...editUser, active: false });
            }
            setPromptActive(false);
            setPromptType('');
          }}
          sx={{ ...buttonOutlinedStyles }}
        >
          Yes, {promptType}
        </Button>
        <Button variant="contained" onClick={(_) => {
          setPromptActive(false);
          setPromptType('');
        }} sx={{ ml: 2, ...buttonStyles }}>
          No, do not {promptType}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeactivateUserPrompt;
