import { useQuery } from '@apollo/client';
import { FC } from 'react';
import styles from './Disclaimer.module.scss';
import contentfulClient from 'src/common/apolloClient';
import Heading from '../Heading/Heading';
import HeadingStyle from '../Heading/HeadingStyle.enum';
import { DISCLAIMER_QUERY } from './Disclaimer.graphql';

const Disclaimer: FC = () => {
  const { data } = useQuery(DISCLAIMER_QUERY, { client: contentfulClient });
  let header = '';
  let description = '';
  if (data) {
    let response = data?.disclaimerSectionCollection?.items[0];
    header = response.header;
    description = response.description;
  }
  return (
    <div className={styles.disclaimerWrapper}>
      {data && (
        <div className={styles.disclaimerSection}>
          <Heading headingStyle={HeadingStyle.PAGE_TITLE}>
            {header}
          </Heading>
          <article className={styles.description}>{description}</article>
        </div>
      )}
    </div>
  );
};

export default Disclaimer;
