import { IconButton } from '@mui/material';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './CloseButton.module.scss';

interface CloseButtonProps {
  handleClose: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ handleClose }) => {
  return (
    <div className={styles.closeWrapper}>
      <IconButton onClick={handleClose} aria-label="close Modal">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default CloseButton;
