import { AccordionDetails, CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { AccordionSummary, SubAccordion } from 'src/pages/DataAdmin/DataAdminAccordionTheme';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import styles from './SubcategoryAccordion.module.scss';

import { PolicyAnswerInput } from 'src/types/PolicyAnswerInput';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import PolicyAnswerSection from './PolicyAnswerSection';
import { AdminButton } from '../AdminButton/AdminButton';

interface SubcategoryAccordionProps {
  subcategory: PolicySubcategory;
  questions: PolicyQuestion[];
  answers: PolicyAnswerInput[];
  policyAnswers: PolicyAnswerInput[];
  setPolicyAnswers: Dispatch<SetStateAction<PolicyAnswerInput[]>>;
  handleSave: (e: any) => void;
  saving: boolean;
  errors: number[],
  setErrors: Dispatch<SetStateAction<number[]>>;
}

const SubcategoryAccordion = ({
  subcategory,
  questions,
  answers,
  policyAnswers,
  setPolicyAnswers,
  handleSave,
  saving,
  errors,
  setErrors
}: SubcategoryAccordionProps) => {
  const totalYesAnswers = answers.reduce(
    (count: number, answer: PolicyAnswerInput) => (answer.value === 'yes' ? count + 1 : count),
    0,
  );

  if (policyAnswers.length === 0) return <CircularProgress />;

  return (
    <SubAccordion sx={{ mb: 2 }} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary>
        <div className={styles.summaryContent}>
          <span>
            Policy Area: {subcategory.title} ({totalYesAnswers} of {questions.length})
          </span>
        </div>
        <AdminButton text="Save" overwriteUnderline={true} onClick={handleSave} defaultStyle={true} disabled={saving} />
      </AccordionSummary>
      <AccordionDetails>
        {questions.map((question: PolicyQuestion) => (
          <PolicyAnswerSection
            key={'answer-' + question.id}
            question={question}
            answer={answers.find(a => a.question.id === question.id)}
            setPolicyAnswers={setPolicyAnswers}
            errors={errors}
            setErrors={setErrors}
          />
        ))}
      </AccordionDetails>
    </SubAccordion>
  );
};

export default SubcategoryAccordion;
