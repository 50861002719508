import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { States } from 'src/types/State.enum';
import { STATE_GAP_ANALYSIS_DATA } from './StateGapAnalysis.graphql';
import { useQuery } from '@apollo/client';
import { GapAnalysisBar } from '../GapAnalysisBar/GapAnalysisBar';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import styles from './StateGapAnalysis.module.scss';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { PolicyAnswerCount } from './types/PolicyAnswerCount.class';

interface StateGapAnalysisProps {
  policyName: string;
  policy: PolicySubcategory;
}

interface StateData {
  name: string;
  questions: PolicyQuestion[];
  numOfYes: number;
}

export const StateGapAnalysis: FC<StateGapAnalysisProps> = ({ policyName, policy }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const states: string[] = States.filter((state) => state !== '');
  const [questionsData, setQuestionsData] = useState<PolicyQuestion[]>([]);
  const [answersData, setAnswersData] = useState<PolicyAnswerCount[]>([]);
  const [hoverAndFocus, setHoverAndFocus] = useState({ id: '', status: false });
  const { data, error, loading } = useQuery(STATE_GAP_ANALYSIS_DATA, {
    variables: {
      subcategory: policy.id,
    },
  });

  const handleMouseEnter = (id: string) => {
    setHoverAndFocus({ id: id, status: true });
  };

  const handleMouseLeave = (id: string) => {
    setHoverAndFocus({ id: id, status: false });
  };

  useEffect(() => {
    if (data) {
      setQuestionsData(data.listPolicyQuestions);
      setAnswersData(data.getYesCountsByPolicy);
    }
  }, [data]);

  if (error || (!loading && data.getYesCountsByPolicy.length < 0)) return <>Something went wrong!</>;

  const stateData: StateData[] = states.map((state: string) => {
    return {
      name: state,
      questions: questionsData.filter((question: PolicyQuestion) => {
        return question.subcategory.title === policyName;
      }),
      numOfYes:
        answersData?.find((answer: PolicyAnswerCount) => {
          // Change return statement to below after migration of all state policy answers to database
          return answer?.state === state;
        })?.count || 0,
    };
  });

  const renderPolicyBars = (states: StateData[]) => {
    return states.map((state: StateData, i: number) => {
      const stateUrl = state.name.replace(/\s+/g, '_');
      const policyUrl = policyName.replace(/\s+/gi, '_').replace(/\//g, '&');
      return (
        <Link
          key={i}
          to={`/state/${stateUrl}/${policyUrl}`}
          className={styles.stateItem}
          onMouseEnter={() => {
            handleMouseEnter(state.name);
          }}
          onFocus={() => {
            handleMouseEnter(state.name);
          }}
          onMouseLeave={() => {
            handleMouseLeave(state.name);
          }}
          onBlur={() => {
            handleMouseLeave(state.name);
          }}
        >
          <div className={styles.stateName}>{state.name}</div>
          <GapAnalysisBar
            yes={state.numOfYes}
            total={state.questions.length}
            height="50px"
            width={
              screenSizeIsAtLeast[Breakpoint.SM] ? '255px' : screenSizeIsAtLeast[Breakpoint.XS] ? '225px' : '175px'
            }
            fontSize="1rem"
            hoverAndFocus={hoverAndFocus.status}
            barName={hoverAndFocus.id === state.name ? state.name : null}
          />
        </Link>
      );
    });
  };

  const bars = renderPolicyBars(stateData);

  return (
    <div id="gap-analysis" className={styles.stateGapAnalysisSection}>
      <div className={styles.contentTitle}>
        GAP ANALYSIS&nbsp;
        <span className={styles.policyName}>
          <span className={styles.hyphen}>-&nbsp;</span>
          {policyName}
        </span>
      </div>
      <div className={styles.contentBlock}>{bars}</div>
    </div>
  );
};
