import React, { ChangeEvent, Dispatch, FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import styles from './LeadershipAndLegislators.module.scss';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { Snapshot } from 'src/components/StateSnapshot/types/Snapshot.class';
import { plainToInstance } from 'class-transformer';
import { PoliticalParties } from 'src/types/PoliticalParty.enum';
import { State } from 'src/types/State.enum';

interface LeadershipAndLegislatorsProps {
  state: State;
  snapshot: Snapshot;
  setSnapshot: Dispatch<Snapshot>;
}

export const LeadershipAndLegislators: FC<LeadershipAndLegislatorsProps> = ({ snapshot, setSnapshot }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const handleSnapshotChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    const updated: any = {
      ...snapshot,
      [name]: value,
    };

    setSnapshot(plainToInstance(Snapshot, updated));
  };

  const inputContainer = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  return (
    <Box component="form" className={styles.content}>
      <div className={styles.sectionTitle}>Leadership and Legislators</div>
      <Box
        sx={{
          ...inputContainer,
          width: screenSizeIsAtLeast[Breakpoint.LG] ? '50%' : '100%',
          margin: '1rem 0',
        }}
      >
        <label className={styles.label} htmlFor="governor">
          Governor:
        </label>
        <TextField
          id="governor"
          name="governor"
          variant="outlined"
          placeholder="Governor"
          sx={{ width: '70%' }}
          InputProps={{
            style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
          }}
          InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          value={snapshot.governor}
          onChange={handleSnapshotChange}
        />
      </Box>
      <div className={styles.groupedFields}>
        <Box
          sx={{
            ...inputContainer,
            width: '100%',
            margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
          }}
        >
          <label className={styles.label} htmlFor="sessionLabel">
            Legislative Session Label:
          </label>
          <TextField
            id="sessionLabel"
            name="sessionLabel"
            value={snapshot.sessionLabel}
            onChange={handleSnapshotChange}
            variant="outlined"
            placeholder="Legislative Session Label"
            sx={{ width: '70%' }}
            InputProps={{
              style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          />
        </Box>
        <Box
          sx={{
            ...inputContainer,
            width: '100%',
            margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
          }}
        >
          <label className={`${styles.label} ${styles.groupedFieldLabel}`} htmlFor="sessionDates">
            Legislative Session Dates:
          </label>
          <TextField
            id="sessionDates"
            name="sessionDates"
            value={snapshot.sessionDates}
            onChange={handleSnapshotChange}
            variant="outlined"
            placeholder="Legislative Session Dates"
            sx={{ width: '70%' }}
            InputProps={{
              style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          />
        </Box>
      </div>
      <div className={styles.groupedFields}>
        <Box
          sx={{
            ...inputContainer,
            width: '100%',
            margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
          }}
        >
          <label className={styles.label} htmlFor="linkLabel">
            Legislature / General Assembly Link Label:
          </label>
          <TextField
            id="linkLabel"
            name="linkLabel"
            value={snapshot.linkLabel}
            onChange={handleSnapshotChange}
            variant="outlined"
            placeholder="Legislature / General Assembly Link Label"
            sx={{ width: '70%' }}
            InputProps={{
              style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          />
        </Box>
        <Box
          sx={{
            ...inputContainer,
            width: '100%',
            margin: screenSizeIsAtLeast[Breakpoint.LG] ? '0' : '1rem 0',
          }}
        >
          <label className={`${styles.label} ${styles.groupedFieldLabel}`} htmlFor="linkUrl">
            Legislature / General Assembly Link URL:
          </label>
          <TextField
            id="linkUrl"
            name="linkUrl"
            value={snapshot.linkUrl}
            onChange={handleSnapshotChange}
            variant="outlined"
            placeholder="Legislature / General Assembly Link URL"
            sx={{ width: '70%' }}
            InputProps={{
              style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
          />
        </Box>
      </div>
      <Box
        sx={{
          ...inputContainer,
          width: screenSizeIsAtLeast[Breakpoint.LG] ? '50%' : '100%',
          margin: '1rem 0',
        }}
      >
        <GeneralSelect
          id="houseMajority"
          name="houseMajority"
          labelText="House Majority Party:"
          isAdmin
          labelLeft
          data={PoliticalParties}
          value={snapshot.houseMajority}
          onChange={handleSnapshotChange}
          defaultStyle={true}
          width="100%"
          partySelector
        />
      </Box>
      <Box
        sx={{
          ...inputContainer,
          width: screenSizeIsAtLeast[Breakpoint.LG] ? '50%' : '100%',
        }}
      >
        <GeneralSelect
          id="senateMajority"
          name="senateMajority"
          labelText="Senate Majority Party:"
          labelLeft
          isAdmin
          data={PoliticalParties}
          value={snapshot.senateMajority}
          onChange={handleSnapshotChange}
          defaultStyle={true}
          width="100%"
          partySelector
        />
      </Box>
    </Box>
  );
};
