import React, { ChangeEvent, Dispatch, FormEvent } from 'react';
import { EditableUser } from 'src/types/EditableUser';
import { EditUserFormError } from '../UserAccountManagement';
import EditUserForm from '../EditUserForm/EditUserForm';
import DeactivateUserPrompt from '../DeactiveUserPrompt/DeactivateUserPrompt';
import { GeneralModal } from 'src/components/GeneralModal/GeneralModal';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { SelectChangeEvent } from '@mui/material';

interface EditUserModalProps {
  modalOpen: boolean;
  showAddUserForm: boolean;
  editUser: EditableUser;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleClose: () => void;
  handleCheckbox: (e: ChangeEvent<HTMLInputElement> | null) => void;
  handleChange: (e: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => void;
  handleDeleteUser: () => void;
  error: EditUserFormError;
  setEditUser: Dispatch<EditableUser>;
  promptActive: boolean;
  setPromptActive: Dispatch<boolean>;
  promptType: '' | 'delete' | 'deactivate';
  setPromptType: Dispatch<'' | 'delete' | 'deactivate'>;
  accepted: boolean;
  setAccepted: Dispatch<boolean>;
}

const EditUserModal = ({
  modalOpen,
  editUser,
  showAddUserForm,
  handleSubmit,
  handleClose,
  handleCheckbox,
  handleChange,
  handleDeleteUser,
  error,
  setEditUser,
  promptActive,
  setPromptActive,
  promptType,
  setPromptType,
  accepted,
  setAccepted,
}: EditUserModalProps) => {
  const formProps = {
    handleSubmit,
    handleChange,
    handleCheckbox,
    handleClose,
    handleDeleteUser,
    editUser,
    showAddUserForm,
    error,
    promptActive,
    setPromptActive,
    setPromptType,
    accepted,
    setAccepted,
  };

  const deactivateUserPromptProps = {
    promptActive,
    setPromptActive,
    promptType,
    setPromptType,
    setAccepted,
    handleCheckbox,
    handleDeleteUser,
    editUser,
    setEditUser,
  };

  const { screenSizeIsAtLeast } = useScreenSize();

  const deactivate = {
    width: screenSizeIsAtLeast[Breakpoint.BASE] ? '500px' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.BASE] ? '30px' : '22px',
  };

  const edit = {
    width: screenSizeIsAtLeast[Breakpoint.BASE] ? '750px' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.BASE] ? '30px' : '22px',
  };

  let customSize = promptActive ? deactivate : edit;

  return (
    <GeneralModal modalOpen={modalOpen} setModalOpen={handleClose} styling={customSize}>
      {promptActive ? <DeactivateUserPrompt {...deactivateUserPromptProps} /> : <EditUserForm {...formProps} />}
    </GeneralModal>
  );
};

export default EditUserModal;
