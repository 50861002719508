import { FC } from 'react';
import Button from '@mui/material/Button';
import { colors } from 'src/theme/colors';
import Breakpoint from 'src/types/BreakPoint.enum';
import { useScreenSize } from 'src/hooks/useScreenSize';

export interface AdminButtonProps {
  onClick: (() => void) | ((e: any) => void);
  disabled?: boolean;
  text: string;
  defaultStyle: boolean;
  overwriteUnderline?: boolean;
}

export const AdminButton: FC<AdminButtonProps> = ({ text, disabled, onClick, defaultStyle, overwriteUnderline }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disableElevation
      disableFocusRipple
      disabled={!!disabled}
      sx={{
        flexGrow: 0,
        fontFamily: 'Lato',
        fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '1rem' : '.9rem',
        width: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : 'initial',
        padding: '10px 25px',
        borderRadius: '6px',
        border: 'solid black 1px',
        background: defaultStyle ? colors.black : 'none',
        color: defaultStyle ? colors.white : colors.black,
        textTransform: 'none',
        boxShadow: 'none',
        textDecoration: overwriteUnderline ? 'underline' : 'none',
        textDecorationColor: defaultStyle ? colors.black : colors.white,
        '&:focus-visible': {
          outline: `4px double ${colors.black}`,
          background: 'none',
          backgroundColor: colors.darkGray2,
          color: colors.white,
          textDecoration: overwriteUnderline ? 'underline' : 'none',
          textDecorationColor: colors.darkGray2,
        },
        '&:hover': {
          background: 'none',
          backgroundColor: colors.darkGray2,
          boxShadow: 'none',
          color: colors.white,
          textDecoration: overwriteUnderline ? 'underline' : 'none',
          textDecorationColor: colors.darkGray2,
        },
      }}
    >
      {text}
    </Button>
  );
};
