import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { PolicyCategory } from 'src/types/PolicyCategory';
import {
  CREATE_POLICY_CATEGORY,
  POLICY_CATEGORY_ACCORDION_DATA,
} from '../../pages/DataAdmin/PolicyCategoryManagement.graphql';
import { CreatePolicyCategoryVars } from '../../pages/DataAdmin/PolicyCategoryManagement.interface';
import styles from './PolicyModal.module.scss';
import { ModalTitle } from './ModalTitle/ModalTitle';

interface AddCategoryFormProps {
  handleClose: () => void;
}

export const AddCategoryForm: FC<AddCategoryFormProps> = ({ handleClose }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const [newPolicyCategoryTitle, setNewPolicyCategoryTitle] = useState<string>('');

  const [createPolicyCategory] = useMutation<PolicyCategory, CreatePolicyCategoryVars>(CREATE_POLICY_CATEGORY, {
    onCompleted(data) {
      handleClose();
      toast.success('New policy created', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error(
        'The policy category name you have entered already exists. Please enter a new policy category name.',
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
    },
    refetchQueries: [{ query: POLICY_CATEGORY_ACCORDION_DATA }],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPolicyCategoryTitle(e.target.value);
  };

  const createNewCategory = () => {
    createPolicyCategory({ variables: { title: newPolicyCategoryTitle } });
  };

  return (
    <>
      <ModalTitle title="Add New Policy Category" />
      <form className={styles.formSection}>
        <label className={styles.label} htmlFor="policyCategoryName">
          Policy Category Name:
        </label>
        <TextField
          id="policyCategoryName"
          value={newPolicyCategoryTitle}
          onChange={handleChange}
          variant="outlined"
          sx={{ width: '100%' }}
          InputProps={{
            style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
          }}
          InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
        />
        <div className={styles.modalBtnContainer}>
          <AdminButton text="Cancel" onClick={handleClose} defaultStyle={false} />
          <AdminButton text="Save" onClick={createNewCategory} defaultStyle={true} />
        </div>
      </form>
    </>
  );
};
