import { gql } from '@apollo/client';

export const STATE_SNAPSHOT_QUERY = gql`
  query ($stateId: State) {
    getStateSnapshotByStateId(stateId: $stateId) {
      stateId
      governor
      sessionLabel
      sessionDates
      linkLabel
      linkUrl
      houseMajority
      senateMajority
      commissionType
      commissionerPosition
      commissionLinkLabel
      commissionLinkUrl
    }
    getOfficeLinks(stateId: $stateId) {
      id
      stateId
      url
      label
    }
  }
`;

export const UPDATE_SNAPSHOT_MUTATION = gql`
  mutation ($stateId: State, $snapshot: StateSnapshotInput, $links: [OfficeLinkInput], $answers: [PolicyAnswerInput]) {
    updateStateSnapshot(snapshot: $snapshot) {
      stateId
      governor
      sessionLabel
      sessionDates
      linkLabel
      linkUrl
      houseMajority
      senateMajority
      commissionType
      commissionerPosition
      commissionLinkLabel
      commissionLinkUrl
    }
    updateOfficeLinkBatch(stateId: $stateId, links: $links) {
      id
      stateId
      label
      url
    }
    updatePolicyAnswers(answers: $answers) {
      id
      value
      url
      stateId
      question {
        id
      }
      createdDate
      lastModifiedDate
    }
  }
`;
