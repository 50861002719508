import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { stripTypenames } from 'src/utils/stripTypenames';
import {
  DELETE_POLICY_CATEGORY,
  POLICY_CATEGORY_ACCORDION_DATA,
} from '../../../pages/DataAdmin/PolicyCategoryManagement.graphql';
import { UpdatePolicyCategoryVars } from '../../../pages/DataAdmin/PolicyCategoryManagement.interface';
import styles from '../PolicyModal.module.scss';

interface Props {
  handleClose: () => void;
  categoryToDelete: PolicyCategory | undefined;
}

export const PolicyCategoryDelete = ({ handleClose, categoryToDelete }: Props) => {
  const [deleteCategory] = useMutation<UpdatePolicyCategoryVars>(DELETE_POLICY_CATEGORY, {
    onCompleted(data) {
      handleClose();
      toast.success('Policy category deleted', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error('This category cannot be deleted because it is associated with one or more policies.', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    refetchQueries: [{ query: POLICY_CATEGORY_ACCORDION_DATA }],
  });

  const deletePolicyCategory = () => {
    if (!categoryToDelete) return;
    const preppedObject = {
      id: categoryToDelete.id,
      title: categoryToDelete.title,
    };
    const payload = {
      policyCategory: stripTypenames(preppedObject),
    };
    deleteCategory({ variables: payload });
  };

  return (
    <div className={styles.divContainer}>
      <h3 className={styles.deleteModalTitle}>Delete {categoryToDelete?.title}</h3>
      <p className={styles.deleteModalDescription}>
        Are you sure you want to delete this policy category? This will delete all the policy data within this category.
        <b> You cannot undo this action.</b> Only categories without policies can be deleted.
      </p>
      <div className={styles.modalBtnContainer}>
        <AdminButton text="Cancel" onClick={handleClose} defaultStyle={false} />
        <AdminButton text="Delete" onClick={() => deletePolicyCategory()} defaultStyle={true} />
      </div>
    </div>
  );
};
