import { GeneralSelectOption } from '../components/GeneralSelect/GeneralSelect';
import { StateOptionEnum } from '../types/StateOption.enum';
import formatEnumToString from './formatEnumToString';

export const getStateOptions = (): GeneralSelectOption[] => {
  return Object.values(StateOptionEnum).map((stateEnum) => {
    let formattedState = formatEnumToString(stateEnum);
    if (stateEnum === StateOptionEnum.UNKNOWN) {
      formattedState = '';
    }
    return {
      value: stateEnum,
      label: formattedState,
    };
  });
};
