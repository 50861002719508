import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { colors } from 'src/theme/colors';
import { POLICY_PROFILE_DATA } from './PolicyProfile.graphql';
import { useQuery } from '@apollo/client';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { PolicyProfileSummary } from 'src/components/PolicyProfileSummary/PolicyProfileSummary';
import { StateGapAnalysis } from 'src/components/GapAnalysis/StateGapAnalysis/StateGapAnalysis';
import styles from './PolicyProfile.module.scss';
import { Alert } from '@mui/material';
import PolicyLinks from 'src/components/PolicyLinks/PolicyLinks';
import {updateTabTitle} from "../../utils/updateTabTitle";

export const PolicyProfile: FC = () => {
  const { policyId }: any = useParams();
  const navigate = useNavigate();
  const policyName = `${policyId.replace(/_/g, ' ')}`.replace(/&/g, '/');
  updateTabTitle(`${policyName} | Policy Profile`)

  const [questionsData, setQuestionsData] = useState<PolicyQuestion[]>([]);
  const [policyData, setPolicyData] = useState<PolicySubcategory>(new PolicySubcategory());
  const [policyDropDownValue, setPolicyDropDownValue] = useState<string>(policyName);
  const [policyBrief, setPolicyBrief] = useState<string>('');

  const handleChangePolicy = (e: SelectChangeEvent) => {
    setPolicyDropDownValue(e.target.value);
    if (e.target.value !== '') navigate(`/policy/${e.target.value.replace(/\s+/gi, '_').replace(/\//g, '&')}`);
  };

  const { data, loading, error } = useQuery(POLICY_PROFILE_DATA);

  useEffect(() => {
    if (data) {
      setQuestionsData(data.listPolicyQuestions.filter((q: PolicyQuestion) => q.subcategory.title === policyName));
      setPolicyData(data.listActivePolicySubcategories.find((p: PolicySubcategory) => p.title === policyName));
      setPolicyBrief(
        data.listActivePolicySubcategories.filter((p: PolicySubcategory) => p.title === policyName)[0].policyBrief,
      );
    }
  }, [data, policyName]);

  if (loading) return <>Loading</>;
  if (error || !data || data.listActivePolicySubcategories.length === 0) {
    return (
      <Alert severity="warning">
        Unable to load Policy information. Please try again or contact support if the issue persists.
      </Alert>
    );
  }

  return (
    <>
      <BreadCrumbs policyName={policyName} />
      <div className={styles.policyProfileSection}>
        <div className={styles.headingContainer}>
          <div>
            <div className={styles.headingText}>
              <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Policy Profile</Heading>
              <span className={styles.line}>|</span>
              <a className={styles.gapAnalysisLink} href="#gap-analysis">
                Jump to Gap Analysis
                <ArrowDropDownIcon
                  sx={{
                    position: 'absolute',
                    marginTop: '1px',
                    marginLeft: '225px',
                    color: colors.blue1,
                    fontSize: '50px',
                  }}
                />
              </a>
            </div>
          </div>
          <div className={styles.selectContainer}>
            <GeneralSelect
              id="policySelector"
              labelText="Choose another policy for more details"
              data={data.listActivePolicySubcategories
                .map((subcategory: PolicySubcategory) => subcategory.title)
                .sort()}
              value={policyDropDownValue}
              onChange={handleChangePolicy}
              defaultStyle={true}
              width="100%"
            />
          </div>
        </div>
        <PolicyProfileSummary policy={policyData} questionsData={questionsData} policyBrief={policyBrief} />
        <StateGapAnalysis
          policyName={policyName}
          policy={data.listActivePolicySubcategories.find(
            (subcategory: PolicySubcategory) => subcategory.title === policyName,
          )}
        />
        <PolicyLinks policyId={policyData.id} />
      </div>
    </>
  );
};
