import PageTitles from 'src/types/PageTitles.enum';

const breadcrumbs = [
  { pageTitle: PageTitles.HOME, path: '/', order: 0 },
  { pageTitle: PageTitles.ABOUT, path: '/about', order: 1 },
  { pageTitle: PageTitles.STYLE, path: '/styleguide', order: 1 },
  { pageTitle: PageTitles.STATE, path: '/state', order: 1 },
  { pageTitle: PageTitles.POLICY, path: '/policy', order: 1 },
  { pageTitle: PageTitles.FAQS, path: '/faqs', order: 1 },
  { pageTitle: PageTitles.CONTACT, path: '/contact', order: 1 },
];

export const policyProfileBreadcrumbs = [
  { pageTitle: PageTitles.HOME, path: '/', order: 0 },
  { pageTitle: PageTitles.POLICY_PROFILE, path: '/policy/:policyId', order: 1 },
  { pageTitle: PageTitles.STATE_COMPARISON, path: '/policy/:policyId/compareStateTool', order: 2 },
];

export const policyDescBreadcrumbs = [
  { pageTitle: PageTitles.HOME, path: '/', order: 0 },
  { pageTitle: PageTitles.STATE, path: '/state/:stateId', order: 1 },
  { pageTitle: PageTitles.POLICY_DESC, path: '/state/:stateId/:policyId', order: 2 },
];

export default breadcrumbs;
