import { InMemoryCache, createHttpLink, ApolloClient } from '@apollo/client';
import contentfulUri from '../contentful.js';

const httpLink = createHttpLink({
    uri: contentfulUri,
    credentials: 'same-origin',
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
    },
});

const contentfulClient = new ApolloClient({
    cache: new InMemoryCache(),
    fetchOptions: { fetch },
    link: httpLink,
});

export default contentfulClient;
