import { gql } from '@apollo/client';

export const POLICY_GAP_ANALYSIS_DATA = gql`
  query ($stateId: State) {
    listPolicyQuestions {
      id
      subcategory {
        id
        title
        tier
      }
    }
    getPolicyAnswersByStateId(stateId: $stateId) {
      id
      value
      url
      question {
        id
        text
        subcategory {
          id
          title
          description
          category {
            id
            title
          }
          tier
        }
      }
    }
    listActivePolicySubcategories {
      id
      title
      tier
    }
  }
`;

export const DESCRIPTION_QUERY = gql`
  query {
    spotGapAnalysisCollection(limit: 1) {
      items {
        content {
          json
        }
      }
    }
  }
`;
