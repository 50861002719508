import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import {buttonTheme} from "../../theme/theme";
import styles from './HeroButton.module.scss';

export interface ButtonProps {
  onClick: () => void;
  disabled?: boolean;
  text: string;
  emphasisText: string;
}

const HeroButton: FC<ButtonProps> = ({ text, onClick, disabled, emphasisText }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="contained"
        onClick={onClick}
        disableElevation
        color="secondary"
        disabled={disabled ? disabled : false}
        sx={{ padding: '10px 50px', margin: '5px 10px', borderRadius: '0px', fontWeight: '400', fontSize: '17px' }}
      >
        {text} <strong className={styles.stronk}>&nbsp;{emphasisText}</strong>
      </Button>
    </ThemeProvider>
  );
};

export default HeroButton;
