import { FC, useContext, useState } from 'react';
import styles from './AdminLoginModal.module.scss';
import { Login } from 'src/services/AuthService';
import AdminLoginForm from './AdminLoginForm/AdminLoginForm';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { UserDataContext } from 'src/hooks/useUserDataContext';
import { GeneralModal } from '../GeneralModal/GeneralModal';
import { toast } from 'react-toastify';
import { validateEmail } from 'src/utils/validateEmail';
import { PasswordRequestForm } from './PasswordRequestForm/PasswordRequestForm';

interface AdminLoginModalProps {
  setModalOpen: React.Dispatch<boolean>;
  modalOpen: boolean;
}
const AdminLoginModal: FC<AdminLoginModalProps> = ({ modalOpen, setModalOpen }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const { setAuthenticated, setUserData } = useContext(UserDataContext);
  const [showPwRequestForm, setShowPwRequestForm] = useState(false);
  const [adminLoginForm, setAdminLoginForm] = useState({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState({
    emailError: { error: false, message: '' },
    passwordError: { error: false, message: '' },
  });
  const handleClose = () => {
    setModalOpen(false);
    setAdminLoginForm({ email: '', password: '' });
    setShowPwRequestForm(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (formError) {
      setFormError({
        emailError: { error: false, message: '' },
        passwordError: { error: false, message: '' },
      });
    }
    setAdminLoginForm({ ...adminLoginForm, [id]: value });
  };

  const handleLogin = async () => {
    const isEmailValid = validateEmail(adminLoginForm.email);
    if (!isEmailValid) {
      return setFormError({ ...formError, emailError: { error: true, message: 'Email not vaild' } });
    }
    try {
      const res = await Login(adminLoginForm, setAuthenticated, setUserData);
      if (res) {
        handleClose();
        toast.success('You have successfully logged in.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (e) {
      setFormError({
        emailError: { error: true, message: 'Incorrect username or password. Please try again.' },
        passwordError: { error: true, message: 'Incorrect username or password. Please try again.' },
      });
    }
  };

  const style = {
    width: screenSizeIsAtLeast[Breakpoint.BASE] ? '450px' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.BASE] ? '30px' : '22px',
  };

  return (
    <GeneralModal modalOpen={modalOpen} setModalOpen={handleClose} styling={style}>
      <div className={styles.modalContent}>
        {showPwRequestForm ? (
          <PasswordRequestForm />
        ) : (
          <AdminLoginForm
            handleChange={handleChange}
            handleLogin={handleLogin}
            loginForm={adminLoginForm}
            formError={formError}
            setShowPwRequestForm={setShowPwRequestForm}
          />
        )}
      </div>
    </GeneralModal>
  );
};

export default AdminLoginModal;
