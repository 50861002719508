import React, { FC } from 'react';
import { GeneralModal } from 'src/components/GeneralModal/GeneralModal';
import { PolicyCategoryAccordionInterface} from '../../pages/DataAdmin/PolicyCategoryManagement.interface';
import { AddCategoryForm } from './AddCategoryForm';
import { ArchiveSubcategoryForm } from './ArchiveSubcategoryForm';
import { ReactivateSubcategoryForm } from './ReactivateSubcategoryForm';
import { PolicyCategory} from "../../types/PolicyCategory";
import { PolicyCategoryEditName} from './PolicyCategoryEditName/PolicyCategoryEditName';
import { PolicyCategoryDelete} from './PolicyCategoryDelete/PolicyCategoryDelete';

interface PolicyModalProps {
  data?: PolicyCategoryAccordionInterface;
  setModalOpen: React.Dispatch<boolean>;
  modalOpen: boolean;
  openAddCategory?: boolean;
  openEditCategory?: boolean;
  openDeleteCategory?: boolean;
  openArchive?: boolean;
  openReactivate?: boolean;
  targetCategory?: PolicyCategory | undefined;
  subcategoryId: number;
}

export const PolicyModal: FC<PolicyModalProps> = ({
  data,
  setModalOpen,
  modalOpen,
  openAddCategory,
  openEditCategory,
  openDeleteCategory,
  openArchive,
  openReactivate,
  targetCategory,
  subcategoryId,
}) => {
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <GeneralModal setModalOpen={setModalOpen} modalOpen={modalOpen}>
      <div>
        {openAddCategory && <AddCategoryForm handleClose={handleClose} />}
        {openEditCategory && <PolicyCategoryEditName handleClose={handleClose} categoryToEdit={targetCategory} />}
        {openDeleteCategory && <PolicyCategoryDelete handleClose={handleClose} categoryToDelete={targetCategory} />}
        {openArchive && data && (
          <ArchiveSubcategoryForm data={data} handleClose={handleClose} subcategoryId={subcategoryId} />
        )}
        {openReactivate && data && (
          <ReactivateSubcategoryForm data={data} handleClose={handleClose} subcategoryId={subcategoryId} />
        )}
      </div>
    </GeneralModal>
  );
};
