import React, { FC } from 'react';
import styles from '../PolicyModal.module.scss';
import Heading from '../../Heading/Heading';
import HeadingStyle from '../../Heading/HeadingStyle.enum';

interface Props {
  title: string;
}
export const ModalTitle: FC<Props> = ({ title }) => {
  return (
    <div className={styles.heading}>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{title}</Heading>
    </div>
  );
};
