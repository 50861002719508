import { gql } from '@apollo/client';

export const CREATE_POLICY_CATEGORY = gql`
  mutation ($title: String!) {
    createPolicyCategory(title: $title) {
      id
      title
      createdDate
      lastModifiedDate
      lastModifiedBy
    }
  }
`;

export const UPDATE_POLICY_CATEGORY = gql`
  mutation ($policyCategory: PolicyCategoryInput) {
    updatePolicyCategory(policyCategory: $policyCategory) {
      id
      title
      createdDate
      lastModifiedDate
      lastModifiedBy
    }
  }
`;

export const DELETE_POLICY_CATEGORY = gql`
  mutation ($policyCategory: PolicyCategoryInput) {
    deletePolicyCategory(policyCategory: $policyCategory)
  }
`;

export const POLICY_CATEGORY_ACCORDION_DATA = gql`
  query {
    listPolicyCategories {
      id
      title
      lastModifiedDate
      lastModifiedBy
      createdDate
    }
    listPolicySubcategories {
      id
      title
      category {
        id
        title
      }
      description
      policyBrief
      tier
      active
    }
  }
`;

export const UPDATE_SUBCATEGORY_MUTATION = gql`
  mutation ($policySubcategory: PolicySubcategoryInput) {
    updatePolicySubcategory(policySubcategory: $policySubcategory) {
      id
      title
      description
      policyBrief
      category {
        id
        title
      }
      tier
      active
    }
  }
`;


