import React, { FC } from 'react';
import { colors } from 'src/theme/colors';
import styles from './GapAnalysisBar.module.scss';

interface GapAnalysisBarProps {
    yes: number;
    total: number;
    height: string;
    width: string;
    fontSize: string;
    hoverAndFocus?: boolean;
    barName?: string | null;
}

export const GapAnalysisBar: FC<GapAnalysisBarProps> = ({ yes, total, height, width, fontSize, hoverAndFocus, barName }) => {
    const barWidth = (yes / total) * 100;

    return (
        <div
            className={`${styles.bar} ${(hoverAndFocus && barName) && styles.barHoverAndFocus}`}
            style={{
                justifyContent: ((hoverAndFocus && barName) ? 'space-between' : 'flex-start'),
                height: height,
                width: width,
            }}
        >
            {yes > 0 ?
                <div className={`${styles.yes} ${(hoverAndFocus && barName) && styles.yesHoverAndFocus}`}
                    style={{
                        justifyContent: yes === 1 ? 'center' : ((hoverAndFocus && barName && barWidth > 50) ? 'space-between' : 'flex-end'),
                        height: height,
                        width: `${barWidth}%`,
                        paddingRight: yes === 1 ? 0 : '1rem',
                        fontSize: fontSize
                    }}
                >
                    {(hoverAndFocus && barName && barWidth > 50) &&
                        <div
                            className={styles.circleContainer}
                            style={{
                                display: yes > 0 ? 'flex' : 'none',
                                paddingLeft: '1rem',
                            }}
                        >
                            <div className={styles.circle} style={{ background: colors.white }} />
                        </div>
                    }
                    {yes} of {total}
                </div>
                :
                <div className={styles.zeroYes} style={{ fontSize: fontSize, }}>
                    {yes} of {total}
                    {(hoverAndFocus && barName) &&
                        <div className={styles.circleContainer} style={{ display: 'flex', paddingRight: '1rem' }}>
                            <div className={styles.circle} style={{ background: colors.black }} />
                        </div>
                    }
                </div>
            }
            {(hoverAndFocus && barName && barWidth <= 50) &&
                <div className={styles.circleContainer} style={{ display: yes > 0 ? 'flex' : 'none', paddingRight: '1rem' }}>
                    <div className={styles.circle} style={{ background: colors.black }} />
                </div>
            }
        </div>
    );
};