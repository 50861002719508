import { FC } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel } from '@mui/material';
import { colors } from 'src/theme/colors';
import Breakpoint from 'src/types/BreakPoint.enum';
import { useScreenSize } from 'src/hooks/useScreenSize';

interface InputProps {
  id: string;
  name?: string;
  placeholder?: string;
  value: string;
  label?: string;
  error?: boolean;
  type?: string;
  helperText?: string;
  disabled?: boolean;
  autoComplete?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  handleChange: (e: any) => void;
  endAdornment?: React.ReactElement;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  autoFocus?: boolean;
}

const Input: FC<InputProps> = ({
  label,
  error,
  helperText,
  handleChange,
  type,
  value,
  placeholder,
  id,
  disabled,
  autoComplete,
  fullWidth,
  name,
  endAdornment,
  onKeyDown,
  readOnly,
  autoFocus,
  multiline,
  rows,
}) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const inputPadding = multiline ? '14px' : screenSizeIsAtLeast[Breakpoint.LG] ? '5px' : '0px';
  return (
    <>
      {label ? (
        <InputLabel
          htmlFor={id}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            fontFamily: 'Lato',
            marginBottom: '6px',
            fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '.9rem' : '.75rem',
          }}
        >
          {label}
        </InputLabel>
      ) : null}
      <FormControl id={id} fullWidth={fullWidth}>
        <TextField
          autoComplete={autoComplete}
          id={id}
          name={name}
          onChange={(e) => handleChange(e)}
          onKeyDown={onKeyDown}
          type={type}
          // TODO: It looks like label isn't actually used here?
          label={label ? '' : placeholder}
          placeholder={placeholder}
          value={value}
          variant="outlined"
          helperText={helperText}
          error={error}
          disabled={disabled}
          fullWidth={fullWidth}
          multiline={multiline}
          rows={rows}
          autoFocus={autoFocus}
          InputProps={{
            style: {
              fontFamily: 'Lato',
              paddingLeft: inputPadding,
              fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '.9rem' : '.8rem',
              height: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : 'fit-content',
              backgroundColor: colors.white,
            },
            endAdornment: endAdornment,
            readOnly: readOnly,
          }}
          InputLabelProps={{
            style: {
              fontFamily: 'Lato',
              paddingLeft: screenSizeIsAtLeast[Breakpoint.LG] ? '5px' : '0px',
              fontSize: screenSizeIsAtLeast[Breakpoint.LG] ? '.9rem' : '.8rem',
              height: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : '45px',
            },
          }}
          FormHelperTextProps={{
            style: {
              fontFamily: 'Lato',
              fontSize: 13,
              marginLeft: '5px',
              marginTop: '5px',
            },
          }}
        />
      </FormControl>
    </>
  );
};

export default Input;
