import { gql } from '@apollo/client';

export const DISCLAIMER_QUERY = gql`
query {
  disclaimerSectionCollection(limit:1) {
    items {
      header
      description
    }
  }
}
`;