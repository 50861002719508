import React from 'react';
import styles from './PolicyQuestionsLegend.module.scss';

interface Props {
  lastUpdatedDate: Date;
}

export const PolicyQuestionsLegend = ({ lastUpdatedDate }: Props) => {
  return (
    <div className={styles.legend}>
      <span className={styles.legendStatus}>
        <span>
          <i>Y=Yes</i>
          <span className={styles.line}>|</span>
        </span>
        <span>
          <i>N=No</i>
          <span className={styles.line}>|</span>
        </span>
        <span>
          <i>N/A=Not Applicable</i>
          <span className={styles.line}>|</span>
        </span>
      </span>
      <span className={styles.legendLastUpdated}>
        <i>Policy Component information last updated {lastUpdatedDate.toDateString()}</i>
      </span>
    </div>
  );
};
