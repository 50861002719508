import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SocialMediaLinks.module.scss';
import GeneralLinkBody from '../../GeneralLinkBody/GeneralLinkBody';
import { icon, IconName } from '@fortawesome/fontawesome-svg-core';

interface SocialMediaLinksProps {
    linkInfo: {
        socialMediaIconLabel: string;
        iconName: IconName;
        socialMediaLink: string;
    }[];
}

const SocialMediaLinks: FC<SocialMediaLinksProps> = ({ linkInfo }) => {
    const socialLinks = linkInfo.map((linkItem, index) => {
        const socialIcon = icon({ prefix: 'fab', iconName: linkItem.iconName });
        return (
            <GeneralLinkBody
                href={linkItem.socialMediaLink}
                key={index}
                aria-label={`Link to CNEE ${linkItem.socialMediaIconLabel}`}
                className={styles.iconLink}
            >
                <FontAwesomeIcon icon={socialIcon} className={styles.icon} title={linkItem.socialMediaIconLabel} />
            </GeneralLinkBody>
        );
    });
    return <div className={styles.socialLinkIcons}>{socialLinks}</div>;
};

export default SocialMediaLinks;
