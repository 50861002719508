import { useQuery } from '@apollo/client';
import { plainToInstance } from 'class-transformer';
import { FC, useEffect, useState } from 'react';
import { getState } from 'src/types/State.enum';
import { STATE_SNAPSHOT_QUERY } from './StateSnapshot.graphql';
import styles from './StateSnapshot.module.scss';
import { Snapshot } from './types/Snapshot.class';
import GeneralInfoCard from '../GeneralInfoCard/GeneralInfoCard';
import {politicalPartyText} from "../../types/PoliticalParty.enum";

interface StateSnapshotProps {
  stateName: string;
}

export const StateSnapshot: FC<StateSnapshotProps> = ({ stateName }) => {
  const [snapshot, setSnapshot] = useState<Snapshot>(new Snapshot());

  const { data } = useQuery(STATE_SNAPSHOT_QUERY, {
    variables: { stateId: getState(stateName) },
  });

  useEffect(() => {
    if (data) {
      const snapshotInstance = plainToInstance(Snapshot, {
        ...data.getStateSnapshotByStateId,
        officeLinks: data.getOfficeLinks,
      });
      setSnapshot(snapshotInstance);
    }
  }, [data]);

  const officeLinks = snapshot.officeLinks.map((link: any, index: Number) => {
    const linkWithProtocol: string = link.url.includes('http') ? link.url : 'http://' + link.url;
    return (
      <p key={index.toString()}>
        <a href={linkWithProtocol} className={styles.links} target="_blank" rel="noopener noreferrer">
          {link.label}
        </a>
      </p>
    );
  });

  return (
    <GeneralInfoCard title={'State Snapshot'}>
      <div className={styles.contentBlock}>
        <p>
          Governor: <span className={styles.emphasized}>{snapshot.separateGovernor(0)}</span> (
          <span className={styles.emphasized}>{snapshot.separateGovernor(1).toUpperCase()}</span>)
        </p>
        <p>
          {snapshot.sessionLabel} <span className={styles.emphasized}>{snapshot.sessionDates}</span>
        </p>
        <p>
          House Majority Party: (<span className={styles.emphasized}>{politicalPartyText[snapshot.houseMajority]}</span>)
          Senate Majority Party: (<span className={styles.emphasized}>{politicalPartyText[snapshot.senateMajority]}</span>)
        </p>
        <p>
          <a href={snapshot.linkUrl} className={styles.links} target="_blank" rel="noopener noreferrer">
            {snapshot.linkLabel}
          </a>
        </p>
      </div>
      <div className={styles.contentBlock}>
        <p>{snapshot.commissionType}</p>
        <p>
          Commissioners Appointed or Elected: <span className={styles.emphasized}>{snapshot.commissionerPosition}</span>
        </p>
        <p>
          <a href={snapshot.commissionLinkUrl} className={styles.links} target="_blank" rel="noopener noreferrer">
            {snapshot.commissionLinkLabel}
          </a>
        </p>
      </div>
      <div className={styles.contentBlock}>
        <p>Additional Links</p>
        {officeLinks || 'No additional links'}
      </div>
    </GeneralInfoCard>
  );
};
