import { gql } from '@apollo/client';

export const RECENT_LEGISLATION_QUERY = gql`
    query($input: EprcBillsInput) {
        bills(input: $input) {
            billId,
            osBillId,
            title,
            summary,
            number
        }
    }
`;