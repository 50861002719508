import { FC, useContext } from 'react';
import styles from './SiteFooter.module.scss';
import SiteLogo from '../../assets/logos/cnee-text-logo.png';
import footerTransition from '../../assets/svg/footer-transition.svg';
import { useQuery } from '@apollo/client';
import { FOOTER_QUERY } from './SiteFooter.graphql';
import LearnMoreLinks from './LearnMoreLinks/LearnMoreLinks';
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks';
import GeneralLinkBody from '../GeneralLinkBody/GeneralLinkBody';
import contentfulClient from 'src/common/apolloClient';
import AdminLoginModal from '../AdminLoginModal/AdminLoginModal';
import { UserDataContext } from 'src/hooks/useUserDataContext';
import { Logout } from 'src/services/AuthService';
import { toast } from 'react-toastify';

const SiteFooter: FC = () => {
  const { data } = useQuery(FOOTER_QUERY, { client: contentfulClient });
  // eslint-disable-next-line
  const { authenticated, setAuthenticated, setUserData, authModalOpen, setAuthModalOpen } = useContext(UserDataContext);
  let learnMoreLinks = [];
  let socialMediaLinks = [];
  let address, copyrightYear, copyrightText, copyrightLink;

  if (data) {
    let response = data?.footerTextCollection?.items[0];
    address = response.address;
    copyrightYear = response.copyrightYear;
    copyrightText = response.copyrightText;
    copyrightLink = response.copyrightLink;
    learnMoreLinks = response.learnMoreLinksCollection?.items;
    socialMediaLinks = response.socialMediaLinksCollection?.items;
  }

  const handleLogOut = () => {
    Logout(setAuthenticated, setUserData);
    toast.success('Logged out successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <section className={styles.footerSection}>
        <img src={footerTransition} alt="" className={styles.footerTransition} />
        <section className={styles.footerContentSection}>
          <div className={styles.siteProviderInfo}>
            <img src={SiteLogo} alt="CNEE logo"></img>
            {data && (
              <div className={styles.providerInfoText}>
                <span>
                  &copy;{copyrightYear}{' '}
                  <a className={styles.copyrightLink} href={copyrightLink}>
                    {copyrightText}
                  </a>
                </span>
                <span>{address}</span>
                <p><small><a href={'https://www.govwebworks.com/'} target={'_blank'} rel={'noreferrer'}>Developed by GovWebworks</a></small></p>
              </div>
            )}
          </div>
          <section className={styles.learnMoreSection}>
            <span className={styles.listHeading}>Learn More</span>
            <LearnMoreLinks links={learnMoreLinks} />
            {authenticated ? (
              <GeneralLinkBody onClick={handleLogOut}>Log Out</GeneralLinkBody>
            ) : (
              <GeneralLinkBody onClick={() => setAuthModalOpen(true)}>Admin Login</GeneralLinkBody>
            )}
          </section>
          <section className={styles.socialMediaSection}>
            <span className={styles.listHeading}>Stay in touch with us!</span>
            <SocialMediaLinks linkInfo={socialMediaLinks} />
          </section>
        </section>
      </section>
      <AdminLoginModal modalOpen={authModalOpen} setModalOpen={setAuthModalOpen} />
    </>
  );
};

export default SiteFooter;
