import React, { FC, useState } from 'react';
import { MainAccordion, AccordionSummary } from './PolicyProfileAccordionTheme';
import { AccordionDetails } from '@mui/material';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import styles from './PolicyProfileSummary.module.scss';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { PolicyBriefLink } from './PolicyBriefLink/PolicyBriefLink';

interface PolicyProfileSummaryProps {
  policy?: PolicySubcategory;
  questionsData?: PolicyQuestion[];
  policyBrief?: string;
}

export const PolicyProfileSummary: FC<PolicyProfileSummaryProps> = ({ policy, questionsData, policyBrief }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.policyProfileSummarySection}>
      {policy && (
        <>
          <div className={styles.contentTitle}>{policy.title}</div>
          <div className={styles.contentBlock}>
            {policy?.description?.length ? policy?.description : 'No policy description.'}
            {policyBrief && (
              <>
                | <PolicyBriefLink policyBrief={policyBrief} policyTitle={policy.title} />{' '}
              </>
            )}
          </div>
          {questionsData && (
            <MainAccordion className={styles.mainAccordion} expanded={expanded} onChange={handleChange}>
              <AccordionSummary>
                <div className={styles.summaryContent}>{expanded ? 'Hide' : 'Show'} policy component questions</div>
              </AccordionSummary>
              <AccordionDetails className={styles.mainAccordionDetails}>
                {questionsData
                  .filter((q) => q.text)
                  .map((question: PolicyQuestion, i: number) => {
                    return (
                      <div key={i} className={styles.question}>
                        {i + 1}. {question.text}
                      </div>
                    );
                  })}
              </AccordionDetails>
            </MainAccordion>
          )}
        </>
      )}
    </div>
  );
};
