import React, { useEffect, useState } from 'react';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import PageTitles from 'src/types/PageTitles.enum';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import styles from './Home.module.scss';
import { MapSection } from './MapSection/MapSection';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { States } from 'src/types/State.enum';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { ActivateAccount, GetUserByActivationToken, GetUserByByPasswordResetToken } from 'src/services/AuthService';
import { toast } from 'react-toastify';
import { PasswordResetModal } from 'src/components/AdminLoginModal/PasswordResetModal';
import { DownloadGapAnalysisAndPrintPage } from '../../components/DownloadGapAnalysisAndPrintPage/DownloadGapAnalysisAndPrintPage';

export default function Home(): React.ReactElement {
  window.scrollTo(0, 0);
  updateTabTitle(PageTitles.HOME);
  const navigate = useNavigate();

  const { activationId, resetToken }: any = useParams();
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [activatedUser, setActivatedUser] = useState({ active: false });
  const [userForReset, setUserForReset] = useState({ email: '', passwordResetToken: resetToken });

  useEffect(() => {
    GetUserByActivationToken(activationId, setActivatedUser);
  }, [activationId]);

  useEffect(() => {
    if (resetToken) {
      GetUserByByPasswordResetToken(resetToken, userForReset, setUserForReset);
    }
    // eslint-disable-next-line
  }, [resetToken]);

  useEffect(() => {
    if (activatedUser.active) {
      const activateAccount = async () => {
        try {
          await ActivateAccount(activatedUser);
          toast.success('Account successfully activated. Please log in to access myTracker.', {
            position: toast.POSITION.TOP_CENTER,
          });
        } catch (e) {
          toast.error('Account activation failed', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      };
      activateAccount();
    }
    if (userForReset.email.length) {
      setResetModalOpen(true);
    }
  }, [activatedUser, userForReset, setResetModalOpen]);

  const states: string[] = States;
  const stateOptions = ['Select a State', ...states];
  const dropDownValues = {
    usState: {
      name: 'Select a State',
      abbreviation: '',
    },
  };
  const [dropDownFilter, setDropDownFilter] = useState(dropDownValues);

  const handleChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    if (value === 'Select a State') {
      setDropDownFilter(dropDownValues);
      return;
    }
    const stateObj = stateOptions.find((item) => item.includes(value));
    setDropDownFilter({ ...dropDownFilter, [name]: stateObj });
    if (stateObj) {
      navigate(`/state/${e.target.value.replace(/\s+/g, '_')}`);
    }
  };
  return (
    <>
      <BreadCrumbs />
      <div className={styles.home}>
        <div className={styles.headingContainer}>
          <div className={styles.headingText}>
            <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Clean Energy Policy by State</Heading>
            <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE}>
              Select state on the map below or use the drop-down menu.
            </Heading>
          </div>
          <div className={styles.selectContainer}>
            <GeneralSelect
              labelText={'Choose a State for more details'}
              id={'stateSelect'}
              data={stateOptions}
              value={stateOptions[0]}
              onChange={handleChange}
              defaultStyle={false}
            />
            <DownloadGapAnalysisAndPrintPage />
          </div>
        </div>
        <MapSection />
        <PasswordResetModal modalOpen={resetModalOpen} setModalOpen={setResetModalOpen} userForReset={userForReset} />
      </div>
    </>
  );
}
