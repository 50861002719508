import React, { FC, useEffect, useState } from 'react';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { PolicyCategoryAccordion } from './PolicyCategoryAccordion/PolicyCategoryAccordion';
import { Alert, CircularProgress } from '@mui/material';
import { POLICY_CATEGORY_ACCORDION_DATA } from './PolicyCategoryManagement.graphql';
import { useQuery } from '@apollo/client';
import { PolicyCategoryAccordionClass, PolicyCategoryAccordionInterface } from './PolicyCategoryManagement.interface';
import { PolicyModal } from '../../components/PolicyModal/PolicyModal';
import styles from './PolicyCategoryManagement.module.scss';

export const PolicyCategoryManagement: FC = () => {
  const [policyCategoryAccordionData, setPolicyCategoryAccordionData] = useState<PolicyCategoryAccordionInterface>(
    new PolicyCategoryAccordionClass(),
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [openReactivate, setOpenReactivate] = useState(false);
  const [subcategoryId, setSubcategoryId] = useState(0);

  const { data, loading, error } = useQuery<PolicyCategoryAccordionInterface>(POLICY_CATEGORY_ACCORDION_DATA);

  useEffect(() => {
    if (data) {
      setPolicyCategoryAccordionData(data);
    }
  }, [data]);

  const openAddNewCategoryModal = () => {
    setModalOpen(true);
    setOpenAddCategory(true);
    setOpenReactivate(false);
    setOpenArchive(false);
  };

  const openArchiveModal = () => {
    setModalOpen(true);
    setOpenArchive(true);
    setOpenAddCategory(false);
    setOpenReactivate(false);
  };

  const openReactivateModal = () => {
    setModalOpen(true);
    setOpenReactivate(true);
    setOpenAddCategory(false);
    setOpenArchive(false);
  };

  if (loading) return <CircularProgress />;
  if (error || data?.listPolicyCategories?.length === 0) {
    return (
      <Alert variant="outlined" severity="warning">
        Unable to load State Policy information. Please try again and contact support if the issue persists.
      </Alert>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>SPOT Policy Data Administration</Heading>
      <div className={styles.buttonsContent}>
        <div className={styles.addCategoryBtnContainer}>
          <AdminButton text="+ Add New Policy Category" onClick={openAddNewCategoryModal} defaultStyle={true} />
        </div>
      </div>
      <PolicyModal
        data={policyCategoryAccordionData}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        openAddCategory={openAddCategory}
        openArchive={openArchive}
        openReactivate={openReactivate}
        subcategoryId={subcategoryId}
      />
      <PolicyCategoryAccordion
        data={policyCategoryAccordionData}
        openArchiveModal={openArchiveModal}
        openReactivateModal={openReactivateModal}
        setSubcategoryId={setSubcategoryId}
      />
    </div>
  );
};
