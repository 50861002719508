import React, { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import Input from 'src/components/Input/Input';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import { SetPasswordResetToken } from 'src/services/AuthService';
import { validateEmail } from 'src/utils/validateEmail';
import styles from './PasswordRequestForm.module.scss';

export const PasswordRequestForm: FC = () => {
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState({ error: false, message: '' });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formError) {
      setFormError({
        error: false,
        message: '',
      });
    }
    setEmail(e.target.value);
  };

  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  const sendPasswordResetEmail = () => {
    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      return setFormError({ error: true, message: 'Email not vaild' });
    }
    SetPasswordResetToken(email);
    toast('Password request successfully submitted.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
  };

  return (
    <div className={styles.contentContainer}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          sendPasswordResetEmail();
        }}
      >
        <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Reset password</Heading>
        <p>
          We will send you an email with a link to reset your password if we find an account with the email address you
          entered.
        </p>
        <div className={styles.inputContainer}>
          <Input
            label="Email"
            error={formError.error}
            helperText={formError.message}
            handleChange={handleChange}
            value={email}
            id="email"
            placeholder={'Email Address'}
            autoFocus={true}
            fullWidth={true}
          />
        </div>
        <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
        <div className={styles.buttonContainer}>
          <PrimaryButton disabled={isDisabled} onClick={sendPasswordResetEmail} text="Send password reset link" />
        </div>
      </form>
    </div>
  );
};
