export const colors = {
  black: '#000000',
  blackTransparent: 'rgba(0, 0, 0, 0.85)',
  white: '#ffffff',
  darkGray1: '#333333',
  darkGray2: '#666666',
  darkGray3: '#4d4d4d',
  darkGray4: '#4A4A4A',
  medGray1: '#999999',
  lightGray1: '#f4f4f4',
  lightGray2: '#e4e4e4',
  lightGray3: '#e8e8e8',
  lightGray4: '#cccccc',
  lightGray5: '#f6f6f6',
  lightGray6: '#fcfafa',
  lightGray7: '#f3f3f3',
  lightGray8: '#d8d8d8',
  lightGray9: '#dddddd',
  lightGray10: '#fafafa',
  blue1: '#1a78be',
  blue2: '#065694',
  blue3: '#0080B2',
  blue4: '#0f5874',
  blue5: '#00678f',
  blue6: '#1465A1',
  blue7: '#007eab',
  green1: '#09812f',
  green2: '#61a739',
  green3: '#487D26',
  green4: '#5c8427',
  green5: '#7D9A59',
  green6: '8cc63f',
  green7: '105225',
  green8: '356200',
  green9: '267b2b',
  $reen10: '5ea538',
  limeGreen: '#8CC63F',
  orange1: '#ffa100',
  orange2: '#E6901A',
  orange3: '#E6901A',
  stateGradient1: '#C4E1B0',
  stateGradient2: '#7DCD41',
  stateGradient3: '#64AF2C',
  stateGradient4: '#4F8B23',
  stateGradient5: '#3C691B',
  yellow1: '#ffcb70',
  yellow2: '#ebd36a24',
  yellow3: '#FECB08',
  yellow4: '#ebd36a',
  yellow5: '#ebd36a',
  yellow6: '#cc983c',
  yellow7: '#fecb08',
  yellow8: '#ffe1a7',
  gold: '#D8A102',
  focus: 'rgb(239, 58, 88)',
};

export const gradients = {
  medBlue: 'linear-gradient(#0b9ad2, #0f5874)',
  medGreen: 'linear-gradient(#61A739 30%, #076324)',
  darkGreen: 'linear-gradient(#076324 25%, #000000)',
  darkBlue: 'linear-gradient(#0F5874 25%, #000000)',
  lightGray: `linear-gradient(${colors.lightGray3}, ${colors.lightGray4})`,
  medGray: `linear-gradient(${colors.lightGray4}, ${colors.darkGray2})`,
};

export const policyChartColors = [
  "#880808",
  "#6FAA64",
  "#A6844F",
  "#051063",
  "#A6752A",
  "#035255",
  "#085503",
  "#340563",
  "#D07B31",
  "#A4BB65",
];

export const statusChartColors = [
  "#D1C076",
  "#658808",
  "#801313",
  "#BA5B09",
  "#5E103E",
  "#BE583E"
];
