import { gql } from '@apollo/client';

export const FAQS_QUERY = gql`
  query {
    faqsCollection {
      items {
        order
        questionTitle
        onHomePage
        answerBody {
          json
        }
        categoryCollection {
          items {
            categoryName
          }
        }
        sys {
          id
          firstPublishedAt
        }
      }
    }
  }
`;