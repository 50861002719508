import { gql } from '@apollo/client';

export const POLICY_DATA_ADMIN_DATA = gql`
  query {
    listPolicyQuestionsAdmin {
      id
      text
      subcategory {
        id
        title
        description
        policyBrief
        category {
          id
          title
        }
        tier
      }
    }
    listActivePolicySubcategories {
      id
      title
      category {
        id
        title
      }
      description
      policyBrief
      tier
    }
  }
`;

export const MEDIA_LINKS_QUERY = gql`
  query ($filter: MediaLinkFilter) {
    mediaLinks(filter: $filter) {
      id
      policySubcategoryId
      url
      label
      state
    }
  }
`;

export const UPDATE_SUBCATEGORY_MUTATION = gql`
  mutation ($policySubcategory: PolicySubcategoryInput, $policyQuestions: [PolicyQuestionInput]) {
    updatePolicySubcategory(policySubcategory: $policySubcategory) {
      id
      title
      description
      policyBrief
      category {
        id
        title
      }
      tier
      active
    }
    updatePolicyQuestion(policyQuestions: $policyQuestions) {
      id
      text
      subcategory {
        id
        title
        description
        policyBrief
        category {
          id
          title
        }
        tier
      }
    }
  }
`;

export const UPDATE_MEDIA_LINKS_MUTATION = gql`
  mutation ($policySubcategoryId: Int, $links: [MediaLinkInput]) {
    updateMediaLinkBatch(policySubcategoryId: $policySubcategoryId, links: $links) {
      id
      policySubcategoryId
      url
      label
      state
    }
  }
`;

export const CREATE_SUBCATEGORY_MUTATION = gql`
  mutation ($policySubcategory: PolicySubcategoryInput) {
    createPolicySubcategory(policySubcategory: $policySubcategory) {
      id
      title
      description
      policyBrief
      category {
        id
        title
      }
      tier
      active
    }
  }
`;

export const CREATE_QUESTIONS_MUTATION = gql`
  mutation ($policyQuestions: [PolicyQuestionInput]) {
    createPolicyQuestions(policyQuestions: $policyQuestions) {
      id
      text
      subcategory {
        id
        title
        description
        policyBrief
        category {
          id
          title
        }
        tier
        active
      }
    }
  }
`;
