import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { PolicyAnswerInput } from 'src/types/PolicyAnswerInput';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import styles from './PolicyAnswerSection.module.scss';

interface PolicyAnswerSectionProps {
  question: PolicyQuestion;
  // The handling of answer in this component is brittle. This assumes that the
  // answer object for this question always exists. But it's possibly null if
  // the database doesn't have an answer to a question.
  answer?: PolicyAnswerInput;
  setPolicyAnswers: Dispatch<SetStateAction<PolicyAnswerInput[]>>;
  errors: number[]
  setErrors: Dispatch<SetStateAction<number[]>>;
}

const PolicyAnswerSection = ({ question, answer, setPolicyAnswers, errors, setErrors }: PolicyAnswerSectionProps) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const handleAnswerChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value as 'yes' | 'no';
    setPolicyAnswers(s => s.map((a) => (a.id === answer?.id ? { ...a, value, changed: true } : a)))
  };

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value !== '') setErrors(p => p.filter((e) => e !== question.id));
    setPolicyAnswers(p => p.map((a) => (a.id === answer?.id ? { ...a, url: value, changed: true } : a)))
  };

  return (
    <div className={styles.detailContent}>
      <div className={styles.policyComponentQuestion}>
        <span className={styles.questionText}>Policy component: {question.text}</span>
        <FormControl>
          <RadioGroup
            key={'radio-key-' + question.id}
            row
            name="answerValue"
            aria-label={`radio-buttons-group-${question.text}-${question.id}`}
            value={answer?.value}
            className={styles.radioForm}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleAnswerChange(e)}
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  size={screenSizeIsAtLeast[Breakpoint.MD] ? 'medium' : 'small'}
                  sx={{
                    width: '43px',
                    '& .MuiSvgIcon-root': { margin: 0 },
                  }}
                />
              }
              label={
                <div
                  style={{
                    marginRight: screenSizeIsAtLeast[Breakpoint.XS] ? 40 : 0,
                    fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem',
                  }}
                >
                  Yes
                </div>
              }
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  size={screenSizeIsAtLeast[Breakpoint.MD] ? 'medium' : 'small'}
                  sx={{
                    width: '43px',
                    '& .MuiSvgIcon-root': { margin: 0 },
                  }}
                />
              }
              label={<div style={{ fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' }}>No</div>}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={styles.policyLink}>
        <label className={styles.policyLinkLabel} htmlFor={`policyComponentLink-${question.text}`}>
          Policy Component Link:
        </label>
        <Box
          component="form"
          sx={{
            display: 'flex',
            width: '100%',
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            key={'url-key-' + question.id}
            id={`policyComponentLink-${question.text}`}
            name="answerUrl"
            value={answer?.url}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleUrlChange(e)}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: 'white',
                fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem',
              },
            }}
            InputLabelProps={{
              style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
            }}
            error={errors.includes(question.id)}
            helperText={errors.includes(question.id) ? "Policy Component URL cannot be empty!" : ''}
          />
        </Box>
      </div>
    </div>
  );
};

export default PolicyAnswerSection;
