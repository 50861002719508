import React, { FC, useState } from 'react';
import styles from '../../../PolicyModal/PolicyModal.module.scss';
import { TextField } from '@mui/material';
import Breakpoint from 'src/types/BreakPoint.enum';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import { useScreenSize } from 'src/hooks/useScreenSize';

interface Props {
  formId: string;
  label: string;
  handleClose: () => void;
  formSubmitFunction: (inputString: string) => void;
  currentCategoryName?: string;
}
export const EditCategoryForm: FC<Props> = ({
  formId,
  handleClose,
  formSubmitFunction,
  currentCategoryName,
  label,
}) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const [inputText, setInputText] = useState<string>(currentCategoryName ?? '');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputText) formSubmitFunction(inputText);
  };

  return (
    <form className={styles.formSection}>
      <label className={styles.label} htmlFor="policyCategoryName">
        {label}
      </label>
      <TextField
        id={formId}
        value={inputText}
        onChange={handleChange}
        variant="outlined"
        sx={{ width: '100%' }}
        InputProps={{
          style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
        }}
        InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
      />
      <div className={styles.modalBtnContainer}>
        <AdminButton text="Cancel" onClick={handleClose} defaultStyle={false} />
        <AdminButton text="Save" onClick={(e) => handleSubmit(e)} defaultStyle={true} />
      </div>
    </form>
  );
};
