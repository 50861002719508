import React, { FC, Dispatch, useEffect, useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { POLICY_MAIN_CATEGORIES } from 'src/const/PolicyMainCategories.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import { makeList } from 'src/utils/makeList';
import styles from './PolicyContent.module.scss';
import { plainToInstance } from 'class-transformer';
import PolicyBriefUpload from './PolicyBriefUpload';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { MediaLinks } from './MediaLinks/MediaLinks';
import { MEDIA_LINKS_QUERY, UPDATE_MEDIA_LINKS_MUTATION } from '../PolicyDataAdmin.graphql';
import { MediaLink } from 'src/types/MediaLink.interface';
import { toast } from 'react-toastify';
import { FormControlLabel, FormGroup } from '@mui/material';
import GeneralSwitch from 'src/components/GeneralSwitch/GeneralSwitch';
import { useParams } from 'react-router-dom';
import { stripTypenames } from 'src/utils/stripTypenames';
import { PolicyBriefLink } from 'src/components/PolicyProfileSummary/PolicyBriefLink/PolicyBriefLink';

interface PolicyContentProps {
  questions: PolicyQuestion[];
  setQuestions: Dispatch<PolicyQuestion[]>;
  policy: PolicySubcategory;
  setPolicy: Dispatch<PolicySubcategory>;
  handleCheckbox: (e: ChangeEvent<HTMLInputElement> | null) => void;
}

export const PolicyContent: FC<PolicyContentProps> = ({
  questions,
  setQuestions,
  policy,
  setPolicy,
  handleCheckbox,
}) => {
  const { policyId }: any = useParams();
  const { screenSizeIsAtLeast } = useScreenSize();
  const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    margin: '3rem 0',
  };

  const { data: links } = useQuery(MEDIA_LINKS_QUERY, {
    variables: { filter: { policySubcategoryId: policy.id } },
  });
  const [updateMediaLinks] = useMutation<MediaLink>(UPDATE_MEDIA_LINKS_MUTATION, {
    onCompleted(data) {
      toast.success('Media links saved', {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => window.location.reload(),
      });
    },
    onError(error) {
      toast.error('Media links did not save. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const saveMediaLinks = () => {
    updateMediaLinks({
      variables: { policySubcategoryId: stripTypenames(policy.id), links: stripTypenames(mediaLinks) },
    });
  };

  const marketAreas: { [key: number]: string } = {
    2: 'Market Creation',
    3: 'Market Expansion',
    1: 'Market Preparation',
  };

  const getObjectKey = (obj: { [key: number]: string }, value: string) => {
    return Object.keys(obj).find((key2: string) => obj[key2 as unknown as number] === value);
  };

  const marketAreaOptions = makeList(marketAreas);
  const [mediaLinks, setMediaLinks] = useState<MediaLink[]>([]);
  const [categoryData, setCategoryData] = useState<PolicyCategory[]>([new PolicyCategory()]);
  const [categoryTitles, setCategoryTitles] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');

  const { data } = useQuery(POLICY_MAIN_CATEGORIES);

  useEffect(() => {
    if (data) {
      setCategoryData(data.listPolicyCategories);
      setCategoryTitles(data.listPolicyCategories.map((d: PolicyCategory) => d.title).sort());
    }
  }, [data]);

  useEffect(() => {
    if (links) {
      setMediaLinks(links.mediaLinks);
    }
  }, [links]);

  const handleChangePolicy = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    const updated =
      name === 'tier'
        ? {
          ...policy,
          [name]: getObjectKey(marketAreas, value),
        }
        : name === 'category'
          ? {
            ...policy,
            [name]: {
              id: categoryData.find((category) => category.title === value)?.id,
              title: value,
            },
          }
          : {
            ...policy,
            [name]: value,
          };

    const newQuestions = [...questions];
    const updatedQuestions = newQuestions.map((question: PolicyQuestion) => {
      const updatedQuestion: any = { ...question };
      updatedQuestion.subcategory = { ...updated };
      return updatedQuestion;
    });

    setPolicy(plainToInstance(PolicySubcategory, updated));
    setQuestions(plainToInstance(PolicyQuestion, updatedQuestions));
  };

  const handleChangeQuestionValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    const { name, value } = e.target;

    const updatedQuestions = [...questions];
    updatedQuestions[i] = {
      ...updatedQuestions[i],
      [name]: value,
    };

    setQuestions(plainToInstance(PolicyQuestion, updatedQuestions));
  };

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0].name);
    encodePolicyBrief(e.target.files[0]);
  };

  const encodePolicyBrief = (file: any) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var base64 = reader.result;
        if (base64) {
          setPolicy({ ...policy, policyBrief: base64.toString().split(',')[1] });
        }
      };
    }
  };

  while (questions.length < 6) {
    questions.push({ ...new PolicyQuestion(), subcategory: policy });
  }

  const questionFields = questions.map((question: PolicyQuestion, i: number) => {
    return (
      <Box key={i} component="div" sx={boxStyles}>
        <label className={styles.label} htmlFor={`question-${question.id}`}>
          Policy Component Question {i + 1}
        </label>
        <TextField
          id={`question-${question.id}`}
          name="text"
          value={question.text}
          onChange={(e) => handleChangeQuestionValue(e, i)}
          variant="outlined"
          sx={{ width: '100%', marginTop: '1rem' }}
          InputProps={{
            style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
          }}
        />
      </Box>
    );
  });

  return (
    <div className={styles.section}>
      <Box component="div" sx={boxStyles}>
        <label className={styles.label} htmlFor="policy-name">
          Policy Name *
        </label>
        <TextField
          id="policy-name"
          name="title"
          value={policy.title}
          variant="outlined"
          onChange={handleChangePolicy}
          sx={{ width: '100%', marginTop: '1rem' }}
          InputProps={{
            style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
          }}
        />
      </Box>
      <Box component="div" sx={boxStyles}>
        <label className={styles.label} htmlFor="description">
          Policy Description
        </label>
        <div className={styles.policyDescText}>This is the full description of the policy.</div>
        <TextField
          id="description"
          name="description"
          variant="outlined"
          value={policy?.description}
          onChange={handleChangePolicy}
          // multiline prop flags WAVE as input missing a label even though htmlFor works if multiline is removed as it creates a second textfield element
          multiline
          rows={5}
          sx={{ width: '100%', marginTop: '1rem' }}
          InputProps={{
            style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
          }}
        />
      </Box>
      <Box component="div" sx={boxStyles}>
        {policyId === 'new' && (
          <>
            <div className={styles.label}>Media Links</div>{' '}
            <div className={styles.policyDescText}>Media links can be added after the policy has been created</div>
          </>
        )}
        {policyId !== 'new' && (
          <MediaLinks setMediaLinks={setMediaLinks} policy={policy} links={mediaLinks} handleSave={saveMediaLinks} />
        )}
      </Box>
      <Box component="div" sx={boxStyles}>
        <div className={styles.selectContainer}>
          <GeneralSelect
            id="selectMarketArea"
            name="tier"
            labelText="Market Area *"
            data={marketAreaOptions}
            value={marketAreas[policy.tier]}
            onChange={handleChangePolicy}
            defaultStyle={true}
            isAdmin
            width="100%"
            fontSize={screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem'}
          />
        </div>
      </Box>
      <Box component="div" sx={boxStyles}>
        <div className={styles.selectContainer}>
          {categoryTitles.length && (
            <GeneralSelect
              id="selectPolicyCategory"
              name="category"
              labelText="Policy Category *"
              data={categoryTitles}
              value={policy.category.title}
              onChange={handleChangePolicy}
              defaultStyle={true}
              isAdmin
              width="100%"
              fontSize={screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem'}
            />
          )}
        </div>
      </Box>
      <Box component="div" sx={boxStyles}>
        {policyId === 'new' && (
          <FormGroup>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <GeneralSwitch
                  id="active"
                  name="active"
                  checked={policy.active}
                  onChange={handleCheckbox}
                  inputProps={{ 'aria-label': 'controlled ' }}
                />
              }
              label={policy.active ? 'Active' : 'Archived'}
            />
          </FormGroup>
        )}
      </Box>
      {questionFields}
      <div className={styles.policyBriefPreview}>
        <span className={styles.previewHeader}>Policy profile preview:</span>
        <PolicyBriefLink policyBrief={policy.policyBrief} policyTitle={policy.title} />
        <span className={styles.previewHelperText}>
          When uploading a new pdf the Policy must be saved in order for the change to take effect
        </span>
      </div>
      <div className={styles.policyBriefContainer}>
        <PolicyBriefUpload id="policyBrief" handleFileChange={handleFileChange} />
        <span>{selectedFile}</span>
      </div>
    </div>
  );
};
