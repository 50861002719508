import React, { FC, useEffect, useState } from 'react';
import { AccordionDetails } from '@mui/material';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { AccordionSummary, MainAccordion } from '../DataAdminAccordionTheme';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import { colors } from 'src/theme/colors';
import { useNavigate } from 'react-router-dom';
import { PolicyCategoryAccordionClass, PolicyCategoryAccordionInterface } from '../PolicyCategoryManagement.interface';
import styles from './PolicyCategoryAccordion.module.scss';
import { toast } from 'react-toastify';
import { PolicyModal } from '../../../components/PolicyModal/PolicyModal';

interface PolicyCategoryAccordionProps {
  data: PolicyCategoryAccordionInterface;
  openArchiveModal: () => void;
  openReactivateModal: () => void;
  setSubcategoryId: React.Dispatch<number>;
}

export const PolicyCategoryAccordion: FC<PolicyCategoryAccordionProps> = ({
  data,
  openArchiveModal,
  openReactivateModal,
  setSubcategoryId,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<PolicyCategory | undefined>(undefined);
  const [policyCategoryAccordionData, setPolicyCategoryAccordionData] = useState<PolicyCategoryAccordionInterface>(
    new PolicyCategoryAccordionClass(),
  );

  useEffect(() => {
    if (data) {
      setPolicyCategoryAccordionData(data);
    }
  }, [data]);

  const handleOpenEditModal = (e: any, category: PolicyCategory) => {
    setSelectedCategory(category);
    setOpenDeleteCategory(false);
    setModalOpen(true);
    setOpenEditCategory(true);
    e.stopPropagation();
  };

  const handleOpenDeleteModal = (e: any, category: PolicyCategory) => {
    setSelectedCategory(category);
    setOpenEditCategory(false);
    setModalOpen(true);
    setOpenDeleteCategory(true);
    e.stopPropagation();
  };

  const openModal = (id: number, active: boolean) => {
    active ? openArchiveModal() : openReactivateModal();
    setSubcategoryId(id);
  };

  return (
    <>
      <PolicyModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        subcategoryId={0}
        targetCategory={selectedCategory}
        openEditCategory={openEditCategory}
        openDeleteCategory={openDeleteCategory}
      />
      {policyCategoryAccordionData.listPolicyCategories.map((category: PolicyCategory) => (
        <MainAccordion
          className={styles.mainAccordion}
          key={category.title + category.id}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary>
            <div className={styles.summaryContent}>{category.title}</div>
            <div className={styles.categoryBtnContainer}>
              <AdminButton
                text="Delete"
                onClick={(e) => handleOpenDeleteModal(e, category)}
                defaultStyle={false}
                overwriteUnderline={true}
              />
              <AdminButton
                text="Edit"
                onClick={(e) => {
                  handleOpenEditModal(e, category);
                }}
                defaultStyle={true}
                overwriteUnderline={true}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails className={styles.mainAccordionDetails}>
            {policyCategoryAccordionData.listPolicySubcategories
              .filter((s: PolicySubcategory) => s.category.id === category.id)
              .sort((a: PolicySubcategory, b: PolicySubcategory) => Number(b.active) - Number(a.active))
              .map((subcategory: PolicySubcategory) => {
                return (
                  <MuiAccordion
                    className={styles.subAccordion}
                    key={subcategory.title + subcategory.id}
                    TransitionProps={{ unmountOnExit: true }}
                    disableGutters
                    elevation={0}
                    sx={{
                      width: '100%',
                      backgroundColor: colors.lightGray3,
                      '&.MuiAccordion-rounded': {
                        border: '1px solid ' + colors.medGray1,
                      },
                    }}
                  >
                    <MuiAccordionSummary>
                      <div className={styles.summaryContent}>{subcategory.title}</div>
                      <div className={styles.subcategoryBtnContainer}>
                        <AdminButton
                          text={subcategory.active === true ? 'Archive' : 'Restore'}
                          onClick={() => {
                            openModal(subcategory.id, subcategory.active);
                          }}
                          defaultStyle={false}
                        />
                        <AdminButton
                          text="Edit"
                          onClick={
                            subcategory.active === true
                              ? () =>
                                  navigate(
                                    `/admin/policy/${subcategory.title.replace(/\s+/gi, '_').replace(/\//g, '&')}`,
                                  )
                              : () =>
                                  toast.error(`Please restore ${subcategory.title} in order to edit.`, {
                                    position: toast.POSITION.TOP_CENTER,
                                  })
                          }
                          defaultStyle={true}
                        />
                      </div>
                    </MuiAccordionSummary>
                  </MuiAccordion>
                );
              })}
            <AdminButton text="+ Add New Policy" onClick={() => navigate('/admin/policy/new')} defaultStyle={true} />
          </AccordionDetails>
        </MainAccordion>
      ))}
    </>
  );
};
