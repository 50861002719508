import { gql } from '@apollo/client';

export const FAQS_HEADING_QUERY = gql`
  query {
    faqPageHeaderContentCollection (where: {headingLocation: "SPOT"}, limit: 1) {
      items {
        faqPageHeader
        faqPageSubHeader
      }
    }
  }
`;