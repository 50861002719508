enum PageTitles {
  HOME = 'Home',
  SPOT = 'Spot for Clean Energy',
  ABOUT = 'About',
  STYLE = 'Style Guide',
  STATE = 'STATE PROFILE',
  POLICY = 'POLICY',
  POLICY_DESC = 'POLICY DESCRIPTION',
  POLICY_PROFILE = 'POLICY PROFILE',
  FAQS = 'FAQs',
  CONTACT = 'Contact',
  STATE_COMPARISON = 'STATE COMPARISON'
}

export default PageTitles;
