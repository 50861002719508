import { Box, Grid, InputLabel, Button } from '@mui/material';
import { FormEvent, ChangeEvent, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'src/components/Input/Input';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import { colors } from 'src/theme/colors';
import { ContactFormTemplate } from 'src/types/ContactForm';
import { ContactFormError } from './Contact';

interface ContactFormProps {
  form: ContactFormTemplate;
  handleCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error: ContactFormError;
}

const ContactForm = ({ handleSubmit, handleChange, handleCancel, form, error }: ContactFormProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const labelStyles = {
    fontSize: '16px',
    color: '#4A4A4A',
    fontFamily: 'Lato',
    textAlign: 'right',
  };

  const cancelButtonStyles = {
    fontFamily: 'Lato',
    fontSize: '16px',
    color: colors.darkGray2,
    pt: 1,
    pb: 1,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.lightGray2,
      boxShadow: 'none',
    },
  };

  const handleCaptchaChange = (value: any) => {
    return value ? setIsDisabled(false) : setIsDisabled(true);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} autoComplete="off">
      <Grid item container spacing={3} sx={{ p: 4 }} xs={12} md={10} direction={{ xs: 'column' }}>
        <Grid
          item
          container
          xs={10}
          sm={10}
          spacing={2}
          justifyContent={{ md: 'space-between' }}
          direction={{ xs: 'column', md: 'row' }}
        >
          <Grid item container xs={5} spacing={2} direction={{ xs: 'column' }}>
            <Grid item xs={2} sm={2} alignSelf={{ xs: 'flex-start' }}>
              <InputLabel sx={{ ...labelStyles }} htmlFor="firstName">
                First Name:
              </InputLabel>
            </Grid>
            <Grid item xs={5} sm={5}>
              <Input
                id="firstName"
                name="firstName"
                handleChange={handleChange}
                type="text"
                value={form.firstName}
                placeholder="First Name"
                error={error.firstName.error}
                helperText={error.firstName.message}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item container xs={5} spacing={2} direction={{ xs: 'column' }}>
            <Grid item xs={2} sm={2} alignSelf={{ xs: 'flex-start' }}>
              <InputLabel sx={{ ...labelStyles }} htmlFor="lastName">
                Last Name:
              </InputLabel>
            </Grid>
            <Grid item xs={5} sm={5}>
              <Input
                id="lastName"
                name="lastName"
                handleChange={handleChange}
                type="text"
                value={form.lastName}
                placeholder="Last Name"
                error={error.lastName.error}
                helperText={error.lastName.message}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={10} sm={10} spacing={2} direction={{ xs: 'column' }}>
          <Grid item xs={2} sm={2} alignSelf={{ xs: 'flex-start' }}>
            <InputLabel sx={{ ...labelStyles }} htmlFor="email">
              Email:
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Input
              id="email"
              name="email"
              handleChange={handleChange}
              type="email"
              value={form.email}
              placeholder="Email"
              error={error.email.error}
              helperText={error.email.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={10} sm={10} spacing={2} direction={{ xs: 'column' }}>
          <Grid item xs={2} sm={2} alignSelf={{ xs: 'flex-start' }}>
            <InputLabel sx={{ ...labelStyles }} htmlFor="organization">
              Organization:
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Input
              id="organization"
              name="organization"
              handleChange={handleChange}
              type="text"
              value={form.organization}
              placeholder="Organization"
              error={error.organization.error}
              helperText={error.organization.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={10} sm={10} spacing={2} direction={{ xs: 'column' }}>
          <Grid item xs={2} sm={2} alignSelf={{ xs: 'flex-start' }}>
            <InputLabel sx={{ ...labelStyles, textOverflow: 'wrap' }} htmlFor="body">
              Questions/Suggestions:
            </InputLabel>
          </Grid>
          <Grid item xs={7.5} sm={7.5} lg={8}>
            <Input
              id="body"
              name="body"
              handleChange={handleChange}
              type="text"
              value={form.body}
              placeholder="Questions or suggestions"
              error={error.body.error}
              helperText={error.body.message}
              fullWidth
              multiline
              rows={8}
            />
          </Grid>
        </Grid>
        <Grid item container xs={10} sm={10} spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Grid item>
            <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} onChange={handleCaptchaChange} />
          </Grid>
        </Grid>
        <Grid item container xs={10} sm={10} spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Grid item>
            <PrimaryButton disabled={isDisabled} onClick={handleSubmit} text="Submit" />
          </Grid>
          <Grid item>
            <Button onClick={handleCancel} sx={{ pl: 3, pr: 3, ...cancelButtonStyles }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
