export class EditableUser {
  id: number = -1;
  active: boolean = false;
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  email: string = '';
  role: string = '';
  newPassword: string = '';
  confirmNewPassword: string = '';
  createdDate: string = '';
  lastLogin: string = '';
}
