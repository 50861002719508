import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDataContext } from 'src/hooks/useUserDataContext';

interface ProtectedRouteProps {
  children: any;
  setModalOpen: React.Dispatch<boolean>;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, setModalOpen }) => {
  const navigate = useNavigate();
  const { authenticated } = useContext(UserDataContext);

  if (!authenticated) {
    navigate('/');
    setModalOpen(true);
    return null;
  } else if (authenticated === 'loading') {
    return null;
  } else {
    return children;
  }
};
