import { gql } from '@apollo/client';

export const CONTACT_TEXT_QUERY = gql`
  query {
    contactFormTextCollection (limit:1, where: {site: "SPOT"}) {
      items {
        header
        description
      }
    }
  }
`;