import React from 'react';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { useMutation } from '@apollo/client';
import { UPDATE_POLICY_CATEGORY } from '../../../pages/DataAdmin/PolicyCategoryManagement.graphql';
import { stripTypenames } from 'src/utils/stripTypenames';
import { ModalTitle } from '../ModalTitle/ModalTitle';
import { EditCategoryForm } from './EditCategoryForm/EditCategoryForm';
import { toast } from 'react-toastify';
import { UpdatePolicyCategoryVars } from '../../../pages/DataAdmin/PolicyCategoryManagement.interface';

interface Props {
  handleClose: () => void;
  categoryToEdit: PolicyCategory | undefined;
}

export const PolicyCategoryEditName = ({ handleClose, categoryToEdit }: Props) => {
  const [updateCategory] = useMutation<UpdatePolicyCategoryVars>(UPDATE_POLICY_CATEGORY, {
    onCompleted(data) {
      handleClose();
      toast.success('Category name saved', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error(
        'The policy category name you have entered already exists. Please enter a new policy category name.',
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
    },
  });

  const savePolicyCategoryName = (inputText: string) => {
    if (!categoryToEdit) return;
    const preppedObject = {
      id: categoryToEdit.id,
      title: `${inputText}`,
    };
    const payload = {
      policyCategory: stripTypenames(preppedObject),
    };
    updateCategory({ variables: payload });
  };

  return (
    <>
      <ModalTitle title="Edit Policy Category Name" />
      <EditCategoryForm
        formId="categoryName"
        label="Policy Category Name:"
        handleClose={handleClose}
        formSubmitFunction={savePolicyCategoryName}
        currentCategoryName={categoryToEdit?.title}
      />
    </>
  );
};
