import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../../types/BreakPoint.enum';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  () => ({
    width: '100%',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #999999',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded:before': {
      opacity: 1,
    },
    '&.Mui-expanded:after': {
      opacity: 1,
    },
  }),
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  return (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon sx={{ fontSize: screenSizeIsAtLeast[Breakpoint.BASE] ? '45px' : '25px', color: '#1A78BE' }} />
      }
      {...props}
    />
  );
})(() => ({
  backgroundColor: 'transparent',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}));
