import { gql } from '@apollo/client';

export const POLICY_MAIN_CATEGORIES = gql`
  query {
    listPolicyCategories {
      id
      title
    }
  }
`;
