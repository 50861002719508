import { FC } from 'react';
import styles from './AdminLoginModal.module.scss';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
import { GeneralModal } from '../GeneralModal/GeneralModal';
import { PasswordResetForm } from './PasswordResetForm/PasswordResetForm';

interface PasswordResetModalProps {
  setModalOpen: React.Dispatch<boolean>;
  modalOpen: boolean;
  userForReset?: { email: string; passwordResetToken: string };
}
export const PasswordResetModal: FC<PasswordResetModalProps> = ({ modalOpen, setModalOpen, userForReset }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const handleClose = () => {
    setModalOpen(false);
  };

  const style = {
    width: screenSizeIsAtLeast[Breakpoint.BASE] ? '450px' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.BASE] ? '30px' : '22px',
  };

  return (
    <GeneralModal modalOpen={modalOpen} setModalOpen={handleClose} styling={style}>
      <div className={styles.modalContent}>
        <PasswordResetForm userForReset={userForReset} handleClose={handleClose} />
      </div>
    </GeneralModal>
  );
};
