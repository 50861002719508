import React, { FC, ReactNode } from 'react';
import styles from './GeneralInfoCard.module.scss';
import { Box } from '@mui/material';

export interface GeneralInfoCardProps {
  title: string;
  description?: ReactNode;
  children?: ReactNode;
}

export const GeneralInfoCard: FC<GeneralInfoCardProps> = ({ title = '', description = '', children }) => {
  return (
    <Box p={4} className={styles.container}>
      <h2>{title}</h2>
      <span>{description}</span>
      <div>{children}</div>
    </Box>
  );
};

export default GeneralInfoCard;
