import React, { FC } from 'react';
import styles from './SpotBanner.module.scss';
import aelHeaderCurve from '../../assets/svg/ael-header-curve.svg';
import spotlogo from 'src/assets/logos/spot-logo-big_WHITE.png';
import { SPOT_TEXT_QUERY } from './SpotBanner.graphql';
import { useQuery } from '@apollo/client';
import contentfulClient from 'src/common/apolloClient';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'

const SpotBanner: FC = () => {
  const { data, error } = useQuery(SPOT_TEXT_QUERY, { client: contentfulClient });
  let spotText = '';

  if (error) {
    throw error;
  }
  if (data) {
    spotText = data?.spotBannerTextCollection?.items[0]?.text;
  }

  const topRoundedClip = (
    <svg className={styles.svgBanner} width="500" height="250" viewBox="0 0 500 80" preserveAspectRatio="none">
      <defs>
        <clipPath id="shape">
          <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" />
        </clipPath>
      </defs>
      <rect x="0" y="0" width="500" height="80" fill="#FFF" clipPath="url(#shape)" />
    </svg>
  );
  return (
    <div className={styles.mainContainer}>
      <div className={styles.roundedContainer}>{topRoundedClip}</div>
      <header className={styles.header}>
        <div className={styles.innerContainer}>
          <div className={styles.logoContainer}>
            <Link component={RouterLink} to='/'>
              <img src={spotlogo} alt="SPOT Logo" />
            </Link>
          </div>
          <h2>{spotText}</h2>
        </div>
      </header>
      <img src={aelHeaderCurve} className={styles.aelHeaderCurve} alt="" />
    </div>
  );
};

export default SpotBanner;
