import { gql } from '@apollo/client';
import { FuelGenerationQueryFilterInterface } from '../../types/FuelGenerationQueryFilter.interface';
import { FuelGenerationInterface } from '../../types/FuelGeneration.interface';

export interface AnnualFuelGenerationQueryFilter {
  fuelGeneration: FuelGenerationQueryFilterInterface;
}

export interface AnnualFuelGenerationData {
  fuelGeneration: FuelGenerationInterface[];
}

export const ANNUAL_FUEL_GENERATION = gql`
  query ($fuelGeneration: FuelGenerationFilter) {
    fuelGeneration(filter: $fuelGeneration) {
      id
      fuel
      watthours
    }
  }
`;

export const GET_ADMIN_SETTING_FUEL_CHART_YEAR = gql`
  query ($settingType: SettingType) {
    adminSetting(input: { settingType: $settingType }) {
      value
      key
    }
  }
`;
