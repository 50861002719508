import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { geoCentroid } from 'd3-geo';
// eslint-disable-next-line
import { ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps';
import stateData from '../../const/stateData.json';
import styles from './MapChart.module.scss';
import { colors } from 'src/theme/colors';
import ReactTooltip from 'react-tooltip';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const offsets: any = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

interface MapChartProps {
  setTooltipContent: React.Dispatch<React.SetStateAction<string>>;
}

const MapChart: FC<MapChartProps> = ({ setTooltipContent }) => {
  const navigate = useNavigate();
  const { screenSizeIsAtLeast } = useScreenSize();

  return (
    <ComposableMap
      tabIndex={-1}
      aria-label="Map of the United States"
      data-tip=""
      projection="geoAlbersUsa"
      onFocus={() => {
        ReactTooltip.hide();
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo, index) => {
              const currentState = stateData.find((s) => s.val === geo.id);
              const param = currentState?.name.replace(/\s+/g, '_');

              return (
                <React.Fragment key={index}>
                  <Geography
                    tabIndex={-1}
                    aria-label={currentState?.name}
                    onMouseEnter={
                      screenSizeIsAtLeast[Breakpoint.SM]
                        ? () => {
                            setTooltipContent(`${currentState?.name}`);
                          }
                        : undefined
                    }
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    onClick={screenSizeIsAtLeast[Breakpoint.SM] ? () => navigate(`/state/${param}`) : undefined}
                    key={geo.rsmKey}
                    stroke={colors.white}
                    geography={geo}
                    fill={colors.blue7}
                    className={styles.state}
                  />
                </React.Fragment>
              );
            })}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = stateData.find((s) => s.val === geo.id);
              return (
                <g key={geo.rsmKey + '-name'}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? <Marker coordinates={centroid}></Marker> : null)}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
