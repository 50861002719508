import React, { ChangeEvent, Dispatch, FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from 'src/theme/colors';
import styles from './MediaLinks.module.scss';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import Breakpoint from 'src/types/BreakPoint.enum';
import { MediaLink } from 'src/types/MediaLink.interface';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { State } from 'src/types/State.enum';

interface MediaLinksProps {
  links: MediaLink[];
  policy: PolicySubcategory;
  setMediaLinks: Dispatch<MediaLink[]>;
  handleSave: () => void;
}

export const MediaLinks: FC<MediaLinksProps> = ({ policy, setMediaLinks, links, handleSave }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const handleAddAnotherLink = () => {
    const updated = [...links];
    updated.push({ url: '', label: '', policySubcategoryId: policy.id });
    setMediaLinks(updated);
  };

  const handleRemove = (index: number) => {
    const updated = [...links];
    updated.splice(index, 1);
    setMediaLinks(updated);
  };

  const handleMediaLinkChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const list = [...links];
    if (name === 'label') list[index].label = value;
    if (name === 'url') list[index].url = value;
    if (name === 'state') list[index]['state'] = value;
    setMediaLinks(list);
  };

  const linkSectionStyle = {
    display: 'flex',
    justifyContent: screenSizeIsAtLeast[Breakpoint.MD] ? 'center' : 'flex-start',
    alignItems: 'center',
    width: '100%',
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className={styles.sectionTitle}>Other Links</div>
      {links.map((link, i) => (
        <div className={styles.form} key={`${link.label}-${i}`}>
          <div className={styles.textfields}>
            <Box
              sx={linkSectionStyle}
            >
              <label className={styles.label}>Display Label:</label>
              <TextField
                id="label"
                name="label"
                variant="outlined"
                defaultValue={link?.label ?? ''}
                onBlur={(e) => {
                  handleMediaLinkChange(e, i);
                }}
                label="Display Label"
                sx={{ width: screenSizeIsAtLeast[Breakpoint.MD] ? '60%' : '70%' }}
                InputProps={{
                  style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
                }}
                InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
              />
            </Box>
            <Box
              sx={{
                marginTop: screenSizeIsAtLeast[Breakpoint.MD] ? '0' : '1rem',
                ...linkSectionStyle
              }}
            >
              <label className={styles.label}>URL:</label>
              <TextField
                id="url"
                name="url"
                defaultValue={link?.url ?? ''}
                onBlur={(e) => {
                  handleMediaLinkChange(e, i);
                }}
                variant="outlined"
                label="URL"
                sx={{ width: screenSizeIsAtLeast[Breakpoint.MD] ? '60%' : '70%' }}
                InputProps={{
                  style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
                }}
                InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
              />
            </Box>
            <Box sx={{ ...linkSectionStyle }}>
              <label style={{ marginRight: '1rem' }}>Specific to State:</label>
              <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select id='state' name='state' defaultValue={link?.state} onBlur={(e) => handleMediaLinkChange(e, i)} fullWidth>
                  {Object.values(State).map((s: string, i: number) => (
                    <MenuItem key={`select-state-${i}`} value={s}>{s}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <button
            onClick={(_) => {
              handleRemove(i);
            }}
            className={styles.deleteButton}
            aria-label={`delete-${link.label}`}
          >
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ))
      }
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleSave}
          sx={{
            color: colors.blue6,
            '&:hover': {
              color: colors.black,
            },
            '&:focus': {
              color: colors.black,
            },
          }}
        >
          Save
        </Button>
        <Button
          onClick={handleAddAnotherLink}
          sx={{
            color: colors.blue6,
            '&:hover': {
              color: colors.black,
            },
            '&:focus': {
              color: colors.black,
            },
          }}
        >
          + Add Another Link
        </Button>
      </div>
    </Box >
  );
};
