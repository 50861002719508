import { gql } from '@apollo/client';

export const STATE_GAP_ANALYSIS_DATA = gql`
  query ($subcategory: Int) {
    listPolicyQuestions {
      subcategory {
        title
      }
    }
    getYesCountsByPolicy(subcategory: $subcategory) {
      state
      count
    }
  }
`;
