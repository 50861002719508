import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Home from './pages/Home/Home';
import StyleGuide from './pages/StyleGuide/StyleGuide';
import CneeHomeNav from './components/CneeHomeNav/CneeHomeNav';
import styles from './App.module.scss';
import SiteFooter from './components/SiteFooter/SiteFooter';
import SpotBanner from './components/SpotBanner/SpotBanner';
import { StateProfile } from './pages/StateProfile/StateProfile';
import Faqs from './pages/Faqs/Faqs';
import MainNav from './components/MainNav/MainNav';
import DrawerMenu from './components/DrawerMenu/DrawerMenu';
import { useUserDataContext, UserDataContext } from 'src/hooks/useUserDataContext';
import { GetUser } from './services/AuthService';
import { StatePolicyDesc } from './pages/StatePolicyDesc/StatePolicyDesc';
import { ScrollToTop } from './utils/scrollToTop';
import { StateDataAdmin } from './pages/DataAdmin/StateDataAdmin';
import { PolicyDataAdmin } from './pages/DataAdmin/PolicyDataAdmin';
import { PolicyProfile } from './pages/PolicyProfile/PolicyProfile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Disclaimer from './components/Disclaimer/Disclaimer';
import { cneeTheme } from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { PolicyCategoryManagement } from './pages/DataAdmin/PolicyCategoryManagement';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import { ProtectedRoute } from './services/ProtectedRoute';
import { Logout } from './pages/Logout/Logout';
import { useReactToPrint } from 'react-to-print';
import UserAccountManagement from './pages/UserAccountManagement/UserAccountManagement';
import { Logout as logout } from 'src/services/AuthService';
import { AdminSettings } from './pages/AdminSettings/AdminSettings';
import { StatePolicyComparison } from './pages/StatePolicyComparison/StatePolicyComparison';
import {updateTabTitle} from "./utils/updateTabTitle";

library.add(fab as any);

function App(): React.ReactElement {
  updateTabTitle('State Policy Opportunity Tracker')
  const userDataContext = useUserDataContext();
  const { authenticated, setAuthenticated, setUserData, setAuthModalOpen } = userDataContext;
  const [drawerOpen, setDrawerOpened] = useState(false);

  // Set Authentication status and User Data on initial App Load
  useEffect(() => {
    GetUser(setAuthenticated, setUserData);
    if (authenticated === false) {
      logout(setAuthenticated, setUserData);
    }
  }, [authenticated, setAuthenticated, setUserData]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <ThemeProvider theme={cneeTheme}>
      <Router>
        <UserDataContext.Provider value={userDataContext}>
          <div id={styles.mainWrapper}>
            <a className={styles.skipNavOption} href="#maincontent">
              Skip to main content
            </a>
            <CneeHomeNav drawerOpen={drawerOpen} setDrawerOpened={setDrawerOpened} />
            <MainNav />
            <SpotBanner />
            <div className={styles.mainContent} id="maincontent" ref={componentRef}>
              <div className={styles.innerContent}>
                <ScrollToTop>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/activate/:activationId" element={<Home />} />
                    <Route path="/password/reset/:resetToken" element={<Home />} />
                    <Route path="/styleguide" element={<StyleGuide />} />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/state/:stateId" element={<StateProfile />} />
                    <Route path="/state/:stateId/:policyId" element={<StatePolicyDesc printPage={handlePrint} />} />
                    <Route path="/policy/:policyId" element={<PolicyProfile />} />
                    <Route
                      path="/policy/:policyId/compareStateTool"
                      element={<StatePolicyComparison printPage={handlePrint} />}
                    />
                    <Route
                      path="/admin/state/:stateId"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <StateDataAdmin />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/admin/globalSettings"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <AdminSettings />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/admin/policy"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <PolicyCategoryManagement />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/admin/policy/:policyId"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <PolicyDataAdmin />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/user-account-management"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <UserAccountManagement />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/logout"
                      element={
                        <ProtectedRoute setModalOpen={setAuthModalOpen}>
                          <Logout />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </ScrollToTop>
              </div>
              <Disclaimer />
            </div>
            <SiteFooter />
            <DrawerMenu drawerOpen={drawerOpen} setDrawerOpened={setDrawerOpened} />
          </div>
          <ToastContainer className={styles.toast} />
        </UserDataContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
