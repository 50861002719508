import { gql } from '@apollo/client';

export const POLICY_PROFILE_DATA = gql`
  query {
    listPolicyQuestions {
      id
      text
      subcategory {
        id
        title
      }
    }
    listActivePolicySubcategories {
      id
      title
      policyBrief
      description
      category {
        lastModifiedDate
      }
    }
  }
`;
