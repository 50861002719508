import axios from 'axios';
import { toast } from 'react-toastify';
import { spotClientUri } from '../spotClient';

const axiosConfig = {
  headers: {
    'content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  credentials: 'same-origin',
};
axios.defaults.withCredentials = true;

export const Logout = async (setAuthenticated, setUserData) => {
  try {
    await axios.post(`${spotClientUri}/user/logout`, axiosConfig);
    setAuthenticated(false);
    setUserData({
      id: undefined,
      createdDate: undefined,
      email: '',
      firstName: '',
      lastName: '',
      passwordResetTokenExpirationDate: undefined,
      active: false,
      role: '',
      fullName: '',
      enabled: false,
      credentialsNonExpired: false,
      accountNonExpired: false,
      authorities: [],
      parent: undefined,
    });
  } catch (error) {
    if (error)
      toast.error('Something went wrong. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
    throw error;
  }
};

export const GetUser = async (setAuthenticated, setUserData) => {
  const res = await axios.get(`${spotClientUri}/user`, axiosConfig);
  if (res.data) {
    const userData = res.data;
    setAuthenticated(true);
    setUserData({
      id: userData.id,
      createdData: userData.createdDate,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      active: userData.active,
      role: userData.role,
      fullName: userData.fullName,
      enabled: userData.enabled,
      credentialsNonExpired: userData.credentialsNonExpired,
      accountNonExpired: userData.accountNonExpired,
      authorities: userData.authorities,
      parent: userData.parent,
    });
  } else {
    setAuthenticated(false);
  }
};

export const Login = async (body, setAuthenticated, setUserData) => {
  const res = await axios.post(`${spotClientUri}/user/login`, body, axiosConfig);
  setAuthenticated(true);
  GetUser(setAuthenticated, setUserData);
  return res;
};

export const CreateAccountPublic = async (body) => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(`${spotClientUri}/user/create`, body, axiosConfig);
  } catch (error) {
    throw error;
  }
};

export const CreateAccountAdmin = async (body, setAdminUsers) => {
  try {
    await axios.post(`${spotClientUri}/user/create-admin`, body, axiosConfig);
    toast.success('Admin account creation successful. Check your email to activate your account.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    GetAllUsers(setAdminUsers);
  } catch (error) {
    toast.error('Unable to create account. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    if (error.response.status === 403) {
      window.location.replace('/');
    }
  }
};

export const UpdateAccount = async (body) => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(`${spotClientUri}/user/update`, body, axiosConfig);
    return res;
  } catch (error) {
    toast.error('Update failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
    });
    if (error.response.status === 403) {
      window.location.replace('/');
    }
  }
};

export const ActivateAccount = async (body) => {
  try {
    // eslint-disable-next-line
    await axios.post(`${spotClientUri}/user/activate`, body, axiosConfig);
    return true;
  } catch (error) {
    throw error;
  }
};

export const GetUserByActivationToken = async (activationId, setActivatedUser) => {
  try {
    const res = await axios.get(`${spotClientUri}/user/activate/${activationId}`, axiosConfig);
    if (res.data.active === false) {
      setActivatedUser({ ...res.data, accountActivationToken: '', active: true });
    }
  } catch (error) {
    throw error;
  }
};

export const GetUserByByPasswordResetToken = async (token, userForReset, setUserForReset) => {
  try {
    const res = await axios.get(`${spotClientUri}/user/password/reset/${token}`, axiosConfig);
    setUserForReset({ ...userForReset, email: res.data.email });
    return res;
  } catch (error) {
    toast.error('This password reset link has been used or expired. Please request to reset your password again.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const SetPasswordResetToken = async (email) => {
  try {
    await axios.post(`${spotClientUri}/user/password/request?email=${encodeURIComponent(email)}`, axiosConfig);
  } catch (error) {
    throw error;
  }
};

export const ResetPassword = async (body) => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(`${spotClientUri}/user/password/reset`, body, axiosConfig);
    return res;
  } catch (error) {
    toast.error('Update failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const SubmitContactForm = (body) => {
  return axios.post(`${spotClientUri}/user/contactForm`, body, axiosConfig);
};

export const GetAllUsers = async (setAdminUsers) => {
  const res = await axios.get(`${spotClientUri}/user/list`, axiosConfig);
  if (res.status === 200) {
    setAdminUsers(res.data);
  }
};

export const DeleteAccount = async (id) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`${spotClientUri}/user/delete/${id}`, axiosConfig);
    return res;
  } catch (error) {
    toast.error('Update failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};
