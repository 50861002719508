import { SelectChangeEvent } from '@mui/material';
import styles from '../AdminSettings.module.scss';
import { useEffect, useState } from 'react';
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton';
import GeneralSelect from '../../../components/GeneralSelect/GeneralSelect';
import { getUpdateFuelChartYearOptions } from '../const/updateFuelChartYearOptions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { RawUpdateFuelChartYearResponse, UpdateFuelChartYearVars } from '../types/UpdateFuelChartYear';
import { toast } from 'react-toastify';
import { UPDATE_FUEL_CHART_YEAR } from '../AdminSettings.graphql';
import { AdminSettingsInput, RawAdminSettingData } from '../../../types/AdminSetting.interface';
import { GET_ADMIN_SETTING_FUEL_CHART_YEAR } from '../../../components/ElectricityGenerationChart/ElectricityGenerationChart.graphql';
import { SettingTypeEnum } from '../../../types/SettingType.enum';

const EditFuelChartYearForm = () => {
  const [fuelChartYear, setFuelChartYear] = useState<string>(new Date().getFullYear().toString());

  const [getFuelChartYear] = useLazyQuery<RawAdminSettingData, AdminSettingsInput>(GET_ADMIN_SETTING_FUEL_CHART_YEAR);

  const [updateFuelChartYear] = useMutation<RawUpdateFuelChartYearResponse, UpdateFuelChartYearVars>(
    UPDATE_FUEL_CHART_YEAR,
  );

  const handleSubmit = () => {
    updateFuelChartYear({ variables: { year: parseInt(fuelChartYear) } })
      .then((res) => {
        toast.success(res.data?.updateFuelChartYear, { position: toast.POSITION.TOP_CENTER });
      })
      .catch(() => {
        toast.error('There was an error with your request', { position: toast.POSITION.TOP_CENTER });
      });
  };

  const handleChangeSelect = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFuelChartYear(value);
  };

  useEffect(() => {
    const fuelChartYear = SettingTypeEnum.FUEL_GENERATION_CHART_YEAR;
    getFuelChartYear({
      variables: { settingType: fuelChartYear },
    }).then((res) => {
      const currentFuelChartYear = res.data?.adminSetting?.value;
      if (currentFuelChartYear) setFuelChartYear(currentFuelChartYear.toString());
    });
  }, [getFuelChartYear]);

  return (
    <div className={styles.settingContent}>
      <span className={styles.settingTitle}>Fuel Generation Chart Year:</span>
      <GeneralSelect
        id="fuelChartYear1"
        hideLabel={true}
        labelText="Fuel Chart Year"
        data={getUpdateFuelChartYearOptions()}
        onChange={(e) => handleChangeSelect(e)}
        value={fuelChartYear}
        defaultStyle={false}
      />
      <PrimaryButton className={styles.submitButton} onClick={handleSubmit} text="Submit" />
    </div>
  );
};

export default EditFuelChartYearForm;
