import { InMemoryCache, createHttpLink, ApolloClient } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const logoutLink = onError(({ response }) => {
  if (response.errors[0].extensions.errorType === 'PERMISSION_DENIED') window.location.replace('/');
});

const spotClient = new ApolloClient({
  cache: new InMemoryCache(),
  fetchOptions: { fetch },
  link: logoutLink.concat(httpLink),
});

export default spotClient;
