import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { UPDATE_SUBCATEGORY_MUTATION} from '../../pages/DataAdmin/PolicyCategoryManagement.graphql';
import { PolicyCategoryAccordionInterface} from '../../pages/DataAdmin/PolicyCategoryManagement.interface';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { stripTypenames } from 'src/utils/stripTypenames';
import styles from './PolicyModal.module.scss';
import { MAIN_NAV_DATA } from 'src/components/MainNav/MainNav.graphql';

interface ArchiveSubcategoryFormProps {
  data: PolicyCategoryAccordionInterface;
  handleClose: () => void;
  subcategoryId: number;
}

export const ArchiveSubcategoryForm: FC<ArchiveSubcategoryFormProps> = ({ data, handleClose, subcategoryId }) => {
  const [formData, setFormData] = useState<PolicySubcategory[]>([new PolicySubcategory()]);

  const [archivePolicySubcategory] = useMutation<PolicySubcategory, PolicySubcategory>(UPDATE_SUBCATEGORY_MUTATION, {
    onCompleted(data) {
      handleClose();
      toast.success(`${formData[0].title} archived`, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error(`${formData[0].title} could not be archived. Please try again.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    refetchQueries: [{ query: MAIN_NAV_DATA }],
  });

  const archivePolicy = () => {
    const payload = stripTypenames({ policySubcategory: { ...formData[0], active: false } });
    archivePolicySubcategory({ variables: payload });
  };

  useEffect(() => {
    if (data) {
      setFormData(
        data.listPolicySubcategories.filter((subcategory: PolicySubcategory) => subcategory.id === subcategoryId),
      );
    }
  }, [data, subcategoryId]);

  return (
    <>
      <div className={styles.heading}>
        <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Archive {formData[0].title}</Heading>
      </div>
      <p>
        Archiving a policy removes it from user view, but saves its information in case of a need to restore. Are you
        sure you wish to proceed?
      </p>
      <form className={styles.formSection}>
        <div className={styles.modalBtnContainer}>
          <AdminButton text="No" onClick={handleClose} defaultStyle={false} />
          <AdminButton text="Yes" onClick={archivePolicy} defaultStyle={true} />
        </div>
      </form>
    </>
  );
};
