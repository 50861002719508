import React, { FC, useEffect, useMemo, useState } from 'react';
import { PolicySubcategory } from '../../types/PolicySubcategory';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PolicyQuestion } from '../../types/PolicyQuestion';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Heading from '../../components/Heading/Heading';
import HeadingStyle from '../../components/Heading/HeadingStyle.enum';
import GeneralSelect from '../../components/GeneralSelect/GeneralSelect';
import { PolicyProfileSummary } from '../../components/PolicyProfileSummary/PolicyProfileSummary';
import { SelectChangeEvent } from '@mui/material/Select';
import { useLazyQuery, useQuery } from '@apollo/client';
import { POLICY_PROFILE_DATA } from '../PolicyProfile/PolicyProfile.graphql';
import { Alert, Grid } from '@mui/material';
import styles from './StatePolicyComparison.module.scss';
import { GET_STATE_ANSWERS } from './StatePolicyComparison.graphql';
import { GetStateAnswersVariable, RawStateAnswersData } from './types/StatePolicyComparison.interface';
import { StateOptionEnum } from '../../types/StateOption.enum';
import { QuestionsAndAnswers } from './QuestionsAndAnswers/QuestionsAndAnswers';
import { StateProfileButtons } from './StateProfileButtons/StateProfileButtons';
import { StateGeneralSelects } from './StateGeneralSelects/StateGeneralSelects';
import { PolicyQuestionsLegend } from '../../components/PolicyQuestionsLegend/PolicyQuestionsLegend';
import { DownloadGapAnalysisAndPrintPage } from '../../components/DownloadGapAnalysisAndPrintPage/DownloadGapAnalysisAndPrintPage';
import Box from '@mui/material/Box';
import {updateTabTitle} from "../../utils/updateTabTitle";

const updateUrlParams = (states: StateOptionEnum[]) => {
  const statesString = states.join(',');
  const params = new URLSearchParams(window.location.search);
  params.set('compare', statesString);
  window.history.replaceState(null, '', `?${params.toString()}`);
};

interface Props {
  printPage: () => void;
}

export const StatePolicyComparison: FC<Props> = ({ printPage }) => {
  updateTabTitle('State Comparison Tool')
  const { policyId }: any = useParams<{ policyId: string }>();
  const [compare] = useSearchParams();
  const statesInUrl = compare.get('compare')?.split(',') || [];

  const getInitialStatesArray = (): StateOptionEnum[] => {
    return [0, 1, 2, 3].map((value) => {
      if (statesInUrl[value]) {
        const possibleStateValue = statesInUrl[value].toUpperCase();
        if (Object.values(StateOptionEnum).includes(possibleStateValue as StateOptionEnum))
          return possibleStateValue as StateOptionEnum;
      }
      return StateOptionEnum.UNKNOWN;
    });
  };

  const navigate = useNavigate();
  const policyName = `${policyId.replace(/_/g, ' ')}`.replace(/&/g, '/');
  const [questionsData, setQuestionsData] = useState<PolicyQuestion[]>([]);
  const [policyData, setPolicyData] = useState<PolicySubcategory>(new PolicySubcategory());
  const [policyDropDownValue, setPolicyDropDownValue] = useState<string>(policyName !== 'policyName' ? policyName : '');
  const [policyBrief, setPolicyBrief] = useState<string>('');
  const [states, setStates] = useState<StateOptionEnum[]>(getInitialStatesArray());
  const handleChangePolicy = (e: SelectChangeEvent) => {
    setPolicyDropDownValue(e.target.value);
    if (e.target.value !== '')
      navigate(`/policy/${e.target.value.replace(/\s+/gi, '_').replace(/\//g, '&')}/compareStateTool`);
  };

  const { data, loading, error } = useQuery(POLICY_PROFILE_DATA);

  const [getStateAnswers, { data: stateAnswers }] = useLazyQuery<RawStateAnswersData, GetStateAnswersVariable>(
    GET_STATE_ANSWERS,
  );

  const questionIdsQueryInput = useMemo<number[]>(() => {
    if (questionsData) return questionsData.map((question) => question.id);
    return [];
  }, [questionsData]);

  useEffect(() => {
    getStateAnswers({
      variables: {
        states: states,
        questionIds: questionIdsQueryInput,
      },
    });
    updateUrlParams(states);
  }, [questionIdsQueryInput, states, getStateAnswers]);

  useEffect(() => {
    if (data) {
      setQuestionsData(data.listPolicyQuestions.filter((q: PolicyQuestion) => q.subcategory.title === policyName));
      setPolicyData(data.listActivePolicySubcategories.find((p: PolicySubcategory) => p.title === policyName));
      setPolicyBrief(
        data.listActivePolicySubcategories.filter((p: PolicySubcategory) => p.title === policyName)[0]?.policyBrief,
      );
    }
  }, [data, policyName]);

  if (loading) return <>Loading</>;
  if (error || !data || data.listActivePolicySubcategories.length === 0) {
    return (
      <Alert severity="warning">
        Unable to load Policy information. Please try again or contact support if the issue persists.
      </Alert>
    );
  }
  return (
    <>
      <BreadCrumbs policyName={policyName} />
      <div className={styles.policyProfileSection}>
        <div className={styles.headingContainer}>
          <div className={styles.headingText}>
            <Heading headingStyle={HeadingStyle.PAGE_TITLE}>State Comparison Tool</Heading>
            <Heading headingStyle={HeadingStyle.PAGE_SUBTITLE}>
              Compare up to four (4) states for policy status.
            </Heading>
          </div>
        </div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <h4 className={styles.policySelectorLabel}>You are viewing the State Policy Comparison for</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GeneralSelect
              id="policySelector"
              hideLabel={true}
              data={data.listActivePolicySubcategories
                .map((subcategory: PolicySubcategory) => subcategory.title)
                .sort()}
              value={policyDropDownValue}
              onChange={handleChangePolicy}
              defaultStyle={true}
              width="100%"
              labelText="Policy"
            />
          </Grid>
        </Grid>
        <PolicyProfileSummary policy={policyData} policyBrief={policyBrief} />
        <div className={styles.policyComparisonContainer}>
          <h4 className={styles.topLeft}>Policy Components</h4>
          <h4 className={styles.topRight}>State Selections</h4>
          <StateGeneralSelects states={states} setStates={setStates} />
          <QuestionsAndAnswers
            questionsLength={questionsData.length}
            questionData={questionsData}
            states={states}
            answers={stateAnswers?.getPolicyAnswersByQuestionsAndStates}
          />
          <StateProfileButtons states={states} />
        </div>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <PolicyQuestionsLegend lastUpdatedDate={new Date(policyData?.category?.lastModifiedDate)} />
          <DownloadGapAnalysisAndPrintPage printPage={printPage} />
        </Box>
      </div>
    </>
  );
};
