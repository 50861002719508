import React, { useEffect, useContext } from 'react';
import { UserDataContext } from 'src/hooks/useUserDataContext';
import { Logout as logout } from 'src/services/AuthService';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

export const Logout = () => {
  const { setAuthenticated, setUserData } = useContext(UserDataContext);

  useEffect(() => {
    logout(setAuthenticated, setUserData);
    toast.success('Logged out successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  });

  return <Navigate to="/" />;
};
