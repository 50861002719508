import { gql } from '@apollo/client';

export const SPOT_TEXT_QUERY = gql`
  query {
    spotBannerTextCollection {
      items {
        text
      }
    }
  }
`;
