import { gql } from '@apollo/client';

export const MAIN_NAV_DATA = gql`
  query {
    listPolicyCategories {
      id
      title
    }
    listActivePolicySubcategories {
      title
      category {
        id
      }
    }
  }
`;
