import { StateOptionEnum } from '../types/StateOption.enum';

const getInitialStateValue = (state?: string): StateOptionEnum => {
  const possibleStateValue = state?.toUpperCase() ?? '';
  if (Object.values(StateOptionEnum).includes(possibleStateValue as StateOptionEnum)) {
    return possibleStateValue as StateOptionEnum;
  }
  return StateOptionEnum.UNKNOWN;
};

export default getInitialStateValue;
