import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactElement } from 'react';
import HeadingStyle from './HeadingStyle.enum';
import styles from './Heading.module.scss';

export interface HeadingProps extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  headingStyle: HeadingStyle;
}

// TODO: This seems like an over-engineered way of _just using normal h1-h6 w/ a className.
// ...or better yet, just setting a MUI theme and using the Typography component
const Heading: FC<HeadingProps> = ({ headingStyle, children }) => {
  const getElement = (): ReactElement => {
    switch (headingStyle) {
      case HeadingStyle.PAGE_TITLE:
        return <span className={styles.heading1}>{children}</span>;
      case HeadingStyle.PAGE_SUBTITLE:
        return <span className={styles.heading2}>{children}</span>;
      case HeadingStyle.CONTAINER_TITLE:
        return <span className={styles.heading3}>{children}</span>;
      case HeadingStyle.CONTAINER_SUBTITLE:
        return <span className={styles.heading4}>{children}</span>;
      default:
        return <></>;
    }
  };

  return getElement();
};

export default Heading;
