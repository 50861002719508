import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import styles from './PolicyDataAdmin.module.scss';
import { PolicyContent } from './PolicyContent/PolicyContent';
import { useQuery, useMutation } from '@apollo/client';
import {
  CREATE_QUESTIONS_MUTATION,
  CREATE_SUBCATEGORY_MUTATION,
  POLICY_DATA_ADMIN_DATA,
  UPDATE_SUBCATEGORY_MUTATION,
} from './PolicyDataAdmin.graphql';
import { AdminButton } from 'src/components/AdminButton/AdminButton';
import { stripTypenames } from 'src/utils/stripTypenames';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { PolicyQuestion } from 'src/types/PolicyQuestion';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import { questionsTemplate } from 'src/const/questionsTemplate';

export const PolicyDataAdmin: FC = () => {
  const { policyId }: any = useParams();
  const subcategoryTitle: string = policyId?.replace(/_/g, ' ').replace(/&/g, '/') || '';
  const [questions, setQuestions] = useState<PolicyQuestion[]>([]);

  const [updateSubcategory] = useMutation(UPDATE_SUBCATEGORY_MUTATION, {
    onCompleted(data) {
      navigate(`/admin/policy/${policy.title.replace(/\s+/gi, '_').replace(/\//g, '&')}`);
      toast.success('Policy saved', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error('Policy did not save. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    refetchQueries: [{ query: POLICY_DATA_ADMIN_DATA }],
  });
  const [createQuestions] = useMutation(CREATE_QUESTIONS_MUTATION, {
    onCompleted(data) {
      toast.success('Questions saved', {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => window.location.reload(),
      });
    },
    onError(error) {
      toast.error('Questions did not save. Please try again.', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
  const [createSubcategory] = useMutation(CREATE_SUBCATEGORY_MUTATION, {
    onCompleted(data) {
      const payload = questions.map((question: PolicyQuestion) => {
        return {
          text: question.text,
          subcategory: data.createPolicySubcategory,
        };
      });

      createQuestions({ variables: { policyQuestions: stripTypenames(payload) } });

      toast.success('Policy saved', {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError(error) {
      toast.error(`Policy failed to save. Please try again.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    refetchQueries: [{ query: POLICY_DATA_ADMIN_DATA }],
  });
  const [policy, setPolicy] = useState<PolicySubcategory>(new PolicySubcategory());
  const [subcategorySelected, setSubcategorySelected] = useState<string>(subcategoryTitle);
  const { data, loading, error } = useQuery(POLICY_DATA_ADMIN_DATA);
  const navigate = useNavigate();

  useEffect(() => {
    if (policyId === 'new') {
      setQuestions(questionsTemplate);
      return;
    }
    if (data) {
      setQuestions(
        data.listPolicyQuestionsAdmin.filter((q: PolicyQuestion) => q.subcategory.title === subcategoryTitle),
      );
      setPolicy(data.listActivePolicySubcategories.find((d: PolicySubcategory) => d.title === subcategoryTitle));
      setSubcategorySelected(
        data.listActivePolicySubcategories.find(
          (subcategory: PolicySubcategory) => subcategory.title === subcategoryTitle,
        ).title,
      );
    }
  }, [data, policyId, subcategoryTitle]);

  const handleChangePolicy = (e: SelectChangeEvent) => {
    setSubcategorySelected(e.target.value);
    if (e.target.value !== '') navigate(`/admin/policy/${e.target.value.replace(/\s+/gi, '_').replace(/\//g, '&')}`);
  };

  const savePolicySubcategory = () => {
    const payload = {
      policySubcategory: stripTypenames(policy),
      policyQuestions: stripTypenames(questions),
    };

    if (policyId === 'new') {
      createSubcategory({ variables: { policySubcategory: policy } });
    } else {
      updateSubcategory({ variables: payload });
    }
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement> | null): void => {
    if (event === null) return;

    setPolicy({
      ...policy,
      [event.target.name]: event.target.checked,
    });
  };

  if (loading) return <>Loading</>;
  if (error || !data || data.listActivePolicySubcategories.length === 0) {
    return (
      <Alert severity="warning">
        Unable to load Policy information. Please try again or contact support if the issue persists.
      </Alert>
    );
  }

  const cancelEdit = () => {
    navigate('/admin/policy');
  };

  const pageHead =
    policyId === 'new' ? (
      <Box component="div" className={styles.viewing}>
        Create New Policy
      </Box>
    ) : (
      <Box className={styles.dropDownSection}>
        <Box component="div" className={styles.viewing}>
          Viewing:
        </Box>
        <Box component="div" className={styles.policySelectContainer}>
          <GeneralSelect
            id="policySelector"
            labelText="Choose another policy"
            data={data.listActivePolicySubcategories.map((subcategory: PolicySubcategory) => subcategory.title).sort()}
            value={subcategorySelected}
            onChange={handleChangePolicy}
            defaultStyle={true}
            width="100%"
          />
        </Box>
      </Box>
    );

  return (
    <div className={styles.pageContainer}>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>SPOT Policy Data Administration</Heading>
      <Box component="div" className={styles.selectContent}>
        {pageHead}
        <Box className={styles.buttonSection}>
          <Box className={styles.buttonsContainer}>
            <AdminButton text="Cancel" onClick={cancelEdit} defaultStyle={false} />
            <AdminButton text="Save" onClick={savePolicySubcategory} defaultStyle={true} />
          </Box>
        </Box>
      </Box>
      <PolicyContent
        questions={questions}
        setQuestions={setQuestions}
        policy={policy}
        setPolicy={setPolicy}
        handleCheckbox={handleCheckbox}
      />
    </div>
  );
};
