import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { MEDIA_LINKS_QUERY } from 'src/pages/DataAdmin/PolicyDataAdmin.graphql';
import { MediaLink } from 'src/types/MediaLink.interface';
import styles from './PolicyLinks.module.scss';

interface PolicyLinksProps {
  policyId: number;
  state?: string;
}

// Hack because SPOT is inconsistent with enums.
const normalize = (s: string) => s.toLowerCase().trim().replace(/\s+/g, '_')

const PolicyLinks: FC<PolicyLinksProps> = ({ policyId, state }) => {
  const [mediaLinks, setMediaLinks] = useState<MediaLink[]>([]);
  const { data: links } = useQuery(MEDIA_LINKS_QUERY, { variables: { filter: { policySubcategoryId: policyId } } });
  useEffect(() => {
    if (links) {
      setMediaLinks(links.mediaLinks);
    }
  }, [links]);

  const stateLinks = !!state ? mediaLinks.filter(l => normalize(l.state ?? '') === normalize(state.toLowerCase())) : []
  const finalLinks = stateLinks.length ? stateLinks : mediaLinks.filter(l => l.state == null)
  const otherLinks = finalLinks.map(createLinkElement)

  return (
    <div className={styles.policyLinksSection}>
      <Heading headingStyle={HeadingStyle.CONTAINER_SUBTITLE}>Other Links</Heading>
      <ul>
        {otherLinks.length > 0
          ? otherLinks
          : 'No additional external links - see policy components for source information'}
      </ul>
    </div>
  );
};

export default PolicyLinks;

function createLinkElement(link: MediaLink) {
  return (
    <li key={link.id}>
      <GeneralLinkBody href={link.url}>{link.label}</GeneralLinkBody>
    </li>
  );

}
