import { FC, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getState } from '../../types/State.enum';
import {
  ANNUAL_FUEL_GENERATION,
  AnnualFuelGenerationData,
  AnnualFuelGenerationQueryFilter,
  GET_ADMIN_SETTING_FUEL_CHART_YEAR,
} from './ElectricityGenerationChart.graphql';
import GeneralInfoCard from '../GeneralInfoCard/GeneralInfoCard';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ChartProps, ElectricityGenerationChartProps } from './ElectricityGenerationChart.interface';
import { FuelNameEnum } from '../../types/FuelName.enum';
import { FuelColorEnum } from '../../types/FuelColor.enum';
import { SortDirectionEnum } from '../../types/SortDirection.enum';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import { SettingTypeEnum } from '../../types/SettingType.enum';
import { AdminSettingsInput, RawAdminSettingData } from '../../types/AdminSetting.interface';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';
highchartsAccessibility(Highcharts);

const getWattHoursPercentage = (actual: number, total: number) => (actual / total) * 100;
const randomHSL = () => `hsla(${~~(360 * Math.random())},70%,70%,0.8)`;

const Chart: FC<ChartProps> = ({ data }) => {
  const totalGigawattHours = data.reduce((c, v) => c + v.watthours, 0);
  const { screenSizeIsAtLeast } = useScreenSize();

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: false,
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          alignTo: screenSizeIsAtLeast[Breakpoint.XL] ? undefined : 'plotEdges',
        },
      },
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          padding: 0
        },
        data: data.map(({ fuel, watthours }) => ({
          name: FuelNameEnum[fuel],
          color: FuelColorEnum[fuel] || randomHSL(),
          y: Number(getWattHoursPercentage(watthours, totalGigawattHours).toFixed(1)),
        })).filter(({ y }) => y > 0),
      },
    ],
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />

      <Typography m={4} align={'center'}>
        Total GWh: {totalGigawattHours.toLocaleString()}
      </Typography>
      <Typography align={'center'}>
        Source:{' '}
        <Link target={'_blank'} href={'https://www.eia.gov/electricity/data/browser/'} underline={'none'}>
          U.S Energy Information Administration, Open Data API, Electricity: Net Generation by Fuel Type
        </Link>
      </Typography>
    </>
  );
};

export const ElectricityGenerationChart: FC<ElectricityGenerationChartProps> = ({ stateName }) => {
  const [getFuelChartYear, { data: rawAdminSetting }] = useLazyQuery<RawAdminSettingData, AdminSettingsInput>(
    GET_ADMIN_SETTING_FUEL_CHART_YEAR,
  );
  const { loading, error, data } = useQuery<AnnualFuelGenerationData, AnnualFuelGenerationQueryFilter>(
    ANNUAL_FUEL_GENERATION,
    {
      variables: {
        fuelGeneration: {
          inStates: [getState(stateName)],
          inYears: [],
          sortColumn: 'watthours',
          sortDirection: SortDirectionEnum.DESC,
        },
      },
    },
  );

  //TODO create global variable for chart year.
  useEffect(() => {
    const fuelChartYear = SettingTypeEnum.FUEL_GENERATION_CHART_YEAR;
    getFuelChartYear({
      variables: { settingType: fuelChartYear },
    });
  }, [getFuelChartYear]);

  return (
    <GeneralInfoCard
      title={`${rawAdminSetting?.adminSetting.value ?? ''} Net Annual Electricity Generation by Fuel Type`}
    >
      {loading && (
        <Typography align={'center'}>
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </Typography>
      )}
      {error && <Typography align={'center'}>Failed to load data.</Typography>}
      {data && data.fuelGeneration.length === 0 && (
        <Typography align={'center'}>No data is currently available for this state.</Typography>
      )}
      {data && data.fuelGeneration.length > 0 && <Chart data={data.fuelGeneration} />}
    </GeneralInfoCard>
  );
};
