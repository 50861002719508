import { Expose, Transform } from 'class-transformer';
import { PolicyQuestion } from './PolicyQuestion';
import { State } from './State.enum';

export class PolicyAnswerInput {
  @Expose()
  id: number = -1;

  @Expose()
  @Transform((obj) => (Boolean(obj.value) ? 'yes' : 'no'))
  value: 'yes' | 'no' = 'no';

  @Expose()
  url: string = '';

  @Expose()
  stateId: State = State.UNKNOWN;

  @Expose()
  question: PolicyQuestion = new PolicyQuestion();

  changed?: boolean = false;
}
