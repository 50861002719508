import React, { FC, useState, useEffect } from 'react';
import GeneralLinkBody from 'src/components/GeneralLinkBody/GeneralLinkBody';
import styles from './MenuItemsDesktop.module.scss';
import { NavigationItems } from 'src/types/NavigationItems.interface.js';
import triangleDown from 'src/assets/svg/triangle-down.svg';
import { useNavigate } from 'react-router-dom';

export interface MenuItemsProps {
  item: NavigationItems;
}

const MenuItemsDeskTop: FC<MenuItemsProps> = ({ item }) => {
  const navigate = useNavigate();
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  useEffect(() => {
    if (menuCollapsed) {
      setMenuCollapsed(false);
    }
    // eslint-disable-next-line
  }, []);

  //If Link does not have any nested sublinks, Display just the link.
  // If nested sublinks, Display the submenus of these nav items

  const innerItems = !item.subItems ? (
    <li
      tabIndex={0}
      role="button"
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          if (item.to) {
            navigate(item.to);
            setMenuCollapsed(true);
          } else if (item.href) {
            window.location.assign(item.href);
            setMenuCollapsed(true);
          }
        }
      }}
    >
      <GeneralLinkBody navigateTo={item.to} href={item.href}>
        {item.title}
      </GeneralLinkBody>
    </li>
  ) : (
    <li tabIndex={0} onMouseOver={() => setMenuCollapsed(false)} role="button">
      <GeneralLinkBody>
        {item.title}
        <img src={triangleDown} alt="" />
      </GeneralLinkBody>

      {/* Submenu 1  - depth 1 */}
      <ul id={menuCollapsed ? styles.menuHidden : undefined}>
        <div className={styles.goldLine}></div>
        {item.subItems?.map((child, key, index) => (
          <li
            onClick={() => setMenuCollapsed(true)}
            tabIndex={0}
            role="button"
            key={child.title}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                if (child.to) {
                  navigate(child.to);
                  setMenuCollapsed(true);
                } else if (child.href) {
                  window.location.assign(child.href);
                  setMenuCollapsed(true);
                }
              }
            }}
          >
            <GeneralLinkBody navigateTo={child.to || undefined} href={child.href || undefined}>
              {child.title}
            </GeneralLinkBody>

            {/* Submenu 2 - depth 2  */}

            {child.subItems ? (
              <ul id={menuCollapsed ? styles.menuHidden : undefined}>
                {child.subItems.map((subItem, index) => (
                  <li
                    tabIndex={0}
                    onClick={() => setMenuCollapsed(true)}
                    key={index}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        if (child.to) {
                          navigate(child.to);
                          setMenuCollapsed(true);
                        } else if (child.href) {
                          window.location.assign(child.href);
                          setMenuCollapsed(true);
                        }
                      }
                    }}
                  >
                    <GeneralLinkBody navigateTo={subItem.to || undefined} href={subItem.href || undefined}>
                      {subItem.title}
                    </GeneralLinkBody>

                    {/* Submenu 3 - depth 3  */}

                    {subItem.subItems ? (
                      <ul id={menuCollapsed ? styles.menuHidden : undefined}>
                        {subItem.subItems.map((subItem, index) => (
                          <li
                            tabIndex={0}
                            onClick={() => setMenuCollapsed(true)}
                            key={index}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                if (child.to) {
                                  navigate(child.to);
                                  setMenuCollapsed(true);
                                } else if (child.href) {
                                  window.location.assign(child.href);
                                  setMenuCollapsed(true);
                                }
                              }
                            }}
                          >
                            <GeneralLinkBody navigateTo={subItem.to || undefined} href={subItem.href || undefined}>
                              {subItem.title}
                            </GeneralLinkBody>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </li>
  );

  return (
    <nav className={styles.upperNavLink}>
      <ul className={styles.nav}>{innerItems}</ul>
    </nav>
  );
};

export default MenuItemsDeskTop;
