import React, { FC } from 'react';
import { StateOptionEnum } from '../../../types/StateOption.enum';
import { Grid } from '@mui/material';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import styles from '../StatePolicyComparison.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  states: StateOptionEnum[];
}
export const StateProfileButtons: FC<Props> = ({ states }) => {
  const navigate = useNavigate();
  return (
    <>
      {[1, 2, 3, 4].map((num, idx) => (
        <React.Fragment key={idx}>
          {states[num - 1] !== StateOptionEnum.UNKNOWN && (
            <Grid style={{ gridArea: `stateBtn${num}`, alignSelf: 'center', justifySelf: 'center' }}>
              <PrimaryButton
                id={styles.billButton}
                text="See State Profile"
                onClick={() => {
                  navigate(`/state/${states[num - 1]}`);
                }}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
