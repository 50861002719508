import React, { FC } from 'react';
import styles from './PolicyBriefLink.module.scss';

interface PolicyBriefLinkProps {
  policyBrief: String;
  policyTitle: String;
}

export const PolicyBriefLink: FC<PolicyBriefLinkProps> = ({ policyBrief, policyTitle }) => {
  return (
    <>
      {!policyBrief && <span className={styles.noFileText}> No policy brief available for download</span>}
      {policyBrief && (
        <>
          <a
            className={styles.briefDownloadLink}
            href={`data:application/pdf;base64,${policyBrief}`}
            download={`${policyTitle} Brief`}
          >
            Download full policy brief
          </a>
        </>
      )}
    </>
  );
};
