import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './MainNav.module.scss';
import MenuItemsDesktop from './MenuItemsDesktop/MenuItemsDesktop';
import { navItemsAdmin, navItems } from '../../const/navItems';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import { MAIN_NAV_DATA } from './MainNav.graphql';
import { useQuery } from '@apollo/client';
import { PolicyCategory } from 'src/types/PolicyCategory';
import { PolicySubcategory } from 'src/types/PolicySubcategory';
import { UserDataContext } from 'src/hooks/useUserDataContext';
import { UserRole } from 'src/types/UserRole.enum';

const MainNav: FC = () => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const { authenticated, userData } = useContext(UserDataContext);
  const { data } = useQuery(MAIN_NAV_DATA);
  const [mainNavData, setMainNavData] = useState([]);

  useEffect(() => {
    if (data) {
      navItems[1].subItems = [];
      setMainNavData(
        data.listPolicyCategories
          .map((main: PolicyCategory) => {
            return {
              title: main.title,
              subItems: data.listActivePolicySubcategories
                .filter((sub: PolicySubcategory) => sub.category.id === main.id)
                .map((policy: PolicySubcategory) => {
                  return {
                    title: policy.title,
                    to: `/policy/${policy.title.replace(/\s+/gi, '_').replace(/\//g, '&')}`,
                  };
                })
                .sort((a: PolicySubcategory, b: PolicySubcategory) => a.title.localeCompare(b.title)),
            };
          })
          .sort((a: PolicyCategory, b: PolicyCategory) => a.title.localeCompare(b.title)),
      );
    }
  }, [data]);

  //Adds policy categories to nav
  if (navItems[1].subItems?.length === 0) {
    navItems[1].subItems?.push(...mainNavData);
  }

  const links = [...navItems];

  if (authenticated !== 'loading' && authenticated && userData.role === UserRole.SUPER_ADMIN) {
    links.push(...navItemsAdmin);
  }

  return (
    <div className={styles.mainNavContainer}>
      <div className={styles.innerContent}>
        <div className={styles.navLinksContainer}>
          {screenSizeIsAtLeast[Breakpoint.LG]
            ? links.map((navItem, index) => <MenuItemsDesktop key={index} item={navItem} />)
            : null}
        </div>
      </div>
    </div>
  );
};

export default MainNav;
