import React, { FC } from 'react';
import { Box, Link, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import aelLogo from '../../assets/logos/ael-tracker-colored-text-logo.png';
import { useQuery } from "@apollo/client";
import { aelClient } from "../../common/apolloAelClient";
import { RECENT_LEGISLATION_QUERY } from "./RecentLegislation.graphql";
import { RecentLegislationVariableInterface, RecentLegislationDataInterface } from "./RecentLegislation.interface";
import { SortDirectionEnum } from "../../types/SortDirection.enum";
import styles from './RecentLegislation.module.scss'
import { getYearFilter } from "../../utils/getYearFilter";
import {stateCode, StateOptionEnum} from "../../types/StateOption.enum";

export interface RecentLegislationProps {
  state: StateOptionEnum,
  policyId: number,
}

export const RecentLegislation: FC<RecentLegislationProps> = ({ state, policyId }) => {

  const yearFilter = getYearFilter(new Date());

  const { data, loading, error } = useQuery<RecentLegislationDataInterface, RecentLegislationVariableInterface>(
    RECENT_LEGISLATION_QUERY,
    {
      client: aelClient,
      variables: {
        input: {
          osState: stateCode[state],
          inSpotPolicyIds: [policyId],
          pageNumber: 0,
          pageSize: 10,
          sortDirection: SortDirectionEnum.DESC,
          sortColumn: 'osCreatedAt',
          inYearsFiled: yearFilter,
        },
      },
    },
  );

  return (
    <Box>
      <div className={styles.recentLegislation}>Recent Legislation</div>
      <Box component='img' src={aelLogo} alt="Advanced Energy Legislation Logo" sx={{ mb: '1rem'}} />
      {loading && <Typography>Loading</Typography>}
      {error && <Typography>Failed to load</Typography>}
      {data && data.bills && data.bills.length === 0 && <Typography>No bills</Typography>}
      {data &&
        <>
          <List>
            {data.bills.map(bill =>
              // padding: 0 is a hack. Something in the code applies padding: 8px 16px on ListItem but I can't find it.
              <ListItem key={bill.billId} sx={{ padding: 0 }}>
                <ListItemButton component="a" href={`${process.env.REACT_APP_AEL_UI_HOST}/bill-details/${bill.osBillId}`} target="_blank">
                  <ListItemText primary={bill.number} secondary={bill.title} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Typography>For historic legislation, visit <Link href={'https://aeltracker.org'}>https://aeltracker.org</Link></Typography>
        </>
      }
    </Box>
  );
}
