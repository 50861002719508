import React, { FC } from 'react';
import IconButton from '../IconButton/IconButton';
import styles from './StateComparisonButton.module.scss';
import { useNavigate } from 'react-router-dom';

type Props = {
  policySelected: string;
  stateSelected: string;
};

export const StateComparisonButton: FC<Props> = ({ policySelected, stateSelected }) => {
  const navigate = useNavigate();
  if (!policySelected) return <></>;
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.stateComparison}>
        <b>State Comparison</b>
        <span className={styles.line}>|</span>{' '}
        <span>Compare this state to up to 3 additional states for this policy.</span>
      </div>
      <IconButton
        text="Go to State Comparison Tool "
        onClick={() =>
          navigate({
            pathname: `/policy/${policySelected.replace(/\s+/gi, '_').replace(/\//g, '&')}/compareStateTool`,
            search: `?compare=${encodeURIComponent(stateSelected)}`,
          })
        }
      />
    </div>
  );
};
