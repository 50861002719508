export enum StateOptionEnum {
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  CALIFORNIA = 'CALIFORNIA',
  COLORADO = 'COLORADO',
  CONNECTICUT = 'CONNECTICUT',
  DELAWARE = 'DELAWARE',
  FLORIDA = 'FLORIDA',
  GEORGIA = 'GEORGIA',
  HAWAII = 'HAWAII',
  IDAHO = 'IDAHO',
  ILLINOIS = 'ILLINOIS',
  INDIANA = 'INDIANA',
  IOWA = 'IOWA',
  KANSAS = 'KANSAS',
  KENTUCKY = 'KENTUCKY',
  LOUISIANA = 'LOUISIANA',
  MAINE = 'MAINE',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MONTANA = 'MONTANA',
  NEBRASKA = 'NEBRASKA',
  NEVADA = 'NEVADA',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NEW_YORK = 'NEW_YORK',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OREGON = 'OREGON',
  PENNSYLVANIA = 'PENNSYLVANIA',
  RHODE_ISLAND = 'RHODE_ISLAND',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  TENNESSEE = 'TENNESSEE',
  TEXAS = 'TEXAS',
  UTAH = 'UTAH',
  VERMONT = 'VERMONT',
  VIRGINIA = 'VIRGINIA',
  WASHINGTON = 'WASHINGTON',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING',
  UNKNOWN = 'UNKNOWN',
}

// Over on AEL, we use statecodes to identify states. SPOT uses uppercase names.
export const stateCode = {
  [StateOptionEnum.ALABAMA]: 'AL',
  [StateOptionEnum.ALASKA]: 'AK',
  [StateOptionEnum.ARIZONA]: 'AZ',
  [StateOptionEnum.ARKANSAS]: 'AR',
  [StateOptionEnum.CALIFORNIA]: 'CA',
  [StateOptionEnum.COLORADO]: 'CO',
  [StateOptionEnum.CONNECTICUT]: 'CT',
  [StateOptionEnum.DELAWARE]: 'DE',
  [StateOptionEnum.FLORIDA]: 'FL',
  [StateOptionEnum.GEORGIA]: 'GA',
  [StateOptionEnum.HAWAII]: 'HI',
  [StateOptionEnum.IDAHO]: 'ID',
  [StateOptionEnum.ILLINOIS]: 'IL',
  [StateOptionEnum.INDIANA]: 'IN',
  [StateOptionEnum.IOWA]: 'IA',
  [StateOptionEnum.KANSAS]: 'KS',
  [StateOptionEnum.KENTUCKY]: 'KY',
  [StateOptionEnum.LOUISIANA]: 'LA',
  [StateOptionEnum.MAINE]: 'ME',
  [StateOptionEnum.MARYLAND]: 'MD',
  [StateOptionEnum.MASSACHUSETTS]: 'MA',
  [StateOptionEnum.MICHIGAN]: 'MI',
  [StateOptionEnum.MINNESOTA]: 'MN',
  [StateOptionEnum.MISSISSIPPI]: 'MS',
  [StateOptionEnum.MISSOURI]: 'MO',
  [StateOptionEnum.MONTANA]: 'MT',
  [StateOptionEnum.NEBRASKA]: 'NE',
  [StateOptionEnum.NEVADA]: 'NV',
  [StateOptionEnum.NEW_HAMPSHIRE]: 'NH',
  [StateOptionEnum.NEW_JERSEY]: 'NJ',
  [StateOptionEnum.NEW_MEXICO]: 'NM',
  [StateOptionEnum.NEW_YORK]: 'NY',
  [StateOptionEnum.NORTH_CAROLINA]: 'NC',
  [StateOptionEnum.NORTH_DAKOTA]: 'ND',
  [StateOptionEnum.OHIO]: 'OH',
  [StateOptionEnum.OKLAHOMA]: 'OK',
  [StateOptionEnum.OREGON]: 'OR',
  [StateOptionEnum.PENNSYLVANIA]: 'PA',
  [StateOptionEnum.RHODE_ISLAND]: 'RI',
  [StateOptionEnum.SOUTH_CAROLINA]: 'SC',
  [StateOptionEnum.SOUTH_DAKOTA]: 'SD',
  [StateOptionEnum.TENNESSEE]: 'TN',
  [StateOptionEnum.TEXAS]: 'TX',
  [StateOptionEnum.UTAH]: 'UT',
  [StateOptionEnum.VERMONT]: 'VT',
  [StateOptionEnum.VIRGINIA]: 'VA',
  [StateOptionEnum.WASHINGTON]: 'WA',
  [StateOptionEnum.WEST_VIRGINIA]: 'WV',
  [StateOptionEnum.WISCONSIN]: 'WI',
  [StateOptionEnum.WYOMING]: 'WY',
  [StateOptionEnum.UNKNOWN]: 'UNKNOWN',
}
