import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import breadcrumbs, { policyProfileBreadcrumbs, policyDescBreadcrumbs } from 'src/const/breadcrumbs';
import Link from '@mui/material/Link';
import styles from './BreadCrumbs.module.scss';
import { colors } from 'src/theme/colors';

interface BreadCrumbsProps {
  stateName?: string;
  policyName?: string;
}

const separator = <span className={styles.separator}>{'>'}</span>;

const BreadCrumbs: FC<BreadCrumbsProps> = ({ stateName, policyName }) => {
  let location = useLocation();
  const stateUrl = stateName?.replace(/\s+/g, '_');
  const policyUrl = policyName?.replace(/\s+/gi, '_').replace(/\//g, '&');

  const currentPagePath =
    location.pathname.includes('activate/') || location.pathname.includes('password/reset') ? '/' : location.pathname;
  const currentPageIndex = breadcrumbs.findIndex(
    (page) =>
      page.path === currentPagePath ||
      (page.pageTitle.includes('STATE PROFILE') && currentPagePath.includes(page.path)) ||
      (page.pageTitle.includes('POLICY') && currentPagePath.includes(page.path)),
  );

  const currentPageOrder = breadcrumbs[currentPageIndex].order;

  const prevBreadcrumbs =
    location.pathname === `/state/${stateUrl}/${policyUrl}`
      ? policyDescBreadcrumbs.slice(0, 2).map((page, index) => (
          <Link
            underline="none"
            key={index}
            href={page.path === '/state/:stateId' ? `${page.path.replace(':stateId', `${stateUrl}`)}` : page.path}
            color={colors.blue1}
            className={styles.crumbLink}
          >
            {page.pageTitle}
          </Link>
        ))
      : location.pathname === `/policy/${policyUrl}/compareStateTool`
      ? policyProfileBreadcrumbs.slice(0, 2).map((page, index) => (
          <Link
            underline="none"
            key={index}
            href={`${policyUrl === 'policyName' ? '/' : page.path.replace(':policyId', `${policyUrl}`)}`}
            color={colors.blue1}
            className={styles.crumbLink}
          >
            {page.pageTitle}
          </Link>
        ))
      : breadcrumbs.slice(0, currentPageOrder).map((page, index) => (
          <Link underline="none" key={index} href={page.path} color={colors.blue1} className={styles.crumbLink}>
            {page.pageTitle}
          </Link>
        ));

  const currBreadcrumbLink =
    location.pathname === `/state/${stateUrl}/${policyUrl}`
      ? `${policyDescBreadcrumbs[2].path.replace('/:stateId/:policyId', `/${stateUrl}/${policyUrl}`)}`
      : breadcrumbs[currentPageIndex].path === '/state'
      ? `${breadcrumbs[currentPageIndex].path.replace('/state', `/state/${stateUrl}`)}`
      : location.pathname === `/policy/${policyUrl}`
      ? `${policyProfileBreadcrumbs[1].path.replace('/:policyId', `/${policyUrl}`)}`
      : location.pathname === `/policy/${policyUrl}/compareStateTool`
      ? `${policyProfileBreadcrumbs[2].path.replace('/:policyId', `/${policyUrl}`)}`
      : breadcrumbs[currentPageIndex].path;

  const pageTitle =
    location.pathname === `/state/${stateUrl}/${policyUrl}`
      ? policyDescBreadcrumbs[2].pageTitle
      : location.pathname === `/policy/${policyUrl}`
      ? policyProfileBreadcrumbs[1].pageTitle
      : location.pathname === `/policy/${policyUrl}/compareStateTool`
      ? policyProfileBreadcrumbs[2].pageTitle
      : breadcrumbs[currentPageIndex].pageTitle;

  return (
    <div className={styles.content}>
      <Stack spacing={2}>
        <Breadcrumbs separator={separator} aria-label="breadcrumb">
          {prevBreadcrumbs}
          <Link
            underline="none"
            key={currentPageIndex}
            href={currBreadcrumbLink}
            color={colors.darkGray2}
            className={styles.crumbLink}
          >
            {pageTitle}
          </Link>
        </Breadcrumbs>
      </Stack>
    </div>
  );
};

export default BreadCrumbs;
