import React, { FC } from 'react';
import { StateOptionEnum } from '../../../types/StateOption.enum';
import { Grid } from '@mui/material';
import GeneralSelect, { GeneralSelectOption } from '../../../components/GeneralSelect/GeneralSelect';
import { useScreenSize } from '../../../hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import { stateOptionsMobile } from '../const/stateOptionsMobile';
import {getStateOptions} from "../../../utils/getStateOptions";

interface Props {
  states: StateOptionEnum[];
  setStates: React.Dispatch<StateOptionEnum[]>;
}
export const StateGeneralSelects: FC<Props> = ({ states, setStates }) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const stateOptions: GeneralSelectOption[] = getStateOptions();
  return (
    <>
      {[1, 2, 3, 4].map((num, idx) => (
        <React.Fragment key={idx}>
          <Grid style={{ textTransform: 'capitalize', gridArea: `stateSelector${num}` }}>
            <GeneralSelect
              id={`stateSelector${num}`}
              labelText={`state ${num} selected`}
              hideLabel={true}
              data={screenSizeIsAtLeast[Breakpoint.MD] ? stateOptions : stateOptionsMobile}
              value={states[num - 1]}
              onChange={(e) => {
                const newStatesArray = [...states];
                newStatesArray[num - 1] = e.target.value as StateOptionEnum;
                setStates(newStatesArray);
              }}
              defaultStyle={true}
              width="100%"
              capitalizeOptions={true}
            />
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};
