import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import styles from './About.module.scss';
import { useQuery } from '@apollo/client';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import contentfulClient from 'src/common/apolloClient';
import { ABOUT_QUERY, SUBCATEGORY_QUERY } from './About.graphql';
import { renderRichText } from 'src/utils/renderRichText.util';
import { SubcategoryQueryInterface } from './About.interface';

export default function About(): React.ReactElement {
  updateTabTitle(PageTitles.ABOUT);
  const { data } = useQuery(ABOUT_QUERY, { client: contentfulClient });
  const { data: subcategories } = useQuery<SubcategoryQueryInterface>(SUBCATEGORY_QUERY);
  let aboutContent, links, policyCount;

  if (data) {
    aboutContent = data?.spotAboutPageCollection?.items[0].content.json;
    links = data?.spotAboutPageCollection?.items[0].content.links;
  }

  if (subcategories) {
    policyCount = subcategories.listActivePolicySubcategories.length.toString();
  }

  return (
    <>
      <BreadCrumbs />
      {data && <div className={styles.aboutContent}>{renderRichText(aboutContent, links, policyCount)}</div>}
    </>
  );
}
