import React, { useState, createContext, useContext } from 'react';
import { UserData } from 'src/types/UserData.interface';

interface UserDataProps {
  authenticated: boolean | string;
  setAuthenticated: React.Dispatch<boolean> | React.Dispatch<string>;
  userData: UserData;
  setUserData: React.Dispatch<any>;
  adminUsers: any[];
  setAdminUsers: React.Dispatch<any>;
  authModalOpen: boolean;
  setAuthModalOpen: React.Dispatch<boolean>;
}

export const UserDataContext = createContext<any>(null);

export const useUserDataContext = (): UserDataProps => {
  const [authenticated, setAuthenticated] = useState('loading');
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [userData, setUserData] = useState({
    id: undefined,
    createdDate: undefined,
    email: '',
    firstName: '',
    lastName: '',
    passwordResetTokenExpirationDate: undefined,
    active: false,
    role: '',
    fullName: '',
    enabled: false,
    credentialsNonExpired: false,
    accountNonExpired: false,
    authorities: [],
    parent: undefined,
  });

  return {
    authenticated,
    setAuthenticated,
    userData,
    setUserData,
    adminUsers,
    setAdminUsers,
    authModalOpen,
    setAuthModalOpen,
  };
};

export function useUserData() {
  return useContext(UserDataContext);
}
