import Breakpoint from '../types/BreakPoint.enum';
import { useMediaQuery } from 'react-responsive';

export const useScreenSize = (): {
  screenSizeIsAtLeast: Record<Breakpoint, boolean>;
} => {
  const screenSizeIsAtLeast: Record<Breakpoint, boolean> = {
    [Breakpoint.XS]: useMediaQuery({ query: '(min-width: 360px)' }),
    [Breakpoint.SM]: useMediaQuery({ query: '(min-width: 480px)' }),
    [Breakpoint.BASE]: useMediaQuery({ query: '(min-width: 624px)' }),
    [Breakpoint.MD]: useMediaQuery({ query: '(min-width: 768px)' }),
    [Breakpoint.LG]: useMediaQuery({ query: '(min-width: 1024px)' }),
    [Breakpoint.XL]: useMediaQuery({ query: '(min-width: 1400px)' }),
  };

  return {
    screenSizeIsAtLeast,
  };
};
