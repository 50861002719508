import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import { colors } from '../../theme/colors';

export const MainAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  width: '100%',
  backgroundColor: colors.white,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded:before': {
    opacity: 1,
  },
  '&.Mui-expanded:after': {
    opacity: 1,
  },
}));

export const SubAccordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
  () => ({
    width: '100%',
    backgroundColor: colors.lightGray3,
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      backgroundColor: colors.lightGray10,
    },
    '&.Mui-expanded:before': {
      opacity: 1,
    },
    '&.Mui-expanded:after': {
      opacity: 1,
    },
    '&.MuiAccordion-rounded': {
      border: '1px solid ' + colors.medGray1,
    },
  }),
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  return (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{
            fontSize: screenSizeIsAtLeast[Breakpoint.BASE] ? '45px' : '25px',
            color: '#1A78BE',
            margin: 0,
          }}
        />
      }
      {...props}
    />
  );
})(() => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}));
