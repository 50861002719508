import React, { FC, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BreadCrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import { StateSnapshot } from 'src/components/StateSnapshot/StateSnapshot';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { colors } from 'src/theme/colors';
import styles from './StateProfile.module.scss';
import GeneralSelect, { GeneralSelectOption } from 'src/components/GeneralSelect/GeneralSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { PolicyGapAnalysis } from 'src/components/GapAnalysis/PolicyGapAnalysis/PolicyGapAnalysis';
import { updateTabTitle } from 'src/utils/updateTabTitle';
import PageTitles from 'src/types/PageTitles.enum';
import { State } from 'src/types/State.enum';
import { Grid } from '@mui/material';
import { ElectricityGenerationChart } from '../../components/ElectricityGenerationChart/ElectricityGenerationChart';
import { getStateOptions } from '../../utils/getStateOptions';
import { StateOptionEnum } from '../../types/StateOption.enum';
import getInitialStateValue from '../../utils/getInitialStateValue';
import getEnumUrlText from '../../utils/getEnumUrlText';
import getEnumDisplayText from '../../utils/getEnumDisplayText';

export const StateProfile: FC = () => {
  const { stateId }: any = useParams();
  const stateOptions: GeneralSelectOption[] = getStateOptions();
  updateTabTitle(PageTitles.STATE + `: ${stateId.replace(/_/g, ' ')}`);
  const navigate = useNavigate();
  const state: State = getEnumDisplayText(stateId);

  const [stateSelected, setStateSelected] = useState<StateOptionEnum>(getInitialStateValue(stateId));

  const handleChange = (e: SelectChangeEvent) => {
    setStateSelected(e.target.value as StateOptionEnum);
    if (e.target.value !== '') navigate(`/state/${getEnumUrlText(e.target.value)}`);
  };

  return (
    <>
      <BreadCrumbs stateName={state} />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <div className={styles.headingText}>
            <Heading headingStyle={HeadingStyle.PAGE_TITLE}>{state}</Heading>
            <span className={styles.line}>|</span>
            <a className={styles.gapAnalysisLink} href="#gap-analysis">
              Jump to Gap Analysis
              <ArrowDropDownIcon
                sx={{
                  position: 'absolute',
                  marginTop: '1px',
                  marginLeft: '225px',
                  color: colors.blue1,
                  fontSize: '50px',
                }}
              />
            </a>
          </div>
        </Grid>
        <Grid item style={{ textTransform: 'capitalize' }} xs={12} lg={6}>
          <GeneralSelect
            id={'anotherStateSelect'}
            labelText={'Choose another state for more details'}
            data={stateOptions}
            value={stateSelected}
            onChange={handleChange}
            defaultStyle={true}
            width="100%"
            capitalizeOptions={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <StateSnapshot stateName={stateId} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ElectricityGenerationChart stateName={stateId} />
        </Grid>
        <Grid item xs={12}>
          <div id="gap-analysis">
            <PolicyGapAnalysis state={stateSelected} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
