import React, { ChangeEvent, Dispatch, FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../../types/BreakPoint.enum';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from 'src/theme/colors';
import styles from './OtherStateOffices.module.scss';
import { Snapshot } from 'src/components/StateSnapshot/types/Snapshot.class';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { getState, State } from 'src/types/State.enum';

interface OtherStateOfficesProps {
  state: State;
  snapshot: Snapshot;
  setSnapshot: Dispatch<Snapshot>;
}

export const OtherStateOffices: FC<OtherStateOfficesProps> = ({ state, snapshot, setSnapshot }) => {
  const { screenSizeIsAtLeast } = useScreenSize();

  const handleAddAnotherLink = () => {
    const updated = instanceToPlain(snapshot);
    updated.officeLinks.push({ url: '', label: '', stateId: getState(state) });
    setSnapshot(plainToInstance(Snapshot, updated));
  };

  const handleRemove = (index: number) => {
    const updated = instanceToPlain(snapshot);
    updated.officeLinks.splice(index, 1);
    setSnapshot(plainToInstance(Snapshot, updated));
  };

  const handleOfficeLinkChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const updated = instanceToPlain(snapshot);
    updated.officeLinks[index][name] = value;
    setSnapshot(plainToInstance(Snapshot, updated));
  };

  return (
    <div className={styles.content}>
      <div className={styles.sectionTitle}>Additional Links</div>
      {snapshot.officeLinks.map((link, i) => (
        <div className={styles.form} key={`${link.label}-${i}`}>
          <div className={styles.textfields}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: screenSizeIsAtLeast[Breakpoint.MD] ? 'center' : 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <label className={styles.label}>Display Label:</label>
              <TextField
                id="label"
                name="label"
                variant="outlined"
                defaultValue={snapshot.officeLinks[i].label}
                onBlur={(e) => {
                  handleOfficeLinkChange(e, i);
                }}
                label="Display Label"
                sx={{ width: screenSizeIsAtLeast[Breakpoint.MD] ? '60%' : '70%' }}
                InputProps={{
                  style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
                }}
                InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: screenSizeIsAtLeast[Breakpoint.MD] ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginTop: screenSizeIsAtLeast[Breakpoint.MD] ? '0' : '1rem',
              }}
            >
              <label className={styles.label}>URL:</label>
              <TextField
                id="url"
                name="url"
                defaultValue={snapshot.officeLinks[i].url}
                onBlur={(e) => {
                  handleOfficeLinkChange(e, i);
                }}
                variant="outlined"
                label="URL"
                sx={{ width: screenSizeIsAtLeast[Breakpoint.MD] ? '60%' : '70%' }}
                InputProps={{
                  style: { backgroundColor: 'white', fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' },
                }}
                InputLabelProps={{ style: { fontSize: screenSizeIsAtLeast[Breakpoint.MD] ? '1rem' : '0.8rem' } }}
              />
            </Box>
          </div>
          <button
            onClick={(e) => {
              handleRemove(i);
            }}
            className={styles.deleteButton}
            aria-label={`delete-${link.label}`}
          >
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ))}
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleAddAnotherLink}
          sx={{
            color: colors.blue6,
            '&:hover': {
              color: colors.black,
            },
            '&:focus': {
              color: colors.black,
            },
          }}
        >
          + Add Another Link
        </Button>
      </div>
    </div>
  );
};
