import React, { FC, useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './GeneralSelect.module.scss';
import caretUpGray from 'src/assets/svg/caret-up.svg';
import caretDownGray from 'src/assets/svg/caret-down.svg';
import caretUpWhite from 'src/assets/svg/caret-up-white.svg';
import caretDownWhite from 'src/assets/svg/caret-down-white.svg';
import caretUpBlack from 'src/assets/svg/caret-up-black.svg';
import caretDownBlack from 'src/assets/svg/caret-down-black.svg';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';
import { colors } from 'src/theme/colors';
import { InputLabel } from '@mui/material';

export interface GeneralSelectOption {
  value: string;
  label: string;
}

export interface GeneralSelectProps {
  id: string;
  name?: string;
  isAdmin?: boolean;
  labelLeft?: boolean;
  labelText: string;
  hideLabel?: boolean;
  data: string[] | GeneralSelectOption[];
  value?: string;
  onChange: (e: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => void;
  defaultStyle: boolean;
  width?: string;
  fontSize?: string;
  capitalizeOptions?: boolean;
  partySelector?: boolean;
}

const GeneralSelect: FC<GeneralSelectProps> = ({
  id,
  name,
  data,
  isAdmin,
  labelLeft,
  labelText,
  hideLabel,
  value,
  onChange,
  defaultStyle,
  width,
  fontSize,
  capitalizeOptions,
  partySelector,
}) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const [hoverAndFocus, setHoverAndFocus] = useState(false);

  const handleHoverAndFocus = () => {
    setHoverAndFocus(true);
  };

  const handleLeaveHoverAndFocus = () => {
    setHoverAndFocus(false);
  };

  const mappedData = data.map((item, i: number) => {
    return (
      <MenuItem
        key={i}
        sx={{
          width: defaultStyle ? width : screenSizeIsAtLeast[Breakpoint.XS] ? 300 : 230,
          fontFamily: 'Lato',
          textTransform: `${capitalizeOptions ? 'capitalize' : 'none'}`,
          fontSize: fontSize ? fontSize : screenSizeIsAtLeast[Breakpoint.MD] ? '1.125rem' : '1rem',
          '&:hover': {
            color: colors.black,
            background: colors.lightGray7,
            fontWeight: 'bold',
          },
          '&:focus': {
            color: colors.black,
            background: colors.lightGray9,
            fontWeight: 'bold',
          },
        }}
        value={typeof item === 'string' ? item : item.value}
      >
        {typeof item === 'string' ? item : item.label}
      </MenuItem>
    );
  });

  const adminStyles = {
    color: colors.black,
    fontWeight: labelLeft ? 'inherit' : 'bold',
    fontSize: labelLeft ? '1.25rem' : '1.1rem',
    textAlign: labelLeft ? 'right' : 'left',
    lineHeight: '1.5rem',
    marginBottom: '10px',
    whiteSpace: 'normal',
  };

  const defaultStyles = {
    display: hideLabel ? 'none' : 'flex',
    fontFamily: 'Lato',
    marginBottom: '6px',
    marginLeft: screenSizeIsAtLeast[Breakpoint.XS] ? '12px' : 0,
  };

  const labelStyles = isAdmin ? adminStyles : defaultStyles;
  const labelPosition = labelLeft ? styles.labelLeft : '';

  return (
    <>
      <Box sx={{ minWidth: screenSizeIsAtLeast[Breakpoint.MD] ? 120 : 'auto' }} className={labelPosition}>
        <InputLabel
          id={`${id}-label`}
          htmlFor={`${id}-input-id`}
          sx={{ ...labelStyles, display: hideLabel ? 'none' : 'flex', fontFamily: 'Lato' }}
        >
          {labelText}
        </InputLabel>
        <FormControl fullWidth>
          <Select
            labelId={`${id}-label`}
            id={id}
            name={name}
            value={value}
            onClose={() => {
              setTimeout(() => {
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
              }, 0);
            }}
            onChange={(e) => onChange(e)}
            onMouseEnter={handleHoverAndFocus}
            onFocus={handleHoverAndFocus}
            onMouseLeave={handleLeaveHoverAndFocus}
            onBlur={handleLeaveHoverAndFocus}
            classes={{
              iconOpen: styles.iconOpen,
            }}
            inputProps={{ id: `${id}-input-id` }}
            IconComponent={({ className }) => {
              className = className.replace('MuiSelect-iconOpen', '');
              return (
                <div className={`${className} ${styles.iconContainer}`}>
                  <img
                    src={defaultStyle ? (hoverAndFocus ? caretUpBlack : caretUpGray) : caretUpWhite}
                    className={styles.caretIconUp}
                    alt=""
                  />
                  <img
                    src={defaultStyle ? (hoverAndFocus ? caretDownBlack : caretDownGray) : caretDownWhite}
                    className={styles.caretIconDown}
                    alt=""
                  />
                </div>
              );
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 440,
                  overflowX: 'scroll',
                  '@media (max-width: 599px)': {
                    maxHeight: 550,
                  },
                },
              },
            }}
            sx={{
              width: defaultStyle ? width : screenSizeIsAtLeast[Breakpoint.XS] ? 300 : 230,
              fontFamily: 'Lato',
              fontSize: fontSize ? fontSize : screenSizeIsAtLeast[Breakpoint.MD] ? '1.25rem' : '1rem',
              borderRadius: defaultStyle ? 0 : '8px',
              background: defaultStyle ? colors.white : colors.blue1,
              color: defaultStyle ? colors.darkGray1 : colors.white,
              textAlign: 'left',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultStyle ? colors.medGray1 : colors.blue1,
                borderWidth: defaultStyle ? 1 : 0,
              },
              '&:hover': {
                background: defaultStyle ? colors.lightGray2 : colors.black,
                fontWeight: defaultStyle ? 'bold' : 'regular',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultStyle ? colors.black : 'none',
              },
              '& .MuiSelect-select:focus': {
                background: defaultStyle ? colors.lightGray2 : colors.black,
                borderRadius: '8px',
                fontWeight: defaultStyle ? 'bold' : 'regular',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: defaultStyle ? colors.black : 'none',
                borderWidth: defaultStyle ? 1 : 0,
              },
            }}
          >
            {mappedData}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default GeneralSelect;
