import { FC } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';

import CloseButton from 'src/components/GeneralModal/CloseButton/CloseButton';

interface GeneralModalProps {
  setModalOpen: React.Dispatch<boolean>;
  modalOpen: boolean;
  children: any;
  styling?: {
    width: string;
    padding: string;
  };
  ariaLabel?: string;
  ariaDescribedBy?: string;
}

export const GeneralModal: FC<GeneralModalProps> = ({
  setModalOpen,
  modalOpen,
  children,
  styling,
  ariaLabel,
  ariaDescribedBy,
}) => {
  const { screenSizeIsAtLeast } = useScreenSize();
  const handleClose = () => {
    setModalOpen(false);
  };
  const defaultSize = {
    width: screenSizeIsAtLeast[Breakpoint.LG] ? 'initial' : '85%',
    padding: screenSizeIsAtLeast[Breakpoint.LG] ? '35px 33px' : '22px',
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    fontFamily: 'Lato',
    boxShadow: 24,
    p: 4,
    width: styling?.width ? styling.width : defaultSize.width,
    padding: styling?.padding ? styling.padding : defaultSize.padding,
  };

  return (
    <Modal open={modalOpen} onClose={handleClose} aria-labelledby={ariaLabel} aria-describedby={ariaDescribedBy}>
      <Box sx={style}>
        <CloseButton handleClose={handleClose} />
        {children}
      </Box>
    </Modal>
  );
};
