import styles from './AdminSettings.module.scss';
import Heading from '../../components/Heading/Heading';
import HeadingStyle from '../../components/Heading/HeadingStyle.enum';
import EditFuelChartYearForm from './UpdateFuelChartYearForm/EditFuelChartYearForm';

export const AdminSettings = () => {
  return (
    <section className={styles.pageContainer}>
      <Heading headingStyle={HeadingStyle.PAGE_TITLE}>Admin Global Settings</Heading>
      <EditFuelChartYearForm />
    </section>
  );
};
