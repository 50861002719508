import { gql } from '@apollo/client';

export const POLICY_DATA = gql`
  query ($stateId: State) {
    listPolicyQuestions {
      id
      text
      subcategory {
        id
        title
        category {
          id
          title
        }
        tier
        lastModifiedDate
      }
    }
    getPolicyAnswersByStateId(stateId: $stateId) {
      id
      value
      url
      question {
        id
        text
        subcategory {
          id
          title
          description
          category {
            id
            title
          }
          tier
        }
      }
    }
    listPolicyCategories {
      id
      title
      createdDate
      lastModifiedDate
      lastModifiedBy
    }
    listActivePolicySubcategories {
      id
      title
      description
      category {
        id
        title
      }
      tier
      policyBrief
    }
  }
`;
