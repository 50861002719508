import { PolicyCategory } from '../types/PolicyCategory';
import { PolicySubcategory } from '../types/PolicySubcategory';

export const questionsTemplate = [
  {  id:  0,
    text:  '',
    subcategory: new PolicySubcategory(),
    category: new PolicyCategory(),
  },
  {  id:  1,
      text:  '',
      subcategory: new PolicySubcategory(),
      category: new PolicyCategory(),
  },
  {  id:  2,
    text:  '',
    subcategory: new PolicySubcategory(),
    category: new PolicyCategory(),
  },
  {  id:  3,
    text:  '',
    subcategory: new PolicySubcategory(),
    category: new PolicyCategory(),
  },
  {  id:  4,
      text:  '',
      subcategory: new PolicySubcategory(),
      category: new PolicyCategory(),
  },
  {  id:  5,
    text:  '',
    subcategory: new PolicySubcategory(),
    category: new PolicyCategory(),
  },
]