import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FULL_GAP_ANALYSIS_EXCEL } from '../../const/FullGapAnalysis.graphql';
import { FC, useState } from 'react';
import styles from './DownloadGapAnalysisAndPrintPage.module.scss';
import { useScreenSize } from '../../hooks/useScreenSize';
import Breakpoint from '../../types/BreakPoint.enum';

interface Props {
  printPage?: () => void;
}

export const DownloadGapAnalysisAndPrintPage: FC<Props> = ({ printPage }) => {
  const [gapAnalysisExcel, setGapAnalysisExcel] = useState<String>();
  const { screenSizeIsAtLeast } = useScreenSize();

  const { loading: excelLoading, error: excelError } = useQuery(FULL_GAP_ANALYSIS_EXCEL, {
    onCompleted: (data) => {
      setGapAnalysisExcel(data?.getGapAnalysis?.encodedFile);
    },
  });

  return (
    <>
      {screenSizeIsAtLeast[Breakpoint.LG] && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '25px',
            marginBottom: `${printPage ? '2rem' : '0'}`,
          }}
        >
          {excelLoading && <span>Preparing Full Gap Analysis ...</span>}
          {excelError && <span>Loading failed, please try again later.</span>}
          {!excelLoading && !excelError && (
            <a
              className={styles.link}
              href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${gapAnalysisExcel}`}
              download={'50 State Gap Analysis'}
            >
              Download Full 50 State Gap Analysis
            </a>
          )}
          {printPage && (
            <>
              <div className={styles.linkSpacer}></div>
              <span className={styles.link} onClick={printPage} onKeyPress={printPage}>
                Print This Page
              </span>
            </>
          )}
        </Box>
      )}
    </>
  );
};
