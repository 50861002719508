import { ChangeEvent, Dispatch, FormEvent } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { buttonStyles, buttonAltStyles } from '../buttonStyles';
import Input from 'src/components/Input/Input';
import GeneralSelect from 'src/components/GeneralSelect/GeneralSelect';
import GeneralSwitch from 'src/components/GeneralSwitch/GeneralSwitch';
import { EditableUser } from 'src/types/EditableUser';
import { EditUserFormError } from '../UserAccountManagement';
import { useScreenSize } from 'src/hooks/useScreenSize';
import Breakpoint from 'src/types/BreakPoint.enum';

interface EditUserFormProps {
  promptActive: boolean;
  setPromptActive: Dispatch<boolean>;
  setPromptType: Dispatch<'' | 'delete' | 'deactivate'>;
  editUser: EditableUser;
  showAddUserForm: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleClose: () => void;
  handleCheckbox: (e: ChangeEvent<HTMLInputElement> | null) => void;
  handleChange: (e: SelectChangeEvent | ChangeEvent<HTMLInputElement>) => void;
  handleDeleteUser: () => void;
  error: EditUserFormError;
}

const EditUserForm = ({
  setPromptType,
  setPromptActive,
  handleSubmit,
  handleChange,
  handleCheckbox,
  handleClose,
  editUser,
  showAddUserForm,
  error,
}: EditUserFormProps) => {
  const labelStyles = {
    fontSize: '16px',
    color: '#4A4A4A',
    fontFamily: 'Lato',
    textAlign: 'right',
  };
  const { screenSizeIsAtLeast } = useScreenSize();

  return (
    <Box component="form" onSubmit={handleSubmit} autoComplete="off">
      <Typography component="h1" textAlign="center" sx={{ fontWeight: 300, fontSize: '32px', fontFamily: 'Lato' }}>
        {showAddUserForm ? 'Add New User Account' : 'Edit User Account'}
      </Typography>
      <Divider sx={{ mt: 3, borderBottomColor: '#979797' }} />
      <Grid container spacing={3} sx={{ p: 4 }}>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={4} sm={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="firstName">
              User First Name
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Input
              id="firstName"
              name="firstName"
              handleChange={handleChange}
              type="text"
              value={editUser.firstName}
              error={error.firstName.error}
              helperText={error.firstName.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={4} sm={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="lastName">
              User Last Name
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Input
              id="lastName"
              name="lastName"
              handleChange={handleChange}
              type="text"
              value={editUser.lastName}
              error={error.lastName.error}
              helperText={error.lastName.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={4} sm={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="email">
              User Email
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <Input
              id="email"
              name="email"
              handleChange={handleChange}
              type="email"
              value={editUser.email}
              error={error.email.error}
              helperText={error.email.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={4} sm={4} alignSelf="center">
            <InputLabel sx={{ ...labelStyles }} htmlFor="role">
              User Role
            </InputLabel>
          </Grid>
          <Grid item xs={8} sm={8}>
            <GeneralSelect
              data={[{ value: 'SUPER_ADMIN', label: 'Super Admin' }]}
              id="role"
              name="role"
              labelText="role"
              onChange={handleChange}
              value={editUser.role}
              defaultStyle={true}
              fontSize={screenSizeIsAtLeast[Breakpoint.MD] ? '0.9rem' : '0.8rem'}
              hideLabel
            />
          </Grid>
        </Grid>
        {showAddUserForm && (
          <>
            <Grid item container xs={12} sm={12} spacing={3}>
              <Grid item xs={4} sm={4} alignSelf="center">
                <InputLabel sx={{ ...labelStyles }} htmlFor="newPassword">
                  Password
                </InputLabel>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Input
                  id="newPassword"
                  name="newPassword"
                  handleChange={handleChange}
                  type="password"
                  value={editUser.newPassword}
                  error={error.password.error}
                  helperText={error.password.message}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} spacing={3}>
              <Grid item xs={4} sm={4} alignSelf="center">
                <InputLabel sx={{ ...labelStyles }} htmlFor="confirmNewPassword">
                  Confirm Password
                </InputLabel>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  handleChange={handleChange}
                  type="password"
                  value={editUser.confirmNewPassword}
                  error={error.password.error}
                  helperText={error.password.message}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={4} sm={4} alignSelf="center">
            {!showAddUserForm && <Typography sx={{ ...labelStyles }}>Account Status</Typography>}
          </Grid>
          <Grid item xs={4} sm={4} alignSelf="center">
            {!showAddUserForm && (
              <FormGroup>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <GeneralSwitch
                      id="active"
                      name="active"
                      checked={editUser.active}
                      onChange={handleCheckbox}
                      inputProps={{ 'aria-label': 'controlled ' }}
                    />
                  }
                  label={editUser.active ? 'Active' : 'Inactive'}
                />
              </FormGroup>
            )}
          </Grid>
          <Grid item container xs={4} sm={4} justifyContent="space-evenly">
            <Button variant="contained" type="submit" sx={{ pl: 3, pr: 3, ...buttonStyles }}>
              Save
            </Button>
            <Button onClick={handleClose} sx={{ pl: 3, pr: 3, ...buttonAltStyles }}>
              Cancel
            </Button>
          </Grid>
          {!showAddUserForm && (
            <Grid item xs={12}>
              <Button variant="contained" color='error' onClick={() => {
                setPromptType('delete')
                setPromptActive(true);
              }}>
                Delete Account
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditUserForm;
