import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { buttonStyles } from '../buttonStyles';
import { UserRole } from 'src/types/UserRole.enum';

const UserRow = ({ user, setEditUser, setModalOpen, setShowAddUserForm, tableBodyStyles }: any) => {
  const openModal = () => {
    setModalOpen(true);
    setShowAddUserForm(false);
    setEditUser({
      id: user.id,
      active: user.active,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      email: user.email,
      role: user.role,
    });
  };

  return (
    <TableRow key={user.id} sx={{ background: '#fff' }}>
      <TableCell sx={{ ...tableBodyStyles }}>{new Date(user.createdDate).toLocaleDateString()}</TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {user.lastName}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {user.firstName}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {user.email}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {new Date(user.lastLogin).toLocaleDateString()}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {UserRole[user.role as keyof typeof UserRole]}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th">
        {user.active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell sx={{ ...tableBodyStyles }} component="th" align="center">
        <Button variant="contained" onClick={openModal} sx={{ ...buttonStyles }}>
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
