import { FC } from 'react';
import styles from './FaqAccordion.module.scss';
import { Accordion, AccordionSummary } from './FaqAccordionTheme';
import { AccordionDetails } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FAQS_QUERY } from './FaqAccordion.graphql';
import { renderRichText } from 'src/utils/renderRichText.util';
import contentfulClient from 'src/common/apolloClient';

const FaqAccordion: FC = () => {
  //To do - set up graceful error handling

  const { data } = useQuery(FAQS_QUERY, { client: contentfulClient });
  let faqData = [];

  if (data) {
    const response = data?.faqsCollection?.items.filter((faq: any) => {
      return faq.categoryCollection.items.filter(
        (item: any) => item.categoryName === 'SPOT' || item.categoryName === 'GENERAL',
      ).length;
    });
    faqData = [...response].sort((a: any, b: any) => a.order - b.order);
  }

  const accordionGroup = faqData.map((faq: any, index: number) => {
    return faq ? (
      <Accordion key={index} id={faq.sys.id}>
        <AccordionSummary className={styles.summary}>
          <div className={styles.summaryContent}>
            <span>{faq.questionTitle}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.detailContent}>{renderRichText(faq.answerBody.json)}</div>
        </AccordionDetails>
      </Accordion>
    ) : null;
  });
  return <>{accordionGroup}</>;
};

export default FaqAccordion;
