import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from '../../theme/colors';

export const MainAccordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    () => ({
        width: '100%',
        backgroundColor: 'transparent',
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded:before': {
            opacity: 1,
        },
        '&.Mui-expanded:after': {
            opacity: 1,
        },
    }),
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => {
    return (
        <MuiAccordionSummary
            expandIcon={
                <ExpandMoreIcon
                    sx={{
                        fontSize: '45px',
                        color: colors.blue6,
                        margin: 0,
                        padding: 0,
                    }}
                />
            }
            {...props}
        />
    );
})(() => ({
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    color: colors.blue6,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '&:hover': {
        textDecoration: 'underline',
    },
}));
