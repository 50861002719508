import { gql } from '@apollo/client';

export const UPDATE_FUEL_CHART_YEAR = gql`
  mutation updateFuelChartYear($year: Int!) {
    updateFuelChartYear(year: $year)
  }
`;

export const GET_ADMIN_SETTING_FUEL_CHART_YEAR = gql`
  query ($settingType: SettingType) {
    adminSetting(input: { settingType: $settingType }) {
      value
      key
    }
  }
`;
