import { gql } from '@apollo/client';

export const ABOUT_QUERY = gql`
  query {
    spotAboutPageCollection(limit: 1) {
      items {
        content {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
              block {
                sys {
                  id
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                url
                fileName
                contentType
              }
              block {
                sys {
                  id
                }
                url
                title
                width
                contentType
                height
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const SUBCATEGORY_QUERY = gql`
  query {
    listActivePolicySubcategories {
      id
      title
    }
  }
`;
