import React, { FC, useState } from 'react';
import MapChart from 'src/components/MapChart/MapChart';
import ReactTooltip from 'react-tooltip';
import styles from './MapSection.module.scss';

export const MapSection: FC = () => {
  const [content, setContent] = useState('');

  return (
    <>
      <div className={styles.mapContainer}>
        <MapChart setTooltipContent={setContent} />
        <ReactTooltip className={styles.toolTip}>{content}</ReactTooltip>
      </div>
    </>
  );
};
